import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import TestView from "@/views/TestView";
//import InfoView from "@/views/storage/InfoView";
import MyThree from "@/views/myThreeView"
import threeStudy from "@/views/threeStudy";
import InfoView from "@/views/storage/InfoView";
import TransferStationView from "@/views/storage/TransferStationView";
import DryClearView from "@/views/storage/DryClearView";
import DataView from "@/views/storage/DataView";
import mapView from "@/views/mapView";
import BufferGeometry from "@/views/three/bufferGeometryView"
import OrderInfoView from "@/views/storage/OrderInfoView";
const routes = [
  {
    path: '/',
    name: 'scrren',
    component: () => import(/* webpackChunkName: "about" */ '../views/ScrrenView.vue')
  },
  // {
  //   path: '/',
  //   name: 'data',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/DataView.vue')
  // },
  {
    path:'/data',
    name:'test_data',
    component: DataView
  },
  {
    path: '/mythree',
    name: 'my_three_test',
    component: MyThree
  },
  {
    path: '/three',
    name: 'three_study',
    component:threeStudy
  },
  {
    path: '/buffer',
    name: 'buffer_geometry',
    component:BufferGeometry
  },
  {
    path: '/test',
    name:'test',
    component: TestView
  },
  {
    path: '/map',
    name: 'map',
    component:mapView
  },
  // {
  //   path: '/storage',
  //   name:'storage_info',
  //   component: InfoView
  // },
  {
    path: '/order',
    name:'order_info',
    component:OrderInfoView

  },
  {
    path: '/dry',
    name:'dry_clear',
    component:DryClearView
  },
  {
    path: '/road',
    name:'road_info',
    component:InfoView
  },
  {
    path:'/transfer',
    name:'transfer_station',
    component:TransferStationView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/echarts',
    name: 'echarts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EchartsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
