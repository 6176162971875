<template>
  <div class="persons-data-view">
    <div class="persons-back">
<!--      <el-table-->
<!--          v-loading="loading"-->
<!--          :data="personList"-->
<!--          max-height="145"-->
<!--          stripe-->
<!--          @cell-click="cellHandler"-->
<!--          style="width: 100%; height: 145px"-->
<!--      >-->
<!--        <el-table-column-->
<!--            width="100"-->
<!--            prop="licenseNumber"-->
<!--            label="车牌">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            width="200"-->
<!--            prop="address"-->
<!--            label="地址">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            width="60"-->
<!--            prop="status"-->
<!--            label="状态">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="vechile_type"-->
<!--            label="车辆类型">-->
<!--        </el-table-column>-->
<!--      </el-table>-->

    </div>


    <div class="persons-row-container">

      <!--        <el-amap class="amap-box" :zoom="zoom" :center="center" :map-style=mapStyle-->
      <!--                 :rotate-enable=mapRoute-->
      <!--                 :view-mode=viewMode-->
      <!--                 :vid="amapDemo"-->
      <!--        >-->
      <el-amap class="amap-box" :zoom="zoom" :center="center" :map-style=mapStyle
               :rotate-enable=mapRoute
               :view-mode=viewMode

      >
        <el-amap-marker
            v-for="marker in markers"
            :position="marker.position"
            :key="marker.id"
            :icon="marker.icon"
            @click="marker.events.test()"

        >

        </el-amap-marker>
        <el-amap-info-window
            :position="window.position"
            :visible="window.visible"
            :content="window.content"
            :offset="window.offset"
            :close-when-click-map="true"

        ></el-amap-info-window>
      </el-amap>

    </div>
  </div>
</template>

<script>
export default {
  name: "VehicleMap",
  props: ['getChildInfo','getOrdersInfo','total_orders','vehicle_type'],
  data(){
    return {
      personList:[
        {
          licenseNumber:'沪EF2513',
          address:'上海市闵行区东闸路91号闵行区莘松小学东160米',
          vechile_type:'建筑垃圾',
          status:'行驶'
        },{
          licenseNumber:'沪EB8707',
          address:'上海市闵行区江川路街道临沧路202号西南25米',
          vechile_type:'建筑垃圾',
          status:'行驶'
        }
      ],
      loading:false,
      center: [121.320435, 31.171072],
      zoom:13,
      viewMode:"3D",
      mapRoute:true,

      mapStyle: "amap://styles/grey", //设置地图样式
      markers:[],
      windows:[],
      window:[],
      param : ''


    }
  },
  mounted() {
    this.param=this.vehicle_type

    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  unmounted(){
    this.ws.close();
  },
  created() {
    this.heartCheck()
    this.cellHandler()

  },
  methods:{
    sendMapInfo(info){
      this.getChildInfo(info)
    },
    sendOrderInfo(info){
      this.getOrdersInfo(info)
    },
    // sendTotalOrders(orders){
    //   this.total_orders(orders)
    // },
    cellHandler(){
      let markers=[];
      let windows=[];
      let that=this;

      that.ws = new WebSocket("wss://garbage-device.bfriendgroup.com:8502");
      //that.ws = new WebSocket("ws://test.bfriendgroup.com:8502");
      that.ws.onopen = function () {
        console.log('websocket连接成功map');
      };
      that.ws.onclose = function () {
        // 关闭 websocket
        console.log("连接已关闭...");
        //断线重新连接

      };
      that.ws.onmessage=function(e){
        markers=[]
        windows=[]
        that.message=JSON.parse(e.data)
        console.log('我得车辆',that.message)
        that.sendOrderInfo(that.message)
       // that.sendTotalOrders(that.message.total_orders)
        let pointMarker=that.message.data
        let div_content=''
        pointMarker.forEach((item,index)=>{
          markers.push({
            position:item.position,
            icon:item.icon,

            events:{
              test(){

                that.windows.forEach(window=>{
                  window.visible=false
                })
                that.window=that.windows[index];
                that.$nextTick(()=>{
                  that.window.visible=true
                })
              },close(){
                console.log('test1111')
              }

            }
          })
          if(item.data_type==='car'){
            div_content=''+
                '<div style=""><text style="color:#028E8F;font-size: 12px;">车牌 : </text><text style="color:#fff;font-size: 12px;">'
                +item.licenseNumber +'</text><br><text style="color:#028E8F;	font-size: 12px;">车辆位置 : </text><text style="color:#fff;	font-size: 12px;">' +
                item.address+'</text><br><text style="color:#028E8F;	font-size: 12px;">车辆状态 : </text><text style="color:#fff;font-size: 12px;">' +
                item.status+ '</text><br><text style="color:#028E8F;	font-size: 12px;">车辆类型 : </text><text style="color:#fff;font-size: 12px;">' +
                item.vechile_type+ '</text></div>'
          }else{
            div_content=''+
                '<div style=""><text style="color:#028E8F;font-size: 12px;">设备名称 : </text><text style="color:#fff;font-size: 12px;">'
                +item.device_name +'</text><br><text style="color:#028E8F;	font-size: 12px;">设备位置 : </text><text style="color:#fff;	font-size: 12px;">' +
                item.address+'</text><br><text style="color:#028E8F;	font-size: 12px;">设备状态 : </text><text style="color:#fff;font-size: 12px;">' +
                item.sta+ '</text></div>'
          }

          windows.push({
            position:item.position,
            content: div_content
                // "<div >"+"车牌"+item.licenseNumber+"</div>"+
                // "<div >"+"车辆位置："+item.address+"</div>"+
                // "<div >"+"状态："+item.status+"</div>"
            ,
           // content:'ccccc',
            offset:[10,-35],

            visible: false
          })

        })


        that.markers = markers;
        console.log('地图',markers)
        that.windows=windows

      }



    },
    heartCheck(){
      clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(() => {
        let that = this;
        console.log('我要的传值',this.param)
        if(that.ws && that.ws.readyState==1) {
          that.ws.send(that.param)
        }

      }, 1000 * 5)
    },
  }
}
</script>
<!--<style lang="scss">-->

<!--</style>-->
<style lang="less">
.amap-demo {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.amap-info-content {
  font-size: 25px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: #00D4E9 3px solid;
  border-radius: 10px;

}

.bottom-center .amap-info-sharp {
  bottom: 0;
  font-size: 25px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #00D4E9;
}

.amap-info-close {
  display: none;
}
.amap-icon img {
  position: unset;
  z-index: -1;
  width: 30px;
  height: 30px;
}
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
.persons-data-view {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  ///////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////

  .persons-back {
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 5px;
    width: 500px;
    height: 145px;
  }

  .persons-row-container {
    width: 100%;
    height:100%;
  }
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}
</style>