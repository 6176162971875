<template>
  <div class="Left-3">
    <div class="d-flex mt-1 jc-center body-box">
      <div id="stacked_line" style="width: 100%; height: 250px"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
name: "stacked_line_show",
  mounted() {
    this.drawChart()
  },
  data(){
    return {
      listing:[]
    }
  },
  methods:{
    drawChart(){

      let myDom = document.getElementById('stacked_line');
      myDom.removeAttribute('_echarts_instance_');
      let myChart = echarts.init(myDom);
      let option={
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['光华路处置量', '颛桥处置量'],
          orient: 'vertical',
          x:'right',
          y:'15%',
          textStyle:{
            fontSize: 12,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          axisLine: {// 轴线的颜色以及宽度
            lineStyle: {
              color: '#113D72'
            }
          },
          axisLabel: {// x轴文字的配置
            show: true,
            textStyle: {
              color: '#efd017'
            }
          },
          boundaryGap: false,
          data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月','八月','九月','十月','十一月','十二月']
        },
        yAxis: {
          type: 'value',
          axisLine: {// 轴线的颜色以及宽度
            lineStyle: {
              color: '#113D72'
            }
          },
          axisLabel: {// x轴文字的配置
            show: false,
            textStyle: {
              color: '#fff'
            }
          },
          splitLine: {// 分割线配置
            lineStyle: {
              color: '#113D72',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '光华路处置量',
            type: 'line',
            stack: 'Total',

            data: [23,24,15,32,56,43,61],
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,  //改变渐变色方向
                  colorStops: [
                    {
                      offset: 0.1,
                      color: 'rgb(33,243,225)' // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'rgb(57,196,199)' // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: 'rgb(67,182,170)' // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: 'rgb(68,173,164)' // 100% 处的颜色
                    }
                  ]
                  // globalCoord: false // 缺省为 false
                }
              }
            },
            itemStyle: {
              normal: {
                color: '#29eed0', // 改变折线点的颜色
                lineStyle: {
                  color: '#29eed0' // 改变折线颜色
                }
              }
            }
          },
          {
            name: '颛桥处置量',
            type: 'line',
            stack: 'Total',
            data: [35,31,13,24,41,56,77],
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,  //改变渐变色方向
                  colorStops: [
                    {
                      offset: 0.1,
                      color: 'rgb(16,62,231)' // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'rgb(63,82,198)' // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: 'rgb(48,112,210)' // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: 'rgb(61,134,199)' // 100% 处的颜色
                    }
                  ]
                  // globalCoord: false // 缺省为 false
                }
              }
            },
            itemStyle: {
              normal: {
                color: '#2f48ec', // 改变折线点的颜色
                lineStyle: {
                  color: '#2f48ec' // 改变折线颜色
                }
              }
            }
          },


        ]
      };
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" scoped>
.Left-3{

  .body-box {
    border-radius: 10px;
    overflow: hidden;

  }
}
#container {

  width:100%;
  height:300px;
}

</style>