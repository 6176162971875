<template>
  <div class="progresswrapper">
    <div class="pro" v-bind:style="{'width':width_data}">{{width_data}}</div>
  </div>
</template>

<script>
export default {
  props: ['data_width'],
  name: "progress_wrapper",
  data () {
    return {
      width_data:this.data_width
    }
  },
  watch:{
    data_width:function (n){

      console.log('www',n)
      this.width_data=n+"%"

    }
  },

}
</script>

<style lang="scss">
.progresswrapper{
  width : 98%;
  height : 16px;
  background-image : linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
  background-color: #0f336d;
  background-size : 40px 40px;
  position : relative;

  .pro{
    width : 30%;
    height : 100%;
    background-image : linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
    //background-color: rgb(32, 75, 184);
    background-color: rgb(32 29 227);
    background-size : 40px 40px;
    animation : progressbar 2s linear infinite;
    font-size: 11px;
    text-align: center;
  }

}
@keyframes progressbar{
  0% {
    background-position : 40px 0
  }
  100% {
    background-position : 0 0
  }
}
</style>