import axios from 'axios'

import store from '@/store'
import { getToken } from '@/utils/auth'
const service = axios.create({
   baseURL:'https://storage-api.bfriendgroup.com/',
    //baseURL: 'http://139.196.37.213/', // 所有的请求地址前缀部分
    // timeout: 60000, // 请求超时时间毫秒
    // withCredentials: true, // 异步请求携带cookie
    headers: {
        // 设置后端需要的传参类型
        'Content-Type': 'application/json; charset=utf-8',
        'token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzA5MTIwMzgsIm5iZiI6MTY3MDkxMjAzOCwiZXhwIjoxNjczNTA0MDM4LCJkYXRhIjp7InVzZXJfaWQiOjF9fQ.xCJqg7pQrIlnAA5joEGH23C1uNVlxqVICqixNBasGqw',
        // 'X-Requested-With': 'XMLHttpRequest',

    },
})
service.interceptors.request.use(
    config => {
        // do something before request is sent
        if (store.getters.token) {
            // config.header.admintoken = "wjf"
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['token'] = getToken()
        }
        // console.log(config)
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        console.log(res)

        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 0) {
            if (res.code == 1) {
                // 接口错误提醒
                this.$message.error({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })

            } else if (res.code == 401) {
                // token失效 强制跳转登录页
                this.$message.error({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 1.5 * 1000,
                    onClose: function() {
                        store.dispatch('user/resetToken').then(() => {
                            location.reload()
                        })
                    }
                })
            } else if (res.code == 4001) {
                // 接口未授权
                this.$message.error({
                    message: res.msg || 'Error',
                    type: 'warning',
                    duration: 5 * 1000
                })
            }

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
            //   // to re-login
            //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
            //     confirmButtonText: 'Re-Login',
            //     cancelButtonText: 'Cancel',
            //     type: 'warning'
            //   }).then(() => {
            //     store.dispatch('user/resetToken').then(() => {
            //       location.reload()
            //     })
            //   })
            // }
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        this.$message.error({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)
// 导出
export default service