<template>
  <div class="Left-3">
    <div class="d-flex mt-1 jc-center body-box">
      <div :id="dom_id" style="width: 100%; height: 200px"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getDomesticGarbageDriverTrend,getWetGarbageDriverTrend
}from '@/api/maintenance'
export default {
  name: "stacked_line_clean_driver",
  props:['driver_id'],
  mounted() {
    console.log('司机编号',this.driver_id)
    this.$nextTick(() => {
      this.dom_id='stacked_line_clean_dirver'
      this.echartsData()
    })
  },
  data(){
    return {
      listing:[],
      title:[],
      children_type:0,
      dom_id:'stacked_line_clean_dirver',
      name:'驾驶员清运走势'
    }
  },
  watch:{
    driver_id(newName, oldName){
      this.children_type=newName
      if(this.children_type.driver_id){
        this.dom_id='stacked_line_clean_dirver'
        this.name='清运重量'
        this.echartsData()
      }else{
        this.dom_id='stacked_line_wet_clean_summary'
        this.name='清运重量'
        this.echartsWetData()
      }

      console.log('旧的的变法',oldName)
      console.log('新的变法',newName)
    }
  },
  methods:{
    echartsWetData(){
      let that=this
      getWetGarbageDriverTrend({
        driver:this.driver_id.driver
      }).then(response=>{
        that.listing=response.data.result
        that.title=response.data.keys
        this.drawChart(that.dom_id)
      })
    },
    echartsData(){
      let that=this
      getDomesticGarbageDriverTrend({
        driver_id:this.driver_id.driver_id,
        area_id:1
      }).then(response=>{
        that.listing=response.data.result
        that.title=response.data.title
        this.drawChart(that.dom_id)
        console.log('我的司机垃圾数据',that.listing)
      })
    },
    drawChart(id){
      console.log('我的dom',id)
      let that=this
      let myDom = document.getElementById(that.dom_id);
      myDom.removeAttribute('_echarts_instance_');
      let myChart = echarts.init(myDom);
      let option={
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [this.name],
          // orient: 'vertical',
          // x:'right',
          //y:'5%',
          textStyle:{
            fontSize: 12,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          axisLine: {// 轴线的颜色以及宽度
            lineStyle: {
              color: '#113D72'
            }
          },
          axisLabel: {// x轴文字的配置
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 11,//字体大小
            }
          },
          boundaryGap: false,
          data: that.title
        },
        yAxis: {
          type: 'value',
          axisLine: {// 轴线的颜色以及宽度
            lineStyle: {
              color: '#113D72'
            }
          },
          axisLabel: {// x轴文字的配置
            show: true,
            textStyle: {
              color: '#fff'
            }
          },
          splitLine: {// 分割线配置
            lineStyle: {
              color: '#113D72',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: this.name,
            type: 'line',
            stack: 'Total',

            data: that.listing,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,  //改变渐变色方向
                  colorStops: [
                    {
                      offset: 0.1,
                      color: 'rgba(51, 156, 255, 0.64)' // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(51, 156, 255, 0.3)' // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: 'rgba(51, 156, 255, 0.1)' // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: 'rgba(0,0,0, 0.1)' // 100% 处的颜色
                    }
                  ]
                  // globalCoord: false // 缺省为 false
                }
              }
            },
            itemStyle: {
              normal: {
                color: '#339CFF', // 改变折线点的颜色
                lineStyle: {
                  color: '#339CFF' // 改变折线颜色
                },

              }
            }
          },

        ]
      };
      myChart.setOption(option);


    }
  }
}
</script>

<style scoped>

</style>