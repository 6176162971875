<template>
<div id="index">
  <dv-full-screen-container class="container">
    <div class="row-container">
      <div class="threed">
        <div class="main-header">

        </div>
      </div>
      <div style="text-align: left;margin-top: 2%;">
        <el-button

            class="filter-item"
            type="primary"
            icon="el-icon-download"
            style="text-align: center;background:rgb(0, 35, 95);"
            @click="jump()"
        >
          返回上级
        </el-button>
      </div>
    </div>
    <div class="header_info">
      <dv-border-box-10 style="width: 30%">
        <div class="title"><span>干垃圾清运情况汇总</span></div>
        <div class="info">
          <div style="position: absolute;
              top: 38%;
              left: 4%;
              width: 35%;">

            <div style="margin-left: 10%">
              <div class="order_title">今日清运桶数</div>
<!--              <div class="order_total" style="margin-top: 13%;">{{today_orders}}</div>-->
              <div class="order_total" style="margin-top: 13%;">{{dry_today_orders}}</div>
            </div>
          </div>

          <div style="position: absolute;
     top: 38%;
    left: 36%;width: 30%">

            <div style="margin-left: 13%">
              <div class="order_title">今日异常上报</div>
              <div class="order_total" style="margin-top: 13%;">0</div>
            </div>
          </div>

          <div style="
    position: absolute;
     top: 38%;
    left: 68%;width: 30%">

            <div style="margin-left: 13%">
              <div class="order_title">临时工单</div>
<!--              <div class="order_total" style="margin-top: 13%;">{{today_finished_orders}}</div>-->
              <div class="order_total" style="margin-top: 13%;">2</div>
            </div>
          </div>
        </div>
      </dv-border-box-10>
      <dv-border-box-10 style="position: absolute;left: 31%;top: 0%;width: 40%;">
        <div class="info">
          <div style="position: absolute;top: 21%; width: 35%;">
            <div style="margin-left: 2%;">
              <div class="order_title" style="font-size: 23px;">莘庄镇</div>
              <div class="order_total" style="margin-top: 13%;">0</div>
            </div>
          </div>
          <div style="position: absolute;top: 21%;left: 30%;width: 35%">
            <div style="margin-left: 2%;">
              <div class="order_title" style="font-size: 23px;">新虹街道</div>
              <div class="order_total" style="margin-top: 13%;">0</div>
            </div>
          </div>
          <div style="position: absolute;top: 21%;left: 60%;width: 35%">
            <div style="margin-left: 2%;">
              <div class="order_title" style="font-size: 23px;">江川路街道</div>
              <div class="order_total" style="margin-top: 13%;">{{summary_barrelage_count.total_count}}</div>
            </div>
          </div>
        </div>
      </dv-border-box-10>
      <dv-border-box-10 style="width: 26%;position: absolute;left: 73%;top: 0%">
        <div class="title"><span>湿垃圾清运情况汇总</span></div>
        <div class="info">
          <div style="position: absolute;
    top: 38%;

    width: 35%;">

            <div style="margin-left: 2%;">
              <div class="order_title">湿垃圾桶数</div>
              <div class="order_total" style="margin-top: 13%;"><span style="color: #f56c6c">16540</span></div>
            </div>
          </div>

          <div style="position: absolute;
     top: 38%;
    left: 36%;width: 30%">
            <!--                <img  src="~@/assets/test.png" style="width: 30px;">-->
            <div style="margin-left: 13%">
              <div class="order_title" >今日异常上报</div>
              <div class="order_total" style="margin-top: 18%;">0</div>
            </div>
          </div>
          <div style="position: absolute;top: 38%;left: 67%;width: 30%">
            <!--                <img  src="~@/assets/test.png" style="width: 30px;">-->
            <div style="margin-left: 13%">
              <div class="order_title">餐厨垃圾桶数</div>
              <div class="order_total" style="margin-top: 18%;">6000</div>
            </div>
          </div>
        </div>
      </dv-border-box-10>
    </div>
    <div class="rows-1">
      <div class="column-1">
        <div style="height: 80%">
          <dv-border-box-12 style="height: 95%;width: 100%;">
            <div class="title" style="width: unset;height: unset"><span style="font-size: 16px">车辆信息</span></div>
            <div  class='systemInfo' style="height: 90%;width: 93%;overflow-y: auto;position: absolute;top: 6%;left: 4%;">
              <table style="font-size: x-small;border-color: unset;width: 100%" class="my_table">
                <thead >
                <tr>
                  <td style="width: 20%">车辆牌照</td>
                  <td style="width: 20%">车辆类型</td>
                  <td style="width: 40%">所在位置</td>
                  <td style="width: 20%">车辆状态</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,key) in cleaning_car" :key="key">
                  <td style="width: 20%">{{item.licenseNumber}}</td>
                  <td style="width: 20%">{{item.vechile_type}}</td>
                  <td style="width: 40%">{{item.address}}</td>
                  <td style="color: yellow;width: 20%">{{item.status}}</td>
                </tr>



                </tbody>
              </table>
            </div>
          </dv-border-box-12>
        </div>

      </div>
      <div class="column-2" v-if="disabled===true">
        <dv-border-box-11 title="地图看板" style="height: 100%">
          <vehicle-map :getChildInfo="getChildInfo" :getOrdersInfo="getOrdersInfo" :vehicle_type="vehicle_type" @my_photo="parentEveng"></vehicle-map>
          <!--            <garbage-box :getChildInfo="getChildInfo"></garbage-box>-->
        </dv-border-box-11>
      </div>
      <div class="column-3">
        <dv-border-box-12 style="height: 45%;width: 91%">
          <div class="title"><span>现场照片</span></div>
          <div style="width: 90%;position: absolute;left: 5%;top:10%;height: 87%">
            <dv-border-box-8 >
              <el-image
                  style="width: 95%; height: 95%;margin-top: 2%"
                  :src="imgUrl2"
                  :preview-src-list="[imgUrl2]"
              />
            </dv-border-box-8>

          </div>

        </dv-border-box-12>
        <dv-border-box-12  style="height: 30%;width: 91%">
          <div class="title" style="padding-top: 1%"><span style="font-size: 16px">湿垃圾减量处置情况</span></div>
          <div  class='systemInfo' style="height: 90%;width: 93%;overflow-y: auto;position: absolute;top: 10%;left: 4%;">
            <table style="font-size: x-small;border-color: unset;width: 100%" class="my_table">
              <thead>
              <tr style="font-size: 15px;font-weight: bold;color: khaki;">
                <td>区域名称</td>
                <td>今日处置量</td>
                <td>今日减量率</td>
                <td>cod指数</td>
              </tr>
              </thead>
              <tbody>
<!--              <tr  v-for="(item,key) in listing" :key="key">-->
<!--                <td>{{item.point_name}}</td>-->
<!--                <td>{{item.daily_barrelage_count}}</td>-->
<!--                <td>12%</td>-->
<!--                <td>0</td>-->
<!--              </tr>-->
              <tr >
                <td>莘庄工业区湿垃圾中转站</td>
                <td>30吨</td>
                <td>43%</td>
                <td>18</td>
              </tr>

              <tr >
                <td>颛桥镇湿垃圾中转站</td>
                <td>46吨</td>
                <td>44%</td>
                <td>16</td>
              </tr>
              </tbody>
            </table>
          </div>
        </dv-border-box-12>
      </div>
    </div>
  </dv-full-screen-container>
</div>
</template>

<script>
import VehicleMap from "@/components/Center1/Map/VehicleMap";
import {
  getDryGarbageInfo
}from '@/api/maintenance'
export default {
  name: "DryClearView",
  components:{
    VehicleMap
  },
  mounted() {
    this.summaryDryGarbageCount();
  },
  methods:{
    summaryDryGarbageCount(){
      let that=this
      getDryGarbageInfo().then(response=>{
          that.listing=response.data.dry_garbage_handle_info
          that.dry_today_orders=response.data.today_barrelage_count
          that.summary_barrelage_count=response.data.summary_barrelage_count[0]
          console.log('干垃圾数据',that.summary_barrelage_count[0])
      })
    },
    parentEveng(data){
      this.imgUrl2=data

    },
    jump(){
      this.disabled=false

      this.$router.back(-1);
    },
    getOrdersInfo(item){
      let that=this

      // var that=this
      // that.today_orders=item.today_orders
      // that.total_orders=item.total_orders
      // that.today_finished_orders=item.today_finished_orders
      that.cleaning_car=item.data
      console.log('干垃圾清运',item)
    },
    getChildInfo(item){
      var that=this
      that.info=item

      console.log('我的项目',item)
    },
  },
  data(){
    return {
      dry_today_orders:0,
      summary_barrelage_count:0,
      disabled:true,
      listing:[],
      imgUrl:'https://storage-api.bfriendgroup.com/uploads/images/clear_attach/1684288634.jpg',
      imgUrl2:'https://storage-api.bfriendgroup.com/uploads/images/clear_attach/1686720982.jpg',
      info:{
        driver:'王松松',
        car_number:'沪DNX978',
        working:'清运中',
        location:'上海市闵行区七莘路',
        street:'新虹片区'
      },
      vehicle_type:'dry_garbage',
      cleaning_car:[]
    }
  }
}
</script>

<style scoped lang="less">
#index{
  color: #f9f9f9;
  background-color: #000000;
  width: 100%;
  height: 100%;
  .container {
    padding: 0.4rem 0.4rem 0.3rem 0.7rem;
    //background-image:  url('../../public/img/bg.png');;
    //background-size: cover;
  }
  /*滚动条样式*/
  .systemInfo::-webkit-scrollbar {/*滚动条整体样式*/
    width: 8px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
  }
  .systemInfo::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: white;
  }
  .systemInfo::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background:#00235f;
  }
  .test{

    display:none;
  }
  .progressbar{
    background: rgb(0, 35, 95);

    border: 0px;

  }
  .progress-label{
    position: absolute;

    color: white;
    /* text-align: center; */
    font-size: 0.5vw;
  }
  table tr{
    height: 30px;
  }

  .order_total{
    margin-top: 2%;
    font-size: 28px;
    font-weight: bold;
    color: burlywood;
  }
  .order_title{
    font-size: 15px;
    font-weight: bolder;
  }
  .title {
    font-size: 20px;
    color: var(--el-color-primary-light-3);
    font-weight: bolder;
    text-align: left;
    /* width: 20%; */
    padding-left: 5%;
    padding-top: 2%;
    //text-shadow:0px 1px 0px #c0c0c0,
    //0px 2px 0px #b0b0b0,
    //0px 3px 0px #a0a0a0,
    //0px 4px 0px #909090,
    //0px 5px 10px rgba(0, 0, 0, .9);
  }
  .header_info{

    // background: blueviolet;
    /* top: 29%; */
    position: absolute;
    width: 100%;
    top: 8%;
    height: 15%;

  }
  .rows-1{
    position: fixed;
    top: 24%;
    // background: cadetblue;
    width: 100%;
    height: 100%;
  }
  .column-3{
    width: 30%;
    height: 100%;
    // background: red;
    position: absolute;
    left: 72%;
    //left: 72%;
    background-position: center 0;
    background-repeat: no-repeat;
  }
  .column-2{
    width: 40%;
    height: 100%;

    position: absolute;
    left: 31%;

    //left: 31%;
  }
  .column-1{
    width: 30%;
    height: 100%;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    float: left;

  }
  .column-1 div{
    width: 100%;
    height: 25%;
  }
  .row-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:  url('../../../public/img/new_title_2.jpg');
    //background-image:  url('../../../public/img/new_title.png');
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    z-index: -100;

  }
}
</style>