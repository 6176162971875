<template>
<div id="index">
  <dv-full-screen-container class="container">
    <div class="row-container">
      <div class="threed">
        <div class="main-header">

        </div>
      </div>
      <div style="text-align: left;
    margin-top: 2%;">
        <el-button

            class="filter-item"
            type="primary"
            icon="el-icon-download"
            style="text-align: center;background:rgb(0, 35, 95);"
            @click="jump_back()"
        >
          返回上级
        </el-button>
      </div>
    </div>

        <div class="header_info">
          <dv-border-box-10 style="width: 30%">
            <div class="title"><span>建筑垃圾清运总车数</span></div>
            <div class="info" style="width: 100%;display: flex;justify-content: space-around;margin-top: 3%;">
              <div  v-for="(item,key) in summary_list" :key="key">
<!--                <img  src="~@/assets/order.png" style="width: 50px;float: left">-->
                <div >
                  <div class="order_title">{{item.name}}</div>
                  <div class="order_total">{{item.cleaning_times}}</div>
                </div>
              </div>

<!--              <div style="position: absolute;-->
<!--     top: 38%;-->
<!--    left: 36%;width: 30%">-->
<!--&lt;!&ndash;                <img  src="~@/assets/order.png" style="width: 50px;float: left">&ndash;&gt;-->
<!--                <div style="margin-left: 13%">-->
<!--                  <div class="order_title">新虹</div>-->
<!--                  <div class="order_total">486</div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div style="-->
<!--    position: absolute;-->
<!--     top: 38%;-->
<!--    left: 68%;width: 30%">-->
<!--&lt;!&ndash;                <img  src="~@/assets/order.png" style="width: 50px;float: left">&ndash;&gt;-->
<!--                <div style="margin-left: 13%">-->
<!--                  <div class="order_title">江川</div>-->
<!--                  <div class="order_total">3</div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </dv-border-box-10>
          <dv-border-box-10 style="position: absolute;left: 31%;top: 0%;width: 40%;">
            <div class="title"><span>建筑垃圾清运情况汇总</span></div>
            <div class="info">
              <div style="position: absolute;
    top: 38%;

    width: 35%;">

                <div style="margin-left: 2%;">
                  <div class="order_title">今日清运工单</div>
                  <div class="order_total" >{{today_orders}}</div>
                </div>



              </div>
              <div style="position: absolute;
     top: 38%;
    left: 15%;width: 45%">

                <div style="margin-left: 2%;">
                  <div class="order_title">已完成工单</div>
                  <div class="order_total">{{today_finished_orders}}</div>
                </div>
              </div>

              <div style="position: absolute;
     top: 38%;
    left: 36%;width: 45%">

                <div style="margin-left: 2%;">
                  <div class="order_title">今日异常工单</div>
                  <div class="order_total">0</div>
                </div>
              </div>

              <div style="position: absolute;
     top: 38%;
    left: 60%;width: 45%">

                <div style="margin-left: 2%;">
                  <div class="order_title">累计清运工单</div>
                  <div class="order_total">{{total_orders}}</div>
                </div>
              </div>


            </div>
          </dv-border-box-10>

          <dv-border-box-10 style="width: 26%;position: absolute;left: 73%;top: 0%">
            <div class="title" style="font-size: 15px;"><span>清运工单统计</span></div>
            <div class="info" style="display: flex;justify-content: center;">
              <dv-capsule-chart :config="config" style="width:330px;height:100px" />
            </div>
          </dv-border-box-10>
        </div>

    <div class="rows-1">


        <div class="column-1">
          <div >

            <dv-border-box-12 style="height: 100%;width: 100%;">
              <div  class='systemInfo'
              style="height: 90%;
    width: 95%;
    overflow-y: auto;
    position: absolute;
    top: 4%;
    left: 4%;
    ">
                <table style="font-size: xx-small;border-color: unset;width: 100%" class="my_table">
                  <thead >
                  <tr>
                    <td style="width: 20%">车辆牌照</td>
                    <td style="width: 20%">车辆类型</td>
                    <td style="width: 40%">所在位置</td>
                    <td style="width: 20%">车辆状态</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,key) in listing.cleaning_car" :key="key">
                    <td style="width: 20%">{{item.licenseNumber}}</td>
                    <td style="width: 20%">{{item.vechile_type}}</td>
                    <td style="width: 40%">{{item.address}}</td>
                    <td style="color: yellow;width: 20%">{{item.status}}</td>
                  </tr>


                  </tbody>
                </table>
              </div>



            </dv-border-box-12>
          </div>
          <div style="height: 50%">

            <dv-border-box-12 style="height: 100%;width: 100%;">
              <div  class='systemInfo'
                    style="height: 90%;
    width: 99%;
    overflow-y: auto;
    position: absolute;
    top: 4%;
    /* left: 4%;
    ">
                <table style="font-size: x-small;border-color: unset;width: 100%" class="my_table">
                  <thead style="border-bottom: 1px solid #f3e6e6;">
                  <tr >
                    <td >点位名称</td>
                    <td>小区地址</td>
                    <td>派单人</td>

                    <td>工单状态</td>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,key) in listing.cleaning_list" :key="key" @click="checkInfo(key)" style="cursor: pointer">
                    <td>{{item.area_name}}</td>
                    <td>{{item.address}}</td>
                    <td>{{item.primary_nickname}}</td>
                    <td style="color: yellow;">{{item.status}}</td>
                  </tr>


                  </tbody>
                </table>
              </div>

            </dv-border-box-12>
          </div>


        </div>


        <div class="column-2" v-if="disabled===true">
          <dv-border-box-11 title="地图看板" style="height: 100%">
            <vehicle-map :getChildInfo="getChildInfo" :getOrdersInfo="getOrdersInfo" :vehicle_type="vehicle_type" @my_photo="parentEveng"></vehicle-map>
<!--            <garbage-box :getChildInfo="getChildInfo"></garbage-box>-->
          </dv-border-box-11>

        </div>


        <div class="column-3">
          <dv-border-box-12 style="height: 45%;width: 91%">
            <div class="title"><span>现场照片</span></div>
            <div style="width: 90%;position: absolute;left: 5%;top:10%;height: 87%">
              <dv-border-box-8 >
                <el-image v-if="is_img_show==true"
                    style="width: 95%; height: 95%;margin-top: 2%"
                    :src="info.imgUrl[index]"
                    :preview-src-list="[info.imgUrl[index]]"
                />
                <div style="position: absolute;
    top: 42%;
    width: 100%;display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;" v-if="is_next==true&&is_img_show==true">
                  <a href="javascript:void(0)" @click="prev" v-show="index!=0" class="right" >
                    <img src="~@/assets/icon/left.png" alt="" style="width: 50px;height: 50px"/>
                  </a>
                  <a href="javascript:void(0)" @click="next" v-show="index<info.imgUrl[index].length-1" class="left" >
                    <img src="~@/assets/icon/right.png" alt="" style="width: 50px;height: 50px"/>
                  </a>


                </div>
              </dv-border-box-8>

            </div>


          </dv-border-box-12>
          <dv-border-box-12  style="height: 30%;width: 91%">
            <div class="title" style="padding-top: 1%"><span>清运信息</span></div>
            <div style="width: 90%;position: absolute;left: 5%;top:10%;height: 87%">
              <dv-border-box-8 >

                <div class="clean_info" style="position: absolute;left: 20%;top: 8%;line-height: 40px;white-space:normal">
                  <div class="order_title">司机名称：<sapn>{{info.driver}}</sapn></div>
                  <div class="order_title">作业车辆：<sapn>{{info.car_number}}</sapn></div>
                  <div class="order_title">所属片区：<sapn>{{info.street}}</sapn></div>
<!--                  <div class="order_title">作业状态：<sapn style="color: yellow">{{info.working}}</sapn></div>-->
                  <div class="order_title">作业地点：<sapn>{{info.location}}</sapn></div>
                  <div class="order_title">清运时间：<sapn> {{info.clean_time}}</sapn></div>

                </div>
              </dv-border-box-8>
            </div>
          </dv-border-box-12>
        </div>

    </div>

  </dv-full-screen-container>
</div>
</template>

<script>
//import GarbageBox from "@/components/Center1/Map/CleanMap";
import VehicleMap from "@/components/Center1/Map/VehicleMap";
import {
 buildGarbageOrders,
  summaryCleaningTimes
}from '@/api/maintenance'
export default {
  name: "OrderInfoView",
  components:{
//    GarbageBox
    VehicleMap
  },

  mounted() {

    this.getBuildGarbageCleaning()
    this.getSummaryCleaning()
    setTimeout(()=>{
      this.checkInfo(0)
    },500)
  },
  created() {

    console.log('路由表222',this.$router);
  },

  methods:{
    parentEveng(data){
      this.imgUrl2=data

    },
    prev: function () {//prev 切换到上一张图片

      this.index--;//此处this指当前对象#mask

    },

    next: function () {//next 切换到下一张图片

      this.index++;//此处this指当前对象#mask

    },
    checkInfo(index){
      let that=this
      if(that.listing.cleaning_list[index].info!=='') {
        that.info=that.listing.cleaning_list[index].info
        that.is_img_show=true
        that.is_next=true
      }
      if(that.info.imgUrl.length==1){
        that.is_next=false
      }
      console.log('我默认选中得信息', that.info)
    },
    getSummaryCleaning(){
      let that=this
      summaryCleaningTimes().then(response=>{
        that.summary_list=response.data
        console.log('清运车次统计',that.summary_list)
      })
    },
    getBuildGarbageCleaning(){
      var that=this
      buildGarbageOrders().then(response=>{
          that.listing.cleaning_list=response.data

      })
    },
    getOrdersInfo(item){
      var that=this
      console.log('我的工单总数',item.outer_ring)
      that.today_orders=item.today_orders
      that.total_orders=item.total_orders
      that.today_finished_orders=item.today_finished_orders
      that.listing.cleaning_car=item.data
      that.config={
        data:item.outer_ring,
        colors: [ '#32C5E9', '#ef5311'],
        unit: '',
        showValue: true
      }

      console.log('我的清运车辆列表',that.listing.cleaning_car)
    },
    getChildInfo(item){
      var that=this
      that.info=item

      console.log('我的项目',item)
    },
    jump_back(){
      this.disabled=false

      this.$router.back(-1);
    },
  },
  data(){
    return {
      config:[],
      disabled:true,
      imgUrl:'https://storage-api.bfriendgroup.com/uploads/images/clear_attach/1678347564.jpg',
      imgUrl2:'https://storage-api.bfriendgroup.com/uploads/images/clear_attach/1679304046.jpg',
      info:{},
      is_next:true,
      is_img_show:false,
      index:0,
      total_orders:0,
      today_orders:0,
      today_finished_orders:0,
      listing:[
        {
          cleaning_car:[],
          cleaning_list:[]
        }
      ],
      summary_list:[],
      vehicle_type:'build_garbage'
    }
  }
}
</script>

<style lang="less" scoped>
#index{
  color: #f9f9f9;
  background-color: #000000;
  width: 100%;
  height: 100%;
  .container {
    padding: 0.4rem 0.4rem 0.3rem 0.7rem;
    //background-image:  url('../../public/img/bg.png');;
    //background-size: cover;
  }
  /*滚动条样式*/
  .systemInfo::-webkit-scrollbar {/*滚动条整体样式*/
    width: 8px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
  }
  .systemInfo::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: white;
  }
  .systemInfo::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background:#00235f;
  }
  .test{

    display:none;
  }
  .progressbar{
    background: rgb(0, 35, 95);

    border: 0px;

  }
  .progress-label{
    position: absolute;

    color: white;
    /* text-align: center; */
    font-size: 0.5vw;
  }
  table tr{
    height: 30px;
  }

  .order_total{
    margin-top: 2%;
    font-size: 28px;
    font-weight: bold;
    color: burlywood;
  }
  .order_title{
    font-size: 15px;
    font-weight: bolder;
  }
  .title {
    font-size: 20px;
    color: var(--el-color-primary-light-3);
    font-weight: bolder;
    text-align: left;
    /* width: 20%; */
    padding-left: 5%;
    padding-top: 2%;
    //text-shadow:0px 1px 0px #c0c0c0,
    //0px 2px 0px #b0b0b0,
    //0px 3px 0px #a0a0a0,
    //0px 4px 0px #909090,
    //0px 5px 10px rgba(0, 0, 0, .9);
  }
  .header_info{

   // background: blueviolet;
    /* top: 29%; */
    position: absolute;
    width: 100%;
    top: 8%;
    height: 15%;

  }
  .rows-1{
    position: fixed;
    top: 24%;
   // background: cadetblue;
    width: 100%;
    height: 100%;
  }
  .column-3{
    width: 30%;
    height: 100%;
   // background: red;
    position: absolute;
    left: 72%;
    //left: 72%;
    background-position: center 0;
    background-repeat: no-repeat;
  }
  .column-2{
    width: 40%;
    height: 100%;

    position: absolute;
    left: 31%;

    //left: 31%;
  }
  .column-1{
    width: 30%;
    height: 100%;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    float: left;

  }
  .column-1 div{
    width: 100%;
    height: 25%;
  }
  .row-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:  url('../../../public/img/new_title_2.jpg');
    //background-image:  url('../../../public/img/new_title.png');
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    z-index: -100;

  }
}
</style>