<template>
  <div id="tab">
    <div class="tab-bar clearfix">
      <a href="javascript:;" :key="index"
         @click="tab(index)"
         v-for="(item,index) in items"
         :class="{active : index===curId}"
      >{{item.item}}</a>
    </div>
    <div class="tab-con">
      <div v-show="curId==0" class="tab-content" @click="openInfoPanel(1)">

        <ul style="display: flex;width:100%">
          <li>
            <div style="height: 20px">当天清运桶数</div>
            <div class="clear_data">{{lists.current_day_count}}桶</div>
          </li>
          <li v-if="lists.word==1">
            <div style="height: 20px">
              <i class="icon-heart"></i><i class="el-icon-view">较昨日上涨</i>
            </div>
            <div style="color: #ec1908;
  font-size: large;">{{lists.percentage}}</div>
          </li>
          <li v-else-if="lists.word==0">
            <div style="height: 20px">
              <i class="icon-heart"></i><i class="el-icon-view">较昨日持平</i>
            </div>
            <div style="color: #08ece4;
  font-size: large;">{{lists.percentage}}</div>
          </li>
          <li v-else-if="lists.word==2">
            <div style="height: 20px">
              <i class="icon-heart"></i><i class="el-icon-view">较昨日下降</i>
            </div>
            <div style="color: #39ec08;
  font-size: large;">{{lists.percentage}}</div>
          </li>
        </ul>
        <ul style="display: flex;width:100%">
          <li>
            <div style="height: 20px">当月清运桶数</div>
            <div class="clear_data">{{lists.current_month_count}}</div>
          </li>
          <li v-if="lists.word_2==2">
            <div style="height: 20px"><i class="icon-heart"></i>较上月同期下降</div>
            <div style="color: #39ec08;
  font-size: large;">{{lists.month_percentage}}</div>
          </li>
          <li v-if="lists.word_2==1">
            <div style="height: 20px"><i class="icon-heart"></i>较上月同期上涨</div>
            <div style="color: #ec1908;
  font-size: large;">{{lists.month_percentage}}</div>
          </li>
          <li v-if="lists.word_2==0">
            <div style="height: 20px"><i class="icon-heart"></i>较上月同期持平</div>
            <div style="color: #08ece4;
  font-size: large;">{{lists.month_percentage}}</div>
          </li>

        </ul>

      </div>
      <div v-show="curId==1" class="tab-content" @click="openInfoPanel(2)">
        <ul style="display: flex;width:100%">
          <li>
            <div style="height: 20px">上月清运重量(kg)</div>
            <div class="clear_data">{{lists.last_month_net_weight}}</div>
          </li>
          <li>
            <div style="height: 20px"><i class="icon-heart"></i>相比较月份清运重量(kg)</div>
            <div style="color: #10ec08;
  font-size: large;">{{lists.compare_last_month_net_weight}}</div>
          </li>
        </ul>
        <ul style="display: flex;width:100%">
          <li v-if="lists.word==2">
            <div style="height: 20px"><i class="icon-heart"></i>同期比较下降</div>
            <div style="color: #39ec08;
  font-size: large;">{{lists.percentage}}</div>
          </li>
          <li v-if="lists.word==1">
            <div style="height: 20px"><i class="icon-heart"></i>同期比较上涨</div>
            <div style="color: #ec1908;
  font-size: large;">{{lists.percentage}}</div>
          </li>
          <li v-if="lists.word==0">
            <div style="height: 20px"><i class="icon-heart"></i>同期比较持平</div>
            <div style="color: #08ece4;
  font-size: large;">{{lists.percentage}}</div>
          </li>


        </ul>
      </div>
    </div>

  </div>
  <div style="width: 100%;height: 100%;background-color: transparent;" v-show="curId==0">
    <div  class='systemInfo' style="height: 100%;width: 100%;overflow-y: auto;">
      <table style="font-size: x-small;border-color: unset;width: 100%" class="my_table">
        <thead>
        <tr style="font-size: 12px;font-weight: bold;color: khaki;">
          <td>清运司机</td>
          <td>本月清运桶数</td>
          <td>今日清运桶数</td>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in lists.driver_clear_info" :class="'tr-color-' + index % 2" :key="index" @click="openDriverInfoPanel(item,1)">
            <td>{{item.driver}}</td>
            <td>{{item.total_barrelage_count_month}}桶</td>
            <td>{{item.total_barrelage_count_day}}桶</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div style="width: 100%;height: 100%" v-show="curId==1">
    <div  class='systemInfo' style="height: 100%;width: 100%;overflow-y: auto;">
      <table style="font-size: x-small;border-color: unset;width: 100%" class="my_table">
        <thead>
        <tr style="font-size: 12px;font-weight: bold;color: khaki;">

          <td>清运司机</td>
          <td>上月清运重量(kg)</td>
          <td>前月清运重量(kg)</td>
          <td>同比增减</td>
        </tr>
        </thead>
        <tbody>
        <!--              <tr  v-for="(item,key) in listing" :key="key">-->
        <!--                <td>{{item.point_name}}</td>-->
        <!--                <td>{{item.daily_barrelage_count}}</td>-->
        <!--                <td>12%</td>-->
        <!--                <td>0</td>-->
        <!--              </tr>-->
        <tr v-for="(item, index) in lists.driver_last_month_weight" :class="'tr-color-' + index % 2" :key="index" @click="openDriverInfoPanel(item,2)">
          <td>{{item.driver}}</td>

          <td>{{item.net_weight}}</td>

          <td>{{item.compare_last_net_weight}}</td>
          <td v-if="item.word==1" style="color: #d43636">{{item.percentage}}</td>
          <td v-if="item.word==0" style="color: #08ece4">{{item.percentage}}</td>
          <td v-if="item.word==2" style="color: #10ec08">{{item.percentage}}</td>
          <td v-if="item.word==3" style="color: #ece808">{{item.percentage}}</td>
        </tr>


        </tbody>
      </table>
    </div>
  </div>
  <clean-trend-panel ref="child_clean_panel"></clean-trend-panel>
  <clean-driver-trend-panel ref="child_clean_driver_panel"></clean-driver-trend-panel>
</template>

<script>
import CleanTrendPanel from "@/components/Center1/Map/Panel/CleanTrendPanel";
import CleanDriverTrendPanel from "@/components/Center1/Map/Panel/CleanDriverTrendPanel";
import {
  getWetGarbageData,
  getDomesticGarbage
}from '@/api/maintenance'
export default {
  name:'TabCard',
  components:{
    CleanDriverTrendPanel,
    CleanTrendPanel
  },
  mounted() {
    this.domesticGarbage()
  },
  data () {
    return {
      curId: 0,
      items: [
        {item: '江川生活垃圾'},
        {item: '长宁生活垃圾'},
      ],
      contents: [
        {content: 'HTML'},
        {content: 'CSS'},
      ],
      lists:[],
      list:[
        {
          driver:'陆家楼',
          month_clear_count:1386,
          month_clear_weight:1569,
          day_clear_count:76,
          day_clear_weight:165
        },
        {
          driver:'贾军',
          month_clear_count:436,
          month_clear_weight:2569,
          day_clear_count:87,
          day_clear_weight:169
        },
        {
          driver:'詹同绿',
          month_clear_count:1386,
          month_clear_weight:5645,
          day_clear_count:92,
          day_clear_weight:234
        },

      ]
    }
  },

  methods: {
    wetGarbage(){
      let that=this
      getWetGarbageData().then(response=>{
          that.lists=response.data
        console.log('清运湿垃圾垃圾数据', that.lists)
      })
    },
    domesticGarbage(){
      let that=this
      getDomesticGarbage().then(response=>{
        that.lists=response.data
        console.log('清运垃圾数据', that.lists)
      })
    },
    openInfoPanel(item){
      this.$refs.child_clean_panel.openInfoPanel(item)
    },
    openDriverInfoPanel(item,is_wet_garbage){
      console.log('是否是江川1',is_wet_garbage)
      this.$refs.child_clean_driver_panel.openInfoPanel(item,is_wet_garbage)
    },
    tab (index) {
      this.curId = index;
      this.lists=[]
      if(this.curId===0){
          this.domesticGarbage()
      }else{
        this.wetGarbage()
      }
    }
  }
}
</script>

<style scoped>
.table tr {
  text-align: center;
  height: 30px;
  cursor: pointer;
}
.table th,td {
  //border: gainsboro 1px solid;
}
.tr-color-0 {
  background: #409eff85;
}
.tr-color-1 {
  background: #1fbbe28a;
}
tbody tr:hover{
  cursor:pointer;
  background-color:rgb(200 192 35 / 64%);
}
#tab {
  width: 100%;

  margin: 6% auto 0;
  display: grid;
}
.tab-bar {
  background-color: #00000061;
}
.tab-bar a {
  float: left;
  width: 25%;
  height: 28px;
  line-height: 28px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 10px;
}
.tab-bar .active {
  background-color: #0670df94;

}
.tab-content {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;

}
.clear_data{
  color: var(--el-color-primary);
  //font-family: fantasy;
  font-size: large;
}
ul{
  list-style-type: none;
  height: 10%;
}
li{
  width: 50%;

}
</style>
