<template>
  <div id="index">
    <dv-full-screen-container class="container">
      <div class="row-container">
        <div class="threed">
          <div class="main-header">
<!--            <div id="tab">-->
<!--              <div class="tab-tit">-->
<!--                &lt;!&ndash;点击设置curId的值  如果curId等于0，第一个a添加cur类名，如果curId等于1，第二个a添加cur类名，以此类推。添加了cur类名，a就会改变样式 @click,:class ,v-show这三个是vue常用的指令或添加事件的方式&ndash;&gt;-->
<!--                <a href="javascript:;" @click="curId=0" :class="{'cur':curId===0}">首页</a>-->
<!--                <a href="javascript:;" @click="curId=1" :class="{'cur':curId===1}">综合养护</a>-->
<!--                <a href="javascript:;" @click="curId=2" :class="{'cur':curId===2}">垃圾清运</a>-->
<!--                <a href="javascript:;" @click="curId=3" :class="{'cur':curId===3}">两网融合</a>-->
<!--                <a href="javascript:;" @click="curId=4" :class="{'cur':curId===4}">设备管理</a>-->
<!--              </div>-->

<!--            </div>-->
            <div style="position: absolute;top: 9%;width: 30%;display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;">
              <div class="time_date" style="width: 33%">{{date}}</div>
              <div v-if="listing.text=='多云'">
                <img :src="require('/public/img/icon/qweather-icons/icons/cloudy.png') " style="width: 30px;position: absolute;top: -28%;
    left: 35%;"></div>
                <div v-if="listing.text=='阴'">
                  <img :src="require('/public/img/icon/qweather-icons/icons/overcast.png') " style="width: 30px;position: absolute;top: -28%;
    left: 35%;">

              </div>
              <div v-if="listing.text=='晴'">
                <img :src="require('/public/img/icon/qweather-icons/icons/clear_day.png') " style="width: 30px;position: absolute;top: -28%;
    left: 35%;">
              </div>
              <div v-if="listing.text=='阵雨'">
                <img :src="require('/public/img/icon/qweather-icons/icons/shower_rain.png') " style="width: 30px;position: absolute;top: -28%;
    left: 35%;">
              </div>
              <div v-if="listing.text=='大雨'">
                <img :src="require('/public/img/icon/qweather-icons/icons/big_rain.png') " style="width: 30px;position: absolute;top: -28%;
    left: 35%;">
              </div>
              <div v-if="listing.text=='小雨'">
                <img :src="require('/public/img/icon/qweather-icons/icons/small_rain.png') " style="width: 30px;position: absolute;top: -28%;
    left: 35%;">
              </div>
              <div style="margin-left: 10%">{{listing.temp}}℃</div>
            </div>
            <img :src="require('/public/img/bf_title.png') " style="height: 100px">

          </div>
        </div>
        <div class="column-1">
          <div class="column-1-row-1">
            <div class="column-1-row-1-1-content">
              <div style="position: absolute;top: 17.8%;left: 5%;">
                <flop_1 :all_data="order_total"></flop_1>
              </div>
              <img :src="require('/public/images/new_platform/data_title.png') " style="height: 100%">
            </div>
          </div>
          <div class="column-1-row-2" >
            <div class="column-1-row-2-1-content">
              <div class="column-1-row-2-1-content-1">
                <span class="order_title">市容一体化</span>
                <div style="margin-top: 2%;margin-left: 6%">
                  <progress_wrapper :data_width="all_event_total_orders"></progress_wrapper>
                </div>
              </div>

              <div class="column-1-row-2-1-content-1">
                <span class="order_title">建筑垃圾清运</span>
                <div style="margin-top: 2%;margin-left: 6%">
                  <progress_wrapper :data_width="all_build_total_orders"></progress_wrapper>
                </div>
              </div>
              <div class="column-1-row-2-1-content-1">
                <span class="order_title">生活垃圾清运</span>
                <div style="margin-top: 2%;margin-left: 6%">
                  <progress_wrapper :data_width="all_live_total_orders"></progress_wrapper>
                </div>
              </div>
              <div class="column-1-row-2-1-content-1">
                <span class="order_title">湿垃圾处置</span>
                <div style="margin-top: 2%;margin-left: 6%">
                  <progress_wrapper :data_width="avg_disposal_volumn"></progress_wrapper>
                </div>
              </div>
              <div class="column-1-row-2-1-content-1">
                <span class="order_title">沪尚回收</span>
                <div style="margin-top: 2%;margin-left: 6%">
                  <progress_wrapper :data_width="all_storage_total_orders"></progress_wrapper>
                </div>
              </div>
            </div>
          </div>

          <!-- 任务数量-->
          <div class="column-1-row-3">

              <div class="tab-tit" style="text-align: left">
                <a href="javascript:;" @click="curId=0" :class="{'cur':curId===0}">可回收物碳中和指数</a>
<!--                <a href="javascript:;" @click="curId=1" :class="{'cur':curId===1}">本月</a>-->
<!--                <a href="javascript:;" @click="curId=2" :class="{'cur':curId===2}">本周</a>-->
              </div>
              <div style="width: 100%;height: 100%;">
                <stacked_column_chart2 :parent_type="curId"></stacked_column_chart2>
              </div>
          </div>
          <div class="column-1-row-4">
            <div >
              <div style="display: flex">
                <div style="width: 50%;display: flex;justify-content: center;">
                  <img  src="~@/assets/1.png" style="width: 50px;">
                  <div>
                    <div style="text-align: left"><span class="article_2">4577人</span></div>
                    <div><span class="son_name">小区用户数量</span></div>
                  </div>
                </div>
                <div style="width: 50%;display: flex;justify-content: center;"><img  src="~@/assets/3.png" style="width: 50px;">
                  <div>
                    <div><span class="article_2">7个</span></div>
                    <div><span class="son_name">街道数量</span></div>
                  </div>
                </div>
              </div>

              <div style="margin-top:4%;display: flex">
                <div style="width: 50%;display: flex;justify-content: center;">
                  <img  src="~@/assets/2.png" style="width: 50px;">
                  <div>
                    <div style="text-align: left"><span class="article_2">44个</span></div>
                    <div><span class="son_name">设备投放小区数量</span></div>
                  </div>
                </div>
                <div style="width: 50%;display: flex;justify-content: center;"><img  src="~@/assets/4.png" style="width: 50px;">
                  <div>
                    <div><span class="article_2">448个</span></div>
                    <div><span class="son_name">网格数量</span></div>
                  </div>
                </div>
              </div>


            </div>
            <div style="height: 40%;display: flex;margin-top: 3%">
              <div style="width: 50%;cursor: pointer" @click="clickCarPanel">
                <div style="display: flex;justify-content: center;">
                  <img  src="~@/assets/5.png" style="width: 100px;">
                  <div style="margin-top: 10%">
                    <div style="text-align: left"><span class="second_article" >{{cars_online}}</span><span style="font-size: smaller">辆</span>
                    </div>
                    <div><span class="son_name_2">实时在线车辆</span></div>
                  </div>
                </div>
              </div>
              <div style="width: 50%;cursor: pointer;" @click="clickWorkerPanel">
                <div style="display: flex;justify-content: center;">
                  <img  src="~@/assets/6.png" style="width: 100px;">
                  <div style="margin-top: 10%">
                    <div style="text-align: left"><span class="second_article" >36</span><span style="font-size: smaller">人</span>
                    </div>
                    <div><span class="son_name_2">实时在线人数</span></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="column-2">
          <div class="column-2-row-1">
            <div style="width: 70%;height: 100%;margin-left: 15%;" class="father_div">
              <div class="img1" @click="clickBuildGarbageBoxPanel"><span>建筑垃圾箱体满仓</span><span style="font-size: 15px;color: white">{{full_bulild_garbage_device}}</span> </div>
              <div class="img2" @click="clickBuildGarbageBoxPanel"><span>建筑垃圾箱体数量</span><span style="font-size: 15px;color: white">{{total_build_garbage_device}}</span></div>
              <div class="img3" @click="clickGarbageBoxPanel"><span>智能回收箱数量</span><span style="font-size: 15px;color: white">{{total_garbage_device}}</span> </div>
              <div class="img4" @click="clickGarbageBoxPanel"><span>智能回收箱满仓</span><span style="font-size: 15px;color: white">{{full_garbage_device}}</span> </div>
            </div>

<!--            <div class="device_summary"> </div>-->
<!--            <div class="device_summary"> </div>-->
<!--            <div class="device_summary"> </div>-->
<!--            <div class="device_summary"> </div>-->

<!--            <div class="device_summary">建筑垃圾箱体满仓10台 </div>-->
<!--            <div class="device_summary">智能建筑垃圾箱数量12 </div>-->
<!--            <div class="device_summary">智能建筑垃圾箱数量12 </div>-->
          </div>
          <div class="column-2-row-2" >
            <div style="width: 60%;height: 100%;position: relative;" >
              <div class="envent_progress">
                <ul style="position: absolute;width: 88%;
    text-align: left;
    margin: 0;line-height: 25px;
    ">
                  <li style="display:flex;">
                    <div>
                      <img :src="require('/public/images/new_platform/button/symbol_2.png') " style="height: 70%">
                    </div>
                    <div style="font-size: small">市容管理</div>
                  </li>
                  <li style="display: flex"><div style="width: 88%;">
                    <progress_wrapper_2 data_width="60" data_background="linear-gradient(to right,rgb(122 130 129 / 0%), #34e8e5)"></progress_wrapper_2>
                  </div><div style="
    font-weight: bolder;margin-top: -2%;"><span style="font-size: larger;">23</span>&nbsp;&nbsp;<span style="color: rgb(52 229 226);font-size: x-large;">件</span></div></li>
                </ul>
                <ul style="position: absolute;width: 88%;top: 17%;
    text-align: left;
    margin: 0;line-height: 25px;">
                  <li style="display:flex;">
                    <div>
                      <img :src="require('/public/images/new_platform/button/symbol_1.png') " style="height: 70%">
                    </div>
                    <div style="font-size: small">道路保洁</div>
                  </li>
                  <li style="display: flex"><div style="width: 88%;">
                    <progress_wrapper_2 data_width="60" data_background="linear-gradient(to right,rgb(122 130 129 / 0%), rgb(32 112 191))"></progress_wrapper_2>
                  </div><div style="
    font-weight: bolder;margin-top: -2%;"><span style="font-size: larger;">31</span>&nbsp;&nbsp;<span style="color: rgb(52 229 226);font-size: x-large;">件</span></div></li>
                </ul>

                <ul style="position: absolute;width: 88%;top: 35%;
    text-align: left;
    margin: 0;line-height: 25px;
    ">
                  <li style="display:flex;">
                    <div>
                      <img :src="require('/public/images/new_platform/button/symbol_2.png') " style="height: 70%">
                    </div>
                    <div style="font-size: small">河道养护</div>
                  </li>
                  <li style="display: flex">
                    <div style="width: 88%;">
                      <progress_wrapper_2 data_width="20" data_background="linear-gradient(to right,rgb(122 130 129 / 0%), #34e8e5)"></progress_wrapper_2>
                  </div>
                    <div style="
    font-weight: bolder;margin-top: -2%;"><span style="font-size: larger;">3</span>&nbsp;&nbsp;<span style="color: rgb(52 229 226);font-size: x-large;">件</span></div></li>
                </ul>
                <ul style="position: absolute;width: 88%;top: 54%;
    text-align: left;
    margin: 0;line-height: 25px;
    ">
                  <li style="display: flex">
                    <div>
                    <img :src="require('/public/images/new_platform/button/symbol_1.png') " style="height: 70%">
              </div>
              <div style="font-size: small">绿化养护</div>
                  </li>
                  <li style="display: flex">
                    <div style="width: 88%;">
                      <progress_wrapper_2 data_width="83" data_background="linear-gradient(to right,rgb(122 130 129 / 0%), rgb(32 112 191))"></progress_wrapper_2>
                    </div><div style="
    font-weight: bolder;margin-top: -2%;"><span style="font-size: larger;">12</span>&nbsp;&nbsp;<span style="color: rgb(52 229 226);font-size: x-large;">件</span></div></li>
                </ul>
                <ul style="position: absolute;width: 88%;top: 73%;
    text-align: left;
    margin: 0;line-height: 25px;
    ">
                  <li style="display: flex">
                    <div>
                      <img :src="require('/public/images/new_platform/button/symbol_2.png') " style="height: 70%">
                    </div>
                    <div style="font-size: small">道路养护</div>
                  </li>
                  <li style="display: flex">
                    <div style="width: 88%;">
                      <progress_wrapper_2 data_width="60" data_background="linear-gradient(to right,rgb(122 130 129 / 0%), #34e8e5)"></progress_wrapper_2>
                    </div>
                    <div style="
    font-weight: bolder;margin-top: -2%;"><span style="font-size: larger;">2</span>&nbsp;&nbsp;<span style="color: rgb(52 229 226);font-size: x-large;">件</span></div>
                  </li>
                </ul>

                <ul style="position: absolute;width: 88%;top: 90%;
    text-align: left;
    margin: 0;line-height: 25px;
    ">
                  <li style="display:flex;">
                    <div>
                      <img :src="require('/public/images/new_platform/button/symbol_1.png') " style="height: 70%">
                    </div>
                    <div  style="font-size: small">
                      管道养护
                    </div>

                  </li>
                  <li style="display: flex"><div style="width: 88%;">
                    <progress_wrapper_2 data_width="60" data_background="linear-gradient(to right,rgb(122 130 129 / 0%), rgb(32 112 191))"></progress_wrapper_2>
                  </div><div style="
    font-weight: bolder;margin-top: -2%;"><span style="font-size: larger;">0</span>&nbsp;&nbsp;<span style="color: rgb(52 229 226);font-size: x-large;">件</span></div></li>
                </ul>
              </div>
            </div>
            <div style="width: 40%;height: 100%">
              <div style="width: 100%;height: 40%;position: relative;">
                <div class="alarm_event" v-if="is_report==true">
<!--                  <div class="cancel"></div>-->
                  <div class="event_title">综合养护事件上报</div>
                  <div class="alarm_info">
                    <div style="position: absolute;
    top: 25%;width: 100%;" >
                      <ul style="display: flex;">
                        <li style="text-align: left;width: 55%;font-size: x-small;">今日上报数</li>
                        <li><div style="position: absolute;
    top: 5%;"><span style="font-size: xx-large;
    color: #2cebbc;
    font-weight: bolder;font-style: italic;">{{childData.today_total_count}}</span><span style="font-size: x-small;">次</span></div></li>
                      </ul>
                      <ul style="display: flex">
                        <li style="text-align: left;width: 55%;font-size: x-small;">本月事件处理总数</li>
                        <li>
                          <div style="position: absolute;
    top: 48%;">
                          <span style="font-size: xx-large;
    color: rgb(230 162 60);;
    font-weight: bolder;font-style: italic;">{{month_event_total}}</span><span>次</span></div></li>
                      </ul>
                    </div>
                  </div>
                </div>
<!--                <dv-border-box-1  style="width: 95%;height: 70%;position: absolute;-->
<!--    top: 30%;-->
<!--    left: 3%;">ffddd</dv-border-box-1>-->
              </div>
              <div style="width: 100%;height: 60%;">
                <div class="outer-box">
                  <div  class="alarm_map">
                    <report_map @report_event="handleChildData"></report_map>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div class="column-2-row-3">
              <div>
                <stacked_line_show ></stacked_line_show>
              </div>
          </div>
        </div>
        <div class="column-3">
          <div class="column-3-row-1" >
            <div class="column-3-row-1-content" v-if="is_report==true">
              <div style="
    position: absolute;
    left: 91%;
    height: 10%;width:10%;cursor: pointer;" @click="close_info"></div>
              <div class="info_title">
                <div>详情</div>
              </div>
              <div class="info_content">
                <div style="background-color: rgb(33 35 36 / 28%);height: 25%;">
                  <ul style="display: flex">
                    <li style="width: 50%;line-height: 35px;font-size: smaller;color: cyan;text-align: left">{{childData.street}}异常</li>
                    <li style="line-height: 35px;width: 42%;font-size: smaller;">所属上级：<span>{{childData.primary_user_id}}</span></li>
                  </ul>
                </div>

                <div style="height: 25%;">
                  <ul style="display: flex">
                    <li style="width: 30%;line-height: 35px;font-size: smaller;text-align: left">事件位置：</li>
                    <li style="line-height: 35px;width: 60%;font-size: large;color: aquamarine;text-align: left;
    font-weight: bold;"><span>{{childData.name}}</span></li>
                  </ul>
                </div>
                <div style="height: 25%;">
                  <ul style="display: flex">
                    <li style="width:30%;line-height: 35px;font-size: smaller;color:rgb(212 54 54 / 82%)"><span>{{childData.report_user_name}}</span></li>
                    <li style="line-height: 35px;width: 50%;font-size: smaller;color: cyan;
    "><span>（{{childData.department}}）</span><span>正在处理</span></li>
                  </ul>
                </div>
                <div style="width: 100%;height: 60%;display: flex;justify-content: space-around;">
                  <div style="width: 30%;display: grid;
    align-content: space-between;"><div  class="primary_content">{{childData.event_type}}</div><div style="font-size: small;
    color: #56a1ea;">异常类型</div></div>
                  <div style="width: 30%;display: grid;
    align-content: space-between;"><div class="primary_content">{{childData.is_self_handle}}</div><div style="font-size: small;
    color: #56a1ea;">事件性质</div></div>
                </div>
                <div class="footer">
                  <ul style="display: flex;font-size: small;color:#56a1ea; ">
                    <li>上报时间：</li>
                    <li>{{childData.report_time}}</li>
                  </ul>
                </div>
              </div>

            </div>

            <div class="column-3-row-1-content" v-if="is_report==false">
              <div class="info_title">
                <div>本月上报</div>
              </div>
              <div style="position: absolute;
    width: 100%;
    height: 84%;
    top: 12%;">
                <div  class='systemInfo'
                      style="height: 95%;
    width: 95%;
    overflow-y: auto;
    position: absolute;
    top:6%;
    left: 4%;
    ">
                  <table style="font-size: x-small;border-color: unset;width: 100%;line-height: 30px;" class="my_table_1" >
                    <thead >
                    <tr >

                      <td>事件类型</td>
                      <td>上报人</td>
                      <td>上报日期</td>
                      <td>操作</td>
                    </tr>
                    </thead>
                    <tbody >
                    <tr v-for="(item,key) in arr2" :key="key" >
                      <td>{{item.child_type}}</td>
                      <td>{{item.name}}</td>
                      <td>{{item.date}}</td>
                      <td v-if="item.status==2" style="color: rgb(16 231 118);">已处理</td>
                      <td style="color: yellow" v-if="item.status==1">待处理</td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="column-3-row-2">
            <div class="menu_lib">

            </div>
            <div style="height: 75%;
    width: 75%;">
              <div class="menu_tag" style="position: absolute;top: 16%;left: 10%;" @click="jump(1)">建筑垃圾清运</div>
              <div class="menu_tag" style="position: absolute;top: 33%;left: 10%;" @click="jump(5)">生活垃圾清运</div>
              <div class="menu_tag" style="position: absolute;top: 45%;left: 55%;">综合养护</div>
              <div class="menu_tag" style="position: absolute;top: 61%;left: 23%;">湿垃圾处置</div>
              <div class="menu_tag" style="position: absolute;top: 25%;left: 60%;">沪尚回收</div>
              <div class="menu_tag" style="position: absolute;top: 60%;left: 60%;" @click="handleClick">监控管理</div>
            </div>
          </div>
        </div>
<!--        <div class="tab-con">-->
          <!--根据curId的值显示div,如果curId等于0，第一个div显示，其它三个div不显示。如果curId等于1，第二个div显示，其它三个div不显示。以此类推-->
<!--          <div v-show="curId===0" class="curID">-->
<!--            <transition name="slide-fade">-->
<!--              <div class="column-1">-->
<!--                <div class="column-1-row-1">-->
<!--                  <div class="column-1-row-1-1-content" >-->
<!--                    cccc-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </transition>-->

<!--          </div>-->
<!--          <div v-show="curId===1" class="curID">-->
<!--            aaa-->
<!--          </div>-->
<!--          <div v-show="curId===2" class="curID">-->
<!--            fff-->
<!--          </div>-->
<!--          <div v-show="curId===3" class="curID">-->
<!--            javascript-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </dv-full-screen-container>
    <monitor_panel ref="child1"></monitor_panel>
    <gps_map ref="child2"></gps_map>
    <gps_worker ref="child3"></gps_worker>
    <garbage_box_map ref="child4"></garbage_box_map>
    <build_garbage_box ref="child5"></build_garbage_box>
  </div>
</template>

<script>
import {
  getWeatherInfo
}from '@/api/maintenance'
import flop_1 from "@/components/New/Flop/flop_1";
import progress_wrapper from "@/components/New/Progress/progress_wrapper";
import stacked_column_chart2 from "@/components/New/Echarts/stacked_column_chart2";
import report_map from "@/components/Center1/Map/ReportMap";
import progress_wrapper_2 from "@/components/New/Progress/progress_wrapper_2";
import stacked_line_show from "@/components/New/Echarts/stacked_line_show";
import {
  eventReportingList
}from '@/api/maintenance'
import monitor_panel from "@/components/Right/MonitorPanel"
import gps_map from "@/components/Center1/Map/GPSMap"
import gps_worker from "@/components/Center1/Map/GPSWorker"
import garbage_box_map from "@/components/Center1/Map/GarbageBoxMap"
import build_garbage_box from "@/components/Center1/Map/BuildGarbageBox"
export default {
  name: "DataView",
  components:{
    monitor_panel,
    flop_1,
    progress_wrapper,
    stacked_column_chart2,
    progress_wrapper_2,
    stacked_line_show,
    report_map,
    gps_map,
    gps_worker,
    garbage_box_map,
    build_garbage_box
  },
  data(){
    return {
      is_monitor:false,
      curId: 0,
      date: '',
      timer:'',
      listing:[],
      src: '',
      order_total:1260,
      ws:null,
      result:[],
      all_event_total_orders:0,
      all_build_total_orders:0,
      all_live_total_orders:0,
      all_storage_total_orders:0,
      avg_disposal_volumn:0,
      cars_online:46,
      childData:'',
      is_report:false,
      month_event_total:0,
      total_garbage_device:0,
      full_garbage_device:0,
      total_build_garbage_device:0,
      full_bulild_garbage_device:0,
      arr2:[]
    }
  },
  mounted() {
    this.updateTime()
    this.wetherInfo()
    this.eventsReporting()
    this.heartCheck()
    this.cellHandler()
    window.onbeforeunload = function () {
      this.ws.close();
    }
  },

  methods: {
    jump(event){

      if(event===1){
        // this.$router.push({
        //   name: "order_info",
        //
        // }).catch(err=>{
        //   console.log(err)
        // })
        this.$router.push({path:"/order"})
      //  this.$route.path()

      }else if(event===6){
        this.$router.push({path: "/road"})

      }else if(event===4){
        this.$router.push({path:'/transfer'})

      }else if(event===5){
        //this.$router.push({path:'dry'})
        window.location.href='/dry'
      }

    },
    handleClick(){
      this.$refs.child1.openMonitor()
    },
    clickCarPanel(){
      this.$refs.child2.openCarGps()
    },
    clickWorkerPanel(){
      this.$refs.child3.openWorkerGps()
    },
    clickGarbageBoxPanel(){
      this.$refs.child4.openGarbageDevice()
    },
    clickBuildGarbageBoxPanel(){
      this.$refs.child5.openBuildGarbagePanel()
    },
    eventsReporting(){
      var that=this
      eventReportingList().then(response=>{
        that.arr2=response.data
        console.log('我的测试数据',that.arr2)
      })
    },
    close_info(){
      this.is_report=false
    },
    handleChildData(data){
      this.childData=data
      if(this.childData){
        this.eventsReporting()
        this.is_report=true
      }
      console.log('我的子组件数据', this.childData)
    },
    cellHandler(){
      let that=this
      that.ws=new WebSocket("ws://test.bfriendgroup.com:8502");
      that.ws.onopen = function () {
        console.log('websocket连接成功2111111');
      };
      that.ws.onerror=function (err){
        console.log('是否有错误',err)
      }
      that.ws.onmessage=function (e){
        console.log('我的socket数据',e)
       that.result=JSON.parse(e.data)
        if(that.result.today_order){
          that.order_total=that.result.today_order.now_total_orders
        }
        if(that.result.month_event_total){
            that.month_event_total=that.result.today_order.month_event_total
        }
        if(that.result.order_progress){
          that.all_event_total_orders=that.result.order_progress.progress.all_event_total_orders
          that.all_build_total_orders=that.result.order_progress.progress.all_build_total_orders
          that.all_live_total_orders=that.result.order_progress.progress.all_live_total_orders
          that.all_storage_total_orders=that.result.order_progress.progress.all_storage_total_orders
          that.avg_disposal_volumn=that.result.order_progress.progress.avg_disposal_volumn
        }
        if(that.result.cars_online){
          that.cars_online=that.result.cars_online
        }
        if(that.result.about_garbage_device){
          that.total_garbage_device=that.result.about_garbage_device.total_garbage_device
          that.full_garbage_device=that.result.about_garbage_device.full_garbage_device
          that.total_build_garbage_device=that.result.about_garbage_device.total_build_garbage_device
          that.full_bulild_garbage_device=that.result.about_garbage_device.full_bulild_garbage_device
        }

       // console.log('我的socket数据',that.result)
      }
    },
    heartCheck(){
      clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(() => {
        let that = this;
        //console.log(that.$global.ws.readyState)
        if(that.ws && that.ws.readyState==1) {
          that.ws.send("new_index")
        }

      }, 1000 *2)
    },
    wetherInfo(){
      let that=this
      getWeatherInfo().then(response=>{
        that.listing=response.data
        console.log('我的天气',that.listing.text)
      })
    },
    updateTime(){
      setInterval(() => {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();
        month = (month < 10) ? "0" + month : month;
        day = (day < 10) ? "0" + day : day;
        hour = (hour < 10) ? "0" + hour : hour;
        min = (min < 10) ? "0" + min : min;
        sec = (sec < 10) ? "0" + sec : sec;
       this.date=year + "." + month + "." + day + " " + hour + ":" + min + ":" + sec;
      }, 1000);
    }
  }
}
</script>

<style lang="less" scoped>
#index {
  color: #f9f9f9;
  background-color: #000000;
  width: 100%;
  height: 100%;
  .outer-box{
    height: 80%;
    width: 70%;
    border-radius: 50%;/*将图案设置成圆形，改变该属性的值，可以调整圆弧大小*/

    overflow: hidden;/*防止外边距塌陷*/

    margin-top: 8%;
    margin-left: 15%;
    border: 1px dot-dash var(--el-color-info);
  }
  .img1{
    background-image: url('../../../src/assets/button/1.png');
    display: flex;
    justify-content: center;
    align-items: center;
    color: aqua;
    cursor: pointer;
  }
  .img2{
    background-image: url('../../../src/assets/button/2.png');
    display: flex;
    justify-content: center;
    align-items: center;
    color: aqua;
    cursor: pointer;
  }
  .img3{
    background-image: url('../../../src/assets/button/3.png');
    display: flex;
    justify-content: center;
    align-items: center;
    color: aqua;
    cursor: pointer;
  }
  .img4{
    background-image: url('../../../src/assets/button/4.png');
    display: flex;
    justify-content: center;
    align-items: center;
    color: aqua;
    cursor: pointer;
  }
  .father_div{
    display: flex;
  }
  .father_div div{
    font-size: 9px;

    width: 35%;
    height: 100%;
    // background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
  }
  ul{
    list-style-type: none;
  }
  .menu_tag{
    font-size: xx-small;
    background-color: #0b4a8c;
    width: 21%;
    height: 4%;
    cursor: pointer;
  }
  .menu_lib{
   // background-color: #d43636;
    height: 75%;
    width: 75%;
    background-image: url('../../../public/images/new_platform/menu_lib.png');
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    position: absolute;
    left: 10%;
    -webkit-transform: rotate(360deg);
    animation: unCircel  40s linear infinite;
    -moz-animation: unCircel  40s linear infinite;
    -webkit-animation: unCircel  40s linear infinite;
  }
  @keyframes unCircel{
    from {-webkit-transform: rotate(360deg);}
    to {-webkit-transform: rotate(0deg);}
  }
  .envent_progress{
    width: 100%;
    height: 60%;
    //background-color: rgb(50, 224, 19);
    position: absolute;
    top: 26%;
    left: 9%;
  }
  .event_title{
    position: absolute;
    left: 10%;
    top: 17%;
    width: 80%;
    font-weight: 600;
    font-style: italic;
    text-align: left;
    //font-weight: bolder;
    background: #a2e0e0 linear-gradient(4deg, transparent 25%, #ceefeb 60%, transparent 60%, transparent) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
  }
  .cancel{
    width: 10%;height: 12%;
    background-image: url('../../../public/images/new_platform/cancel.png');
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    position: absolute;
    left: 86%;
    top: 7%;
    cursor: pointer;
  }
  .alarm_event{
    width: 83%;
    height: 70%;
    position: absolute;
    top: 30%;
    left: 13%;
    background-image: url('../../../public/images/new_platform/alarm_event.png');
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
  }
  .alarm_map{

    height: 92%;
    width: 92%;
    margin-top: 7px;
    margin-left: 7px;
   // background-color: #e08e13;

    border-radius: 50%;/*将图案设置成圆形，改变该属性的值，可以调整圆弧大小*/

    overflow: hidden;/*防止外边距塌陷*/
    border-style: solid;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#e78d20,direction=120,strength=4);
    -moz-box-shadow: 3px 3px 10px #e78d20;
    -webkit-box-shadow: 3px 3px 10px #e78d20;
    box-shadow:3px 3px 7px #e78d20;
    border: 2px solid var(--el-color-warning);

  }
  .column-1{
    //position: fixed;
    //top: 8%;

   // height: 96%;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    //background-color: #d43636;
  }
  .column-1-row-2-1-content{
    width: 100%;
    height: 100%;
  // background-color: #e0de13;
   // border:1px solid #2070bf;
  }
  .order_title{
    font-size: 11px;
    margin-left: 1%;
  }
  .column-1-row-2-1-content-1{
    width: 100%;
    text-align: left;
  }
  .column-2{
    position: absolute;
    left: 25%;
    margin: 0;
    padding: 0;

    //background-color: #a5952a;
  }

  .column-3{
    position: absolute;
    left: 73%;
    width: 27%;
    //background-color: palevioletred;
  }
  .column-3-row-1-content{
    width: 82%;
    height: 95%;
    /*background-color: rgb(66,185,131);*/
    position: relative;
    top: 5%;
    left: 10%;
  }
  .column-3-row-1{
    width: 100%;
    height: 50%;
    //background-color: #afd1ea;
    background-image: url('../../../public/images/new_platform/1.png');
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;

  }
  .info_title{
    position: absolute;
    top: 4%;
    left: 30%;
    font-weight: 800;
    font-size: x-large;
  }
  .primary_content{
    margin-top: 25%;
    font-size: larger;
    background-image: linear-gradient(to bottom, #f8edef, #abbde8);
    color: transparent;
    -webkit-background-clip: text;
    font-weight: bold;
  }
  .footer{
    height: 25%;
    width: 100%;
    //background-color: rgba(240, 255, 255, 0.37);
    line-height: 40px;
  }
  .info_content{
    position: absolute;
    width: 100%;
    height: 40%;
    top:19%;
   // background-color: #d436364d;
  }
  .column-3-row-2{
    width: 100%;
    height: 50%;
    position: relative;
    //background-color: #dc117f;
  }
  .time_date{
    border-right: 1px solid red;
  }
  .el-tabs__item {
    padding: 0 20px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 15px;
    font-weight: 500;
    color: white;
    position: relative;
  }
  #tab{
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 2%;
  }
  .tab-tit{
    font-size: 0;
   // width: 600px;
  }
  .tab-tit a{
    display: inline-block;
    /* height: 40px; */
    line-height: 45px;
    font-size: 11px;
    width: 35%;
    text-align: center;
    //background: #ecf5ff24;
    font-weight: 500;
    color: #2987e5;
    text-decoration: none;
    background-image: url('../../../public/images/new_platform/button/1.png');
   // background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;

  }
  .tab-tit .cur{
    background-image: url('../../../public/images/new_platform/button/2.png');
    // background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    color: #d9b752;
  }
  .tab-con{
    height: 100%;
    width: 100%;

  }
  .tab-con .curID{
    //background: #d43636;
    height: 100%;
    width: 100%;
    //background: #e0de13;
  }
  .container {
    padding: 0.4rem 0.4rem 0.3rem 0.7rem;
    // background: #e0de13;
    //background-image:  url('../../public/img/bg.png');;
    //background-size: cover;
  }
  .second_article{
    font-size: x-large;

  }
  .article_2{
    font-size: x-large;
    font-weight: bolder;
    background: #ceefeb linear-gradient(135deg, transparent 25%, #13e0e0 60%, transparent 60%, transparent) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: scratchy 1.5s linear infinite;
    animation: scratchy 1.5s linear infinite;
  }
  .son_name{
    font-size: small;
    color: #05b7eb;
  }
  .son_name_2{
    font-size: small;
    color: var(--el-menu-active-color);
    font-weight: bolder;
    font-style: italic;
  }

  .column-1-row-1{
    width: 100%;
    height: 15%;
  }
  .column-2-row-1{
    width: 100%;
    height: 5%;
    //background-color: #d43636;

  }
  .column-2-row-2{
    width: 100%;
    height: 50%;
    display: flex;
   // background-color: #0fd4ef;
    background-image: url('../../../src/assets/event.png');

    background-repeat: no-repeat;
   // background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;

  }
  .column-2-row-3{
    width: 100%;
    height: 45%;
   // background-color: #e0de13;
  }
  .column-1-row-2{
    width: 100%;
    height: 27%;
  }
  .column-1-row-3{
    width: 100%;
    height: 20%;
    //border:1px solid #2070bf;
  }
  .column-1-row-4{
    width: 100%;
    height:35%;
   // border:1px solid #2070bf;

  }
  .column-1-row-1-1-content{
   // position: absolute;
    width: 100%;
    height:100%;

    background-position: center 0;
    background-repeat: no-repeat;
   // background-color: #d43636;
  }

  .main-header{
    width: 100%;
    height: 102px;
    //background-image: url('../../../public/img/bf_title.png');
    //background-position: center 0;
    //background-repeat: no-repeat;
    //background-size: cover;
    //-webkit-background-size: cover;
    //-o-background-size: cover;
    //-moz-background-size: cover;
    //-ms-background-size: cover;

  }
  .row-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image:  url('../../../public/img/background_img2.jpg');
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    z-index: -100;

  }
}
</style>