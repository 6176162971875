<template>
  <el-dialog :width="500" :title="title" v-model="dialogVisible" custom-class="dialog_width_clean" :modal='modal' :before-close="outClosePanel">
  <div style="width: 100%">
    <stacked_line_clean_summary :is_domestic-garbage="item"></stacked_line_clean_summary>
  </div>
  </el-dialog>
</template>

<script>
import stacked_line_clean_summary from "@/components/New/Echarts/stacked_line_clean_summary";
export default {
name: "CleanTrendPanel",
  components:{
    stacked_line_clean_summary
  },
  data(){
    return {
      listing:[],
      dialogVisible:false,
      item:[],
      modal:false,
      listQuery:{},
      title:"生活垃圾清运走势"

    }
  },
  methods:{
    async outClosePanel(){
      this.dialogVisible=false
    },
    async openInfoPanel(item){
      this.item=''
      this.title=''
      if(item==1){
        this.title='江川生活垃圾清运走势'
      }else{
        this.title='长宁湿垃圾清运走势'
      }

      this.dialogVisible=true
      this.item=item

      console.log('我的信息',item)
    }
  }
}
</script>

<style lang="less">
.dialog_width_clean{
  // margin-top: 20px !important;

  height: 30%;
  box-sizing: border-box;
  background-color: var(--el-text-color-primary) !important;
  border-top: 1px solid #0e6b9f;
  border-bottom: 1px solid #0e6b9f;
  border-radius: 0 !important;
  overflow-y: auto;
  position: absolute;
  left: 16.8%;
  top: 42%;


  .el-dialog__header{
    padding: unset;
    margin-right: unset;
    word-break: break-all;
    background-color: #0000ff6b;
    width: 100%;
    height: 18%;
    line-height: 42px;
    text-align: left;
  }
  .el-dialog__title{
    font-size: small;
    padding-left: 10px
  }
  .el-dialog__body{
    padding: 0;

  }
}
</style>