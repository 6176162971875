<template>
  <div class="persons-data-view_3">
    <div class="persons-row-container_2" id="container">

    </div>
  </div>
</template>

<script>
export default {
name: "ReportMap",
  data(){
    return {
      markers:[121.37845811631945, 31.107472059461806],
      map:'',
      marker:'',
      ws:null

    }
  },
  mounted: async function () {
    await this.initMap()
    await this.cellHandler()
    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  unmounted(){
    this.ws.close();
    this.map?.destroy();
  },
  created() {
    //this.initMap()
    //this.heartCheck()
    this.cellHandler()
  },
  methods:{

    async cellHandler(){
      let that=this
      that.ws=new WebSocket("ws://test.bfriendgroup.com:8502");
      that.ws.onopen = function () {
        console.log('websocket连接成功map');
      };
      that.ws.onclose = function () {
        // 关闭 websocket
        console.log("连接已关闭...");
        //断线重新连接

      };
      that.marker=null;
      that.ws.onmessage=function (e){
        that.message=JSON.parse(e.data)
        if(that.message.event_report){
          that.$emit('report_event',that.message.event_report)
          that.marker = new AMap.Marker({
            position: new AMap.LngLat(that.message.event_report.longitude,that.message.event_report.latitude),
            title: "上海市"
          });
          that.marker.setMap(that.map);
          that.map.setCenter([that.message.event_report.longitude,that.message.event_report.latitude])

        }
      }

    },
    async initMap(){
      const AMap = await this.$amapPromise;
      this.map = new AMap.Map("container", {
        center: [121.3778404405382, 31.107730305989584],
        zoom: 13,
        mapStyle: "amap://styles/grey",
        resizeEnable: true,
        rotateEnable:true,
        pitchEnable:true,
        pitch:-50,
        rotation:-15,
        viewMode:'3D',//开启3D视图,默认为关闭
        buildingAnimation:true,//楼块出现是否带动画

        expandZoomRange:true,
        showIndoorMap: false,
        zooms:[3,20],
      });

      // AMap.plugin(['AMap.HawkEye','AMap.Scale','AMap.ToolBar','AMap.ControlBar','AMap.MapType'], () => {
      //   // 将缩略图控件添加到地图实例中
      //   //map.addControl(new AMap.HawkEye())
      //   this.map.addControl(new AMap.Scale())
      //   this.map.addControl(new AMap.ToolBar())
      //   this.map.addControl(new AMap.ControlBar())
      //   this.map.addControl(new AMap.MapType())
      //
      // })

    }
  }
}
</script>

<style lang="less">
.amap-demo {
  position: fixed;
  width: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.amap-info-content {
  font-size: 25px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: #00D4E9 3px solid;
  border-radius: 10px;

}

.bottom-center .amap-info-sharp {
  bottom: 0;
  font-size: 25px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #00D4E9;
}

.amap-info-close {
  display: none;
}
.amap-icon img {
  position: unset;
  z-index: -1;
  width: 30px;
  height: 30px;
}
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
.persons-data-view_3 {
  //position: relative;
  //top: 10%;
  //left: 3%;
  width: 100%;
  height: 100%;

  background-color: #030409;
  color: #fff;

  ///////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////

  .persons-back {
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 5px;
    width: 500px;
    height: 145px;
  }

  .persons-row-container_2{
    width: 100%;
    height:100%;
  }
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}

</style>