<template>
  <el-dialog  :title="item.nickName"  v-model="dialogVisible"
                custom-class="dialogwidth" :before-close="outClosePanel">
    <div class="marker_info">
      <div class="left-info" >
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 25%"><span class="info_title">姓名:</span></li>
          <li><span class="info_content">{{item.nickName}}</span></li>
        </ul>
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 25%"><span class="info_title">所属岗位:</span></li>
          <li><span class="info_content">{{item.roleId}}</span></li>
        </ul>
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 25%"><span class="info_title">今日班次:</span></li>
          <li style="align-content: flex-end;
    flex-wrap: wrap;display: flex"><span class="info_content">6:00-11:00,15:00-17:00</span></li>
        </ul>
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 32%"><span class="info_title">今日上报事件:</span></li>
          <li><span class="info_content">{{listing.current_day_events}}</span></li>
        </ul>
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 25%"><span class="info_title">定位时间:</span></li>
          <li style="align-content: flex-end;
    flex-wrap: wrap;display: flex"><span class="info_content">{{listing.online_time}}</span></li>
        </ul>
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 25%"><span class="info_title">定位地点:</span></li>
          <li><span class="info_content">{{listing.address}}</span></li>
        </ul>
      </div>
      <div class="center-info">
        <img :src="src" style="height:482px;width:100%">
      </div>
      <div class="right-info">
        <ul style="width: 100%;" class="panel_ul">
          <li style="width: 32%"><span class="info_title">当月已处理事件:</span></li>
          <li><span class="info_content">{{listing.current_month_event_count}}</span></li>
        </ul>
<!--        <ul style="width: 100%;">-->
<!--          <li style="width: 30%"><span class="info_title">最新报警:</span></li>-->
<!--          <li><span class="info_content">0</span></li>-->
<!--        </ul>-->
<!--        <ul style="width: 100%;">-->
<!--          <li style="width: 32%"><span class="info_title">最新报警时间:</span></li>-->
<!--          <li><span class="info_content">2023-10-15 10:43</span></li>-->
<!--        </ul>-->
        <ul style="width: 100%;" class="panel_ul">
          <li style="width: 30%"><span class="info_title">当天事件上报数:</span></li>
          <li><span class="info_content">{{listing.current_day_event_count}}</span></li>
        </ul>
        <ul style="width: 100%;" class="panel_ul">
          <li style="width: 30%"><span class="info_title">作业状态</span></li>
          <li><span class="info_content">{{item.workerStatus}}</span></li>
        </ul>
        <ul style="width: 100%;display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-content: stretch;
    justify-content: space-evenly;" class="panel_ul">

          <li style="width: 100%;"><img_carousel :event_photos="listing.handle_photo"></img_carousel></li>
        </ul>

      </div>
    </div>
  </el-dialog>
</template>

<script>
import img_carousel from "@/components/Carousel/ImgCarousel"
import cleaner_img from "@/assets/info_img/cleaner.png"
import city_worker from "@/assets/info_img/city_worker.png"
import {

  getWorkerInfo
}from '@/api/maintenance'
export default {
name: "MapInfoPanel",
  components:{
    img_carousel
  },
  data(){
    return {
      dialogVisible:false,
      item:[],
      //src:"~@/assets/info_img/cleaner.png"
      src:cleaner_img,
      listQuery:{
        username:'',
        latitude:'',
        longitude:'',
        create_time:''
      },
      listing:[]
    }
  },
  mounted() {

  },
  methods:{
    async outClosePanel(){
      this.dialogVisible=false
    },
    async openInfoPanel(item){
      this.item=item
      if(item.roleId=='市容队员') {
          this.src=city_worker
      }else{
        this.src=cleaner_img
      }
      this.listQuery.create_time=item.createTime
      this.listQuery.latitude=item.latitude
      this.listQuery.longitude=item.longitude
      this.listQuery.username=item.nickName
      console.log('我的信息',item)
      this.dialogVisible=true
      this.workInfoForMan()
    },
    workInfoForMan(){
      let that=this
      getWorkerInfo(this.listQuery).then(response=>{
          that.listing=response.data
          console.log('我的工作情况',that.listing.handle_photo)
      })
    }
  }
}
</script>

<style lang="less">
.dialogwidth{
  margin-top: 80px !important;
  width: 60% !important;
  height: 63% !important;
  box-sizing: border-box;
  background-color: rgb(3 39 60 / 50%) !important;
  border-top: 1px solid #0e6b9f;
  border-bottom: 1px solid #0e6b9f;
  border-radius: 0 !important;
  overflow-y: auto;
  .el-dialog__header{
    padding: unset;
    margin-right: unset;
    word-break: break-all;
    background-color: #0000ff6b;
    width: 100%;
    height: 10%;
    line-height: 50px;
  }
}
.marker_info{
 // background-color: #d43636;
  width: 100%;
  height: 100%;
  display: flex;
}
.left-info{
  width: 40%;
  line-height: 60px;
  //background-color: #00235f;
}
.center-info{
  //width: 30%;
  //height: 100%;
  //background-color: #e0de13;
}
.right-info{
  width: 50%;
  line-height: 40px;
  //background-color: #00aaff;
}
.panel_ul{
  list-style-type: none;
  padding: 0;
  display: flex;
  width: 100%;
  /* justify-content: space-evenly; */
  font-size: large;
  color: aliceblue;
  font-weight: 600;
  font-family: cursive;

}
.info_title{
  color: #00aaff;
}
.info_content{

}
</style>