<template>
  <el-dialog
      title="车辆监控"
      v-model="dialogVisible"
      custom-class="gps-map-dialog" :before-close="outClose">
    <div class="persons-data-view_4">
      <div class="persons-row-container_3" id="container_2">
      </div>
    </div>
  </el-dialog>
  <map-vehicle-info-panel ref="child_vehicle_panel_info"></map-vehicle-info-panel>
</template>

<script>
import MapVehicleInfoPanel from "@/components/Center1/Map/Panel/MapVehicleInfoPanel"

export default {
name: "GPSMap",
  components: {MapVehicleInfoPanel},
  data(){
    return {
      markers:[],
      map:'',

      dialogVisible:false,
      param:'build_garbage',
      div_content:'',
      infoWindow:[],
      ws:""
    }
  },
  mounted: async function (){
    //await this.initMap()

    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  created: async function(){

  },

  // unmounted(){
  //   this.ws.close();
  //   this.map?.destroy();
  // },

  methods:{
    openVehicleInfoPanel(item){
      this.$refs.child_vehicle_panel_info.openInfoPanel(item)
    },
    async outClose(){
      console.log('我的关闭----')
      this.dialogVisible=false
      this.ws.close();
      this.map?.destroy();
      clearInterval(this.timeoutObj)
    },
    async openCarGps(){
      this.dialogVisible=true
      await this.initMap()
      await this.cellHandler()
      await this.heartCheck()
    },
    async heartCheck(){
      clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(() => {
        let that = this;
        console.log('我要的传值',this.param)
        if(that.ws && that.ws.readyState==1) {
          that.ws.send(that.param)
        }

      }, 1000 * 5)
    },
     isExist(oldCarNumber){

      let that=this
      for(let i=0;i<that.markers.length;i++){
          let car_number=that.markers[i].getExtData().car_number
        if(car_number==oldCarNumber){
           let index=i

          return index
        }
      }
    },
    async cellHandler(){
      let that=this
      let marker=[];
      // let windows=[];


      that.ws = new WebSocket("wss://www.bfriendgroup.cn:8502");
      //that.ws = new WebSocket("ws://test.bfriendgroup.com:8502");
      that.ws.onopen = function () {
        console.log('websocket连接成功map');
      };
      that.ws.onclose = function () {
        // 关闭 websocket
        console.log("连接已关闭...");
        //断线重新连接

      };
      that.ws.onmessage=function(e){

        // windows=[]
        that.message=JSON.parse(e.data)
        console.log('我得车辆',that.message)
        let pointMarker=that.message.data
        if(that.markers.length>0){
          that.markers.forEach((item,index)=>{
            that.map.remove(that.markers[index])


          })


        }
        that.markers=[]
        pointMarker.forEach((item)=>{
          marker = new AMap.Marker({
            position: new AMap.LngLat(item.position[0],item.position[1]),
            title: "上海市",
            icon:item.icon,
            extData:{car_number:item.licenseNumber},
            //offset: new AMap.Pixel(-15,-15)
          });
         // console.log('我的车辆',marker.getExtData().car_number)
          marker.on('click', () => {
            that.openVehicleInfoPanel(item)
            // that.infoWindow=new AMap.InfoWindow({
            //   isCustom:false,
            //   closeWhenClickMap:true,
            //   content: '<div style=""><text style="color:#028E8F;font-size: 12px;">车牌 : </text><text style="color:#fff;font-size: 12px;">'
            //       +item.licenseNumber +'</text><br><text style="color:#028E8F;	font-size: 12px;">车辆位置 : </text><text style="color:#fff;	font-size: 12px;">' +
            //       item.address+'</text><br><text style="color:#028E8F;	font-size: 12px;">车辆状态 : </text><text style="color:#fff;font-size: 12px;">' +
            //       item.status+ '</text><br><text style="color:#028E8F;	font-size: 12px;">车辆类型 : </text><text style="color:#fff;font-size: 12px;">' +
            //       item.vechile_type+ '</text></div>',
            // //  offset:new AMap.Pixel(0,-15),
            //   autoMove: true,
            //   showShadow:true
            // })
            // console.log("Marker被点击",item.position[0]+'...'+item.position[1]);
            // that.infoWindow.open(
            //     that.map,
            //     // 窗口信息的位置
            //     item.position
            // );
            // 其他操作...
          });

          // that.map.on("zoomend",() => {
          //   // 关闭信息窗体
          //   that.map.clearInfoWindow(that.infoWindow);
          // })
          that.markers.push(marker)

          marker.setMap(that.map)

        })


      }

    },
    async initMap(){
      const AMap = await this.$amapPromise;
      this.map = new AMap.Map('container_2', {
        center: [121.3778404405382, 31.107730305989584],
        zoom: 11,
        mapStyle: "amap://styles/grey",
        resizeEnable: true,
        rotateEnable:true,
        pitchEnable:true,
        pitch:-50,
        rotation:-15,
        viewMode:'3D',//开启3D视图,默认为关闭
        buildingAnimation:true,//楼块出现是否带动画

        expandZoomRange:true,
        showIndoorMap: false,
        zooms:[3,20],
       // mapStyle: "amap://styles/grey",
      });
      AMap.plugin(['AMap.HawkEye','AMap.Scale','AMap.ToolBar','AMap.ControlBar','AMap.MapType'], () => {
        // 将缩略图控件添加到地图实例中
        // this.map.addControl(new AMap.HawkEye())
        // this.map.addControl(new AMap.Scale())
        // this.map.addControl(new AMap.ToolBar())
        // this.map.addControl(new AMap.ControlBar())
        //this.map.addControl(new AMap.MapType())

      })
    }
  }
}
</script>

<style lang="less">
.amap-demo {
  position: fixed;
  width: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.amap-info-content {
  font-size: 25px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: #00D4E9 3px solid;
  border-radius: 10px;

}

.bottom-center .amap-info-sharp {
  bottom: 0;
  font-size: 25px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #00D4E9;
}

.amap-info-close {
  display: none;
}
.amap-icon img {
  position: unset;
  z-index: -1;
  width: 30px;
  height: 30px;
}
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
.persons-data-view_4 {
  //position: relative;
  //top: 10%;
  //left: 3%;
  width: 100%;
  height: 100%;

  background-color: #030409;
  color: #fff;

  ///////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////


  .persons-row-container_3{
    width: 100%;
    height:800px;
  }
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}
.el-link{
  color: #f9f9f9;
}
//.el-dialog{
//  margin-top: 20px !important;
//  width: 90% !important;
//  height: 90% !important;
//  box-sizing: border-box;
//  background-color: rgb(3 39 60 / 50%) !important;
//  border-top: 1px solid #0e6b9f;
//  border-bottom: 1px solid #0e6b9f;
//  border-radius: 0 !important;
//}

.el-dialog__title {
  font-family: Ruizi4;
  line-height: 24px;
  font-size: 18px;
  color: #fff !important;
}
</style>