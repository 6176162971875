<template>
  <div class="Left-3">
    <div class="d-flex mt-1 jc-center body-box">
      <div id="recycling_pie" style="width: 100%; height:300px;position: absolute;top: 10%"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  name: "recycling_pie",
  props:['category_data_info'],

  mounted() {


      this.info=this.category_data_info
      this.drawChart()
      console.log('子组件的数据',this.category_data_info)
  },

  data(){
    return {
      info:[]
    }
  },

  methods:{
    drawChart(){
      let myDom = document.getElementById('recycling_pie');
      myDom.removeAttribute('_echarts_instance_');
      let myChart = echarts.init(myDom);
      let option = {

        tooltip: {
          trigger: 'item'
        },
        legend: {
         // orient: 'vertcal',
          left: 'center',
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
           // color:'#0be55b'
          },
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#ef6567', '#f9c956', '#75bedc', '#ecb13a','#0be55b'],
        series: [
          {

            type: 'pie',
            radius: '50%',
            data: this.info,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" scoped>
.Left-3{

  .body-box {
    border-radius: 10px;
    overflow: hidden;

  }
}
#container {

  width:100%;
  height:500px;
}

</style>