<template>
  <el-dialog
      title="人员监管"
      v-model="dialogVisible"
      custom-class="gps-map-dialog" :before-close="outClose"
  >
    <div class="persons-data-view_5">
      <div class="persons-row-container_4" id="container_3">
      </div>
    </div>
  </el-dialog>
<map-info-panel ref="child_panel_info" ></map-info-panel>
</template>

<script>
import MapInfoPanel from "@/components/Center1/Map/Panel/MapInfoPanel"

export default {
name: "GPSWorker",
  components: {MapInfoPanel},
  data(){
    return {
     // markers:[121.37845811631945, 31.107472059461806],
      map:'',
      markers:[],
      dialogVisible:false,
      div_content:'',
      infoWindow:[],
      message:null,
    }
  },
  mounted: async function (){
  //  await this.initMap()

    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  unmounted(){
    // this.ws.close();
    // this.map?.destroy();
  },
  created() {

    // this.heartCheck()
    // this.cellHandler()
  },
  methods:{
     openInfoPanel(item){
      this.$refs.child_panel_info.openInfoPanel(item)
    },
    async openWorkerGps(){
      this.dialogVisible=true
      await this.initMap()
      await this.cellHandler()
      await this.heartCheck()
    },
    async outClose(){
      console.log('我的关闭222----')
      this.dialogVisible=false
      this.ws.close();
      this.map?.destroy();
      clearInterval(this.timeoutObj)
    },
    async heartCheck(){
      clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(() => {
        let that = this;

        if(that.ws && that.ws.readyState==1) {
          that.ws.send("心跳ing")
        }

      }, 1000 * 5)
    },
    async initMap(){
      const AMap = await this.$amapPromise;
      this.map = new AMap.Map('container_3', {
        center: [121.3778404405382, 31.107730305989584],
        zoom: 11,
        mapStyle: "amap://styles/grey",
        resizeEnable: true,
        rotateEnable:true,
        pitchEnable:true,
        pitch:-50,
        rotation:-15,
        viewMode:'3D',//开启3D视图,默认为关闭
        buildingAnimation:true,//楼块出现是否带动画

        expandZoomRange:true,
        showIndoorMap: false,
        zooms:[3,20],
        // mapStyle: "amap://styles/grey",
      });
      AMap.plugin(['AMap.HawkEye','AMap.Scale','AMap.ToolBar','AMap.ControlBar','AMap.MapType'], () => {
        // 将缩略图控件添加到地图实例中
        this.map.addControl(new AMap.HawkEye())
        this.map.addControl(new AMap.Scale())
        this.map.addControl(new AMap.ToolBar())
        this.map.addControl(new AMap.ControlBar())
        //this.map.addControl(new AMap.MapType())

      })
    },
    async cellHandler(){
      let that=this
      let marker=[];
      that.ws = new WebSocket("wss://www.bfriendgroup.com:8501?userId=1&roleId=4");
      that.ws.onopen = function () {
        console.log('websocket_2连接成功map');
      };
      that.ws.onclose = function () {
        // 关闭 websocket
        console.log("连接已关闭...");
        //断线重新连接

      };
      that.ws.onmessage=function(e){
        that.message=JSON.parse(e.data)
        if(that.message) {
          if(that.markers.length>0){
            that.markers.forEach((item,index)=>{
              that.map.remove(that.markers[index])

            })
          }
          that.markers=[]
          that.message.forEach((item)=>{
            if(item.onLine==1){
              item.onLine='在线'
              item.iconPath='/images/vechile_logo/blue_p1.png'

            }else{
              item.onLine='离线'
              item.iconPath='/images/vechile_logo/grey_p1.png'
            }
            if(item.workerStatus==1){
              item.workerStatus='超时报警'
            }else{
              item.workerStatus='正常'
            }
            if(item.roleId==6){
              item.roleId='道路保洁人员'
            }else if(item.roleId==21){
              item.roleId='市容队员'
            }else{
              item.roleId='其他'
            }

            marker=new AMap.Marker({
              position: new AMap.LngLat(item.longitude,item.latitude),
              icon:item.iconPath,
              title: "上海市",
            })
            marker.on('click',()=>{
              that.openInfoPanel(item)

              // that.infoWindow=new AMap.InfoWindow({
              //   isCustom:false,
              //   closeWhenClickMap:true,
              //   content:''+
              //       '<div style=""><text style="color:#028E8F;font-size: 12px;">人员名称 : </text><text style="color:#fff;font-size: 12px;">'
              //       +item.nickName +'</text><br><text style="color:#028E8F;	font-size: 12px;">人员电话 : </text><text style="color:#fff;	font-size: 12px;">' +
              //       item.mobile+'</text><br><text style="color:#028E8F;	font-size: 12px;">状态 : </text><text style="color:#fff;font-size: 12px;">' +
              //       item.onLine+ '</text><br><text style="color:#028E8F;	font-size: 12px;">职位 : </text><text style="color:#fff;	font-size: 12px;">' +
              //       item.roleId+'</text><br></div>',
              //   autoMove: true,
              //   showShadow:true
              // })
              console.log("人员被点击",item.longitude+','+item.latitude);
              // that.infoWindow.open(
              //     that.map,
              //     // 窗口信息的位置
              //    [item.longitude,item.latitude]
              // );
            })
            // that.map.on("zoomend",() => {
            //   // 关闭信息窗体
            //   that.map.clearInfoWindow(that.infoWindow);
            // })
            //console.log('我的marker',index)
            that.markers.push(marker)
            marker.setMap(that.map)
          })
        }
        console.log('我得人员',that.message)
      }
    }
  }
}
</script>

<style lang="less">
.amap-demo {
  position: fixed;
  width: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.amap-info-content {
  font-size: 25px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: #00D4E9 3px solid;
  border-radius: 10px;

}

.bottom-center .amap-info-sharp {
  bottom: 0;
  font-size: 25px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #00D4E9;
}

.amap-info-close {
  display: none;
}
.amap-icon img {
  position: unset;
  z-index: -1;
  width: 30px;
  height: 30px;
}
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
.persons-data-view_5 {
  //position: relative;
  //top: 10%;
  //left: 3%;
  width: 100%;
  height: 100%;

  background-color: #030409;
  color: #fff;

  ///////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////


  .persons-row-container_4{
    width: 100%;
    height:800px;
  }
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}
.el-link{
  color: #f9f9f9;
}
.gps-map-dialog{
  margin-top: 20px !important;
  width: 90% !important;
  height: 90% !important;
  box-sizing: border-box;
  background-color: rgb(3 39 60 / 50%) !important;
  border-top: 1px solid #0e6b9f;
  border-bottom: 1px solid #0e6b9f;
  border-radius: 0 !important;
  overflow-y: auto;
}

.el-dialog__title {
  font-family: Ruizi4;
  line-height: 24px;
  font-size: 18px;
  color: #fff !important;
}

</style>