<template>

  <router-view/>
</template>
<script>
import {
  carLists
} from '@/api/car'
export default {
  name: 'App',
  components: {},
  data() {
    return {
      message:[]
    }
  },
  created() {
    //this.localSocket();
    //this.start();
    //this.getList()
    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  methods:{
    getList(){
      carLists().then(response=>{
        console.log('测试',response)
      })
    },
    localSocket(){
      let that = this;
      if ("WebSocket" in window) {
        that.ws = new WebSocket("ws://test.bfriendgroup.com:8502");

        that.$global.setWs(that.ws)
        that.ws.onopen = function () {
          console.log('websocket连接成功');
        };
        // that.ws.onmessage=function(e){
        //   that.message=JSON.parse(e.data)
        //   if(that.message.code==0&&that.message.type=='runStateOption'){
        //     that.$global.device_status=that.message
        //   }
        //   console.log(JSON.parse(e.data))
        //
        // }
        that.ws.onclose = function () {
          // 关闭 websocket
          console.log("连接已关闭...");
          //断线重新连接
          setTimeout(() => {
            that.localSocket();
          }, 2000);
        };
      } else {
        // 浏览器不支持 WebSocket
        console.log("您的浏览器不支持 WebSocket!");
      }
    },
    start() {
      clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(() => {
        let that = this;
        if (that.$global.ws && that.$global.ws.readyState == 1) {
          that.$global.ws.send("index");
          console.log('发送心跳...index');
        }
      }, 1000 * 60)
    }
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
