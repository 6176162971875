<template>
  <div class="persons-data-view_2">
    <div class="persons-row-container">
      <el-amap class="amap-box" :zoom="zoom" :center="center" :map-style=mapStyle
               :rotate-enable=mapRoute
               :view-mode=viewMode

      >
        <el-amap-marker
            v-for="marker in markers"
            :position="marker.position"
            :key="marker.id"
            :icon="marker.icon"
            @click="marker.events.test()"

        >

        </el-amap-marker>
        <el-amap-info-window
            :position="window.position"
            :visible="window.visible"
            :content="window.content"
            :offset="window.offset"
            :close-when-click-map="true"

        ></el-amap-info-window>
      </el-amap>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkerMap",
  data(){
    return {
      center: [121.320435, 31.171072],
      zoom:13,
      viewMode:"3D",
      mapRoute:true,

      mapStyle: "amap://styles/grey", //设置地图样式
      markers:[],
      windows:[],
      window:[],
      message:[]
    }
  },
  mounted() {
    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  unmounted(){
    this.ws.close();
  },
  created() {

    this.heartCheck()
    this.cellHandler()
  },
  methods:{
    cellHandler(){
      let markers=[];
      let windows=[];
      let that=this;
      let div_content=null
      that.ws = new WebSocket("wss://www.bfriendgroup.com:8501?userId=1&roleId=4");
      //that.ws=new WebSocket("ws://test.bfriendgroup.com:8501?userId=1&roleId=4")
      that.ws.onopen = function () {
        console.log('websocket连接成功map');
      };
      that.ws.onclose = function () {
        // 关闭 websocket
        console.log("连接已关闭...");
        //断线重新连接

      };
      that.ws.onmessage=function (e){
        markers=[]
        windows=[]
        that.message=JSON.parse(e.data)
       // console.log('原始数据',that.message)

        that.message.forEach((item,index)=>{
          if(item.onLine==1){
            item.onLine='在线'
            item.iconPath='/images/vechile_logo/blue_p1.png'

          }else{
            item.onLine='离线'
            item.iconPath='/images/vechile_logo/grey_p1.png'
          }
          if(item.workerStatus==1){
            item.workerStatus='超时报警'
          }else{
            item.workerStatus='正常'
          }
          if(item.roleId==6){
            item.roleId='道路保洁人员'
          }else if(item.roleId==21){
            item.roleId='市容队员'
          }else{
            item.roleId='其他'
          }
          markers.push({
            position:[item.longitude,item.latitude],
            icon:item.iconPath,
            events:{
              test(){

                that.windows.forEach(window=>{
                  window.visible=false
                })
                that.window=that.windows[index];
                that.$nextTick(()=>{
                  that.window.visible=true
                })
              }
            }
          })

          div_content=''+
              '<div style=""><text style="color:#028E8F;font-size: 12px;">人员名称 : </text><text style="color:#fff;font-size: 12px;">'
              +item.nickName +'</text><br><text style="color:#028E8F;	font-size: 12px;">人员电话 : </text><text style="color:#fff;	font-size: 12px;">' +
              item.mobile+'</text><br><text style="color:#028E8F;	font-size: 12px;">状态 : </text><text style="color:#fff;font-size: 12px;">' +
              item.onLine+ '</text><br><text style="color:#028E8F;	font-size: 12px;">职位 : </text><text style="color:#fff;	font-size: 12px;">' +
              item.roleId+'</text><br></div>'
          windows.push({
            position:[item.longitude,item.latitude],
            content: div_content
            // "<div >"+"车牌"+item.licenseNumber+"</div>"+
            // "<div >"+"车辆位置："+item.address+"</div>"+
            // "<div >"+"状态："+item.status+"</div>"
            ,
            // content:'ccccc',
            offset:[10,-35],

            visible: false
          })

        })
       // console.log('新的人员结构111',markers)
        that.markers = markers;
        that.windows=windows
      }

    },
    heartCheck(){
      clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(() => {
        let that = this;
        //console.log(that.$global.ws.readyState)
        if(that.ws && that.ws.readyState==1) {
          that.ws.send("心跳ing")
        }

      }, 1000 * 5)
    },
  }
}
</script>

<style lang="less">
.amap-demo {
  position: fixed;
  width: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.amap-info-content {
  font-size: 25px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: #00D4E9 3px solid;
  border-radius: 10px;

}

.bottom-center .amap-info-sharp {
  bottom: 0;
  font-size: 25px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #00D4E9;
}

.amap-info-close {
  display: none;
}
.amap-icon img {
  position: unset;
  z-index: -1;
  width: 30px;
  height: 30px;
}
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
.persons-data-view_2 {
  //position: relative;
  //top: 10%;
  //left: 3%;
  width: 100%;
  height: 100%;

  background-color: #030409;
  color: #fff;

  ///////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////

  .persons-back {
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 5px;
    width: 500px;
    height: 145px;
  }

  .persons-row-container {
    width: 100%;
    height:100%;
  }
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}

</style>