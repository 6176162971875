<template>
  <div class="Left-3">
    <div class="d-flex mt-1 jc-center body-box">
      <div id="maintenance_pie" style="width: 100%; height:200px;position: absolute;top: 10%"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import {
  eventsEchartsPie
}from '@/api/maintenance'
export default {
  name: "maintenance_pie",
  mounted() {
    this.echartsPieData()


  },
  data(){
    return {
      listing:[]
    }
  },
  methods:{
    echartsPieData(){
      var that=this
      eventsEchartsPie().then(response=>{
        that.listing=response.data
        this.drawChart()
        console.log('饼图数据',that.listing)
      })
    },
    drawChart(){
      let myDom = document.getElementById('maintenance_pie');
      myDom.removeAttribute('_echarts_instance_');
      let myChart = echarts.init(myDom);
      let option = {

        tooltip: {
          trigger: 'item'
        },
        legend: {
           orient: 'vertcal',
          left: 'left',

          y:'center',
          textStyle:{
            fontSize: 11,//字体大小
            color: '#ffffff'//字体颜色
            // color:'#0be55b'
          },
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#ef6567', '#f9c956', '#75bedc'],
        series: [
          {

            type: 'pie',
            radius: '65%',
            center:['60%','45%'],
            // data: [
            //   { value: 2239, name: '市容管理' },
            //   { value: 228, name: '绿化养护' },
            //   { value: 881, name: '道路养护' },
            //   { value: 157, name: '河道养护' },
            //   { value: 67, name: '管道养护' },
            //   { value: 1257, name: '道路保洁' },
            //
            // ],
            data:this.listing,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" scoped>
.Left-3{

  .body-box {
    border-radius: 10px;
    overflow: hidden;

  }
}
#container {

  width:100%;
  height:400px;
}

</style>