<template>
  <el-dialog
      title="智能建筑垃圾箱分布"
      v-model="dialogVisible"
      custom-class="gps-map-dialog" :before-close="outClose"
  >
    <div class="persons-data-view_7">
      <div class="persons-row-container_6" id="container_5">
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
name: "BuildGarbageBox",
  data(){
    return {
      dialogVisible:false,
      markers:[121.37845811631945, 31.107472059461806],
      map:'',
      marker:'',
      div_content:'',
      infoWindow:[],
      message:null,
      ws:null
    }
  },
  mounted: async function (){
   //   await this.initMap()

    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  unmounted(){
    // this.ws.close();
    // this.map?.destroy();
  },
  created: async function(){

  },
  methods:{
    async outClose(){
      this.dialogVisible=false
      this.ws.close();
      this.map?.destroy();
      clearInterval(this.timeoutObj)
    },
    async openBuildGarbagePanel(){
      this.dialogVisible=true
      await this.initMap()
      await this.cellHandler()
      await this.heartCheck()
    },
    async heartCheck(){
      clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(() => {
        let that = this;

        if(that.ws && that.ws.readyState==1) {
          that.ws.send("garbage_room")
        }

      }, 1000 * 5)
    },
    async initMap(){
      const AMap = await this.$amapPromise;
      this.map = new AMap.Map('container_5', {
        center: [121.3778404405382, 31.107730305989584],
        zoom: 11,
        mapStyle: "amap://styles/grey",
        resizeEnable: true,
        rotateEnable:true,
        pitchEnable:true,
        pitch:-50,
        rotation:-15,
        viewMode:'3D',//开启3D视图,默认为关闭
        buildingAnimation:true,//楼块出现是否带动画

        expandZoomRange:true,
        showIndoorMap: false,
        zooms:[3,20],
        // mapStyle: "amap://styles/grey",
      });
      AMap.plugin(['AMap.HawkEye','AMap.Scale','AMap.ToolBar','AMap.ControlBar','AMap.MapType'], () => {
        // 将缩略图控件添加到地图实例中
        this.map.addControl(new AMap.HawkEye())
        this.map.addControl(new AMap.Scale())
        this.map.addControl(new AMap.ToolBar())
        this.map.addControl(new AMap.ControlBar())
        //this.map.addControl(new AMap.MapType())

      })
    },
    async cellHandler(){
      let that=this
      that.ws=new WebSocket("ws://test.bfriendgroup.com:8502");
      that.ws.onopen = function () {
        console.log('websocket连接成功2111111');
      };
      that.ws.onerror=function (err){
        console.log('是否有错误',err)
      }
      that.ws.onmessage=function (e){
        that.message=JSON.parse(e.data)
        if(that.message){

          that.message.garbage.forEach((item)=>{
            that.marker=new AMap.Marker({
              position: new AMap.LngLat(item.position[0],item.position[1]),
              icon:item.icon,
              title: "上海市",
            })
            that.marker.on('click',()=>{
              that.infoWindow=new AMap.InfoWindow({
                isCustom:false,
                closeWhenClickMap:true,
                content:""+
                    "<div style='color:#b8e3e3;font-size: 12px;'>"+"设备名称："+item.device_id+"</div>"+
                    "<div style='color:#cde1e2;	font-size: 12px;'>"+"设备承载："+item.loading_capacity+"</div>"+
                    "<div style='color:#d3e8e8;	font-size: 12px;'>"+"设备电量："+item.electricity_quantity+"</div>"+
                    "<div style='color:#cde1e2;	font-size: 12px;'>"+"设备温度："+item.electricity_temp+"</div>",
                autoMove: true,
                showShadow:true
              })
              that.infoWindow.open(
                  that.map,
                  // 窗口信息的位置
                  item.position
              );
            })
            that.map.on("zoomend",() => {
              // 关闭信息窗体
              that.map.clearInfoWindow(that.infoWindow);
            })
            //console.log('我的marker',index)
            that.marker.setMap(that.map);
          })
        }
      }
    }
  }
}
</script>

<style lang="less">
.amap-demo {
  position: fixed;
  width: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.amap-info-content {
  font-size: 25px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: #00D4E9 3px solid;
  border-radius: 10px;

}

.bottom-center .amap-info-sharp {
  bottom: 0;
  font-size: 25px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #00D4E9;
}

.amap-info-close {
  display: none;
}
.amap-icon img {
  position: unset;
  z-index: -1;
  width: 30px;
  height: 30px;
}
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
.persons-data-view_7 {
  //position: relative;
  //top: 10%;
  //left: 3%;
  width: 100%;
  height: 100%;

  background-color: #030409;
  color: #fff;

  ///////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////


  .persons-row-container_6{
    width: 100%;
    height:800px;
  }
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}
.el-link{
  color: #f9f9f9;
}
//.el-dialog{
//  margin-top: 20px !important;
//  width: 90% !important;
//  height: 90% !important;
//  box-sizing: border-box;
//  background-color: rgb(3 39 60 / 50%) !important;
//  border-top: 1px solid #0e6b9f;
//  border-bottom: 1px solid #0e6b9f;
//  border-radius: 0 !important;
//}

.el-dialog__title {
  font-family: Ruizi4;
  line-height: 24px;
  font-size: 18px;
  color: #fff !important;
}


</style>