<template>
  <div class="persons-data-view">
    <div class="persons-back">
      <!--      <el-table-->
      <!--          v-loading="loading"-->
      <!--          :data="personList"-->
      <!--          max-height="145"-->
      <!--          stripe-->
      <!--          @cell-click="cellHandler"-->
      <!--          style="width: 100%; height: 145px"-->
      <!--      >-->
      <!--        <el-table-column-->
      <!--            width="100"-->
      <!--            prop="licenseNumber"-->
      <!--            label="车牌">-->
      <!--        </el-table-column>-->
      <!--        <el-table-column-->
      <!--            width="200"-->
      <!--            prop="address"-->
      <!--            label="地址">-->
      <!--        </el-table-column>-->
      <!--        <el-table-column-->
      <!--            width="60"-->
      <!--            prop="status"-->
      <!--            label="状态">-->
      <!--        </el-table-column>-->
      <!--        <el-table-column-->
      <!--            prop="vechile_type"-->
      <!--            label="车辆类型">-->
      <!--        </el-table-column>-->
      <!--      </el-table>-->

    </div>


    <div class="persons-row-container">

      <!--        <el-amap class="amap-box" :zoom="zoom" :center="center" :map-style=mapStyle-->
      <!--                 :rotate-enable=mapRoute-->
      <!--                 :view-mode=viewMode-->
      <!--                 :vid="amapDemo"-->
      <!--        >-->

      <el-amap class="amap-box" :zoom="zoom" :center="center" :map-style=mapStyle
               :rotate-enable=mapRoute
               :view-mode=viewMode

               :rotation=rotation
:plugin="plugin"
      >
        <el-amap-marker
            v-for="marker in markers"
            :position="marker.position"
            :key="marker.id"
            :icon="marker.icon"
            @click="marker.events.test()"
        >

        </el-amap-marker>
        <el-amap-info-window

            :position="window.position"
            :visible="window.visible"
            :content="window.content"
            :offset="window.offset"
            :close-when-click-map="true"
        ></el-amap-info-window>
      </el-amap>

    </div>
  </div>
</template>

<script>
export default {
  name: "GarbageBox",
  props:['getSummaryData','getUserDelivery'],
  data(){
    return {
      loading:false,

      center: [121.37590030809707,31.088615234018363],
      zoom:10,
      viewMode:"3D",
      terrain:true,
      mapRoute:true,
      rotation:'30',
      mapStyle: "amap://styles/grey", //设置地图样式
      markers:[],
      windows:[],
      window:[],
      lng: 0,
      lat: 0,
      message:'',
      events: {
        click() {
          console.log('test')
        }
      },
      plugin: ['ToolBar', {
        pName: 'MapType',
        defaultType: 0,
        events: {
          init (o) {
            console.log(o)
          }
        }
      }, {
        pName: 'Scale',
        events: {
          init (instance) {
            console.log(instance)
          }
        }
      }, {
        pName: 'OverView',
        events: {
          init (instance) {
            console.log(instance)
          }
        }
      }]


    }
  },
  created() {
    this.heartCheck()
    this.cellHandler()
    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  methods:{
    sendSummaryData(info){
      this.getSummaryData(info)
    },
    sendUserOrderDetail(data){
      this.getUserDelivery(data)
    },
    cellHandler(){
      let markers=[];
      let windows=[];
      let that=this;
      that.ws = new WebSocket("wss://www.bfriendgroup.cn:8502");
     // that.ws = new WebSocket("ws://test.bfriendgroup.com:8502");
      that.ws.onopen = function () {
        console.log('websocket连接成功map');
      };
      that.ws.onclose = function () {
        // 关闭 websocket
        console.log("连接已关闭...");
        //断线重新连接

      };
      that.ws.onmessage=function(e){
        that.message=JSON.parse(e.data)
        markers=[]
        windows=[]
        console.log('我智能回收',that.message)
        let pointMarker=that.message.show_device
        that.sendSummaryData(that.message.storage)
        that.sendUserOrderDetail(that.message.smart_room)
        pointMarker.forEach((item,index)=>{
          markers.push({
            position:item.position,
            icon:item.icon,
            events:{
              test(){

                that.windows.forEach(window=>{
                  window.visible=false
                })
                that.window=that.windows[index];
                that.$nextTick(()=>{
                  that.window.visible=true
                })
              }
            }
          })
          windows.push({
            position:item.position,
            content:""+
                "<div style='color:#b8e3e3;font-size: 12px;'>"+"设备名称："+item.device_name+"</div>"+
                "<div style='color:#cde1e2;	font-size: 12px;'>"+"设备位置："+item.village+"</div>"+
                "<div style='color:#d3e8e8;	font-size: 12px;'>"+"状态："+item.sta+"</div>"
            ,
            //content:'ccccc',
            offset:[10,-35],

            visible: false
          })

        })
        that.markers = markers;
        console.log('地图',markers)
        that.windows=windows

      }
    },
    heartCheck(){
      clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(() => {
        let that = this;
        //console.log(that.$global.ws.readyState)
        if(that.ws && that.ws.readyState==1) {
          that.ws.send("smart_room")
        }

      }, 1000 * 5)
    },
  }
}
</script>
<!--<style lang="scss">-->

<!--</style>-->
<style lang="less">
.amap-demo {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.amap-info-content {
  font-size: 25px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: #00D4E9 3px solid;
  border-radius: 10px;

}

.bottom-center .amap-info-sharp {
  bottom: 0;
  font-size: 25px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #00D4E9;
}

.amap-info-close {
  display: none;
}
.amap-icon img {
  position: unset;
  z-index: -1;
  width: 30px;
  height: 30px;
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}
.persons-data-view {
  position: relative;
  top: 10%;
  left: 3%;
  width: 94%;
  height: 88%;
  background-color: #030409;
  color: #fff;

  ///////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////

  .persons-back {
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 5px;
    width: 500px;
   // height: 145px;
  }

  .persons-row-container {
    width: 100%;
    height: 100%;
  }
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}
</style>