import request from '@/utils/request'
// 清运车辆列表
export function summaryReporting(query) {
    return request({
        url: 'api/maintenance/getTotalEventReport',
        method: 'post',
        params: query
    })
}
//wether
export function getWeatherInfo(query){
    return request({
        url: 'api/Maintenance/getWeatherInfo',
        method: 'post',
        params: query
    })
}
export function eventReportingList(query){
    return request({
        url: 'api/maintenance/eventReportingList',
        method: 'post',
        params: query
    })
}
export function eventsForEcharts(query){
    return request({
        url: 'api/maintenance/eventsEcharts',
        method: 'post',
        params: query
    })
}
export function eventsForColumn(query){
    return request({
        url: 'api/maintenance/columnData',
        method: 'post',
        params: query
    })
}
export function getWorkerInfo(query){
    return request({
        url: 'api/maintenance/getWorkerInfo',
        method: 'post',
        params: query
    })
}
export function getVehicleInfo(query){
    return request({
        url:'api/maintenance/getVehicleInfo',
        method:'post',
        params:query
    })
}
export function eventsEchartsPie(query){
    return request({
        url: 'api/maintenance/eventsEchartsPie',
        method: 'post',
        params: query
    })
}
export function buildGarbageOrders(query){
    return request({
        url: 'api/maintenance/buildOrders',
        method: 'post',
        params: query
    })
}
export function summaryCleaningTimes(query){
    return request({
        url: 'api/maintenance/summaryVehiclesTimes',
        method: 'post',
        params: query
    })

}
export function summaryOutCategory(query){
    return request({
        url: 'api/maintenance/summaryOutCategory',
        method: 'post',
        params: query
    })
}
export function getClientsRanking(query){
    return request({
        url: 'api/maintenance/clientsRanking',
        method: 'post',
        params: query
    })
}
export function getDryGarbageInfo(query){
    return request({
        url: 'api/maintenance/drySummaryData',
        method: 'post',
        params: query
    })
}
export function workerInfo(query){
    return request({
        url: 'api/maintenance/drySummaryData',
        method: 'post',
        params: query
    })
}
export function getDomesticGarbage(query){
    return request({
        url: 'api/maintenance/domesticGarbage',
        method: 'post',
        params: query
    })
}
export function getDomesticGarbageTrend(query){
    return request({
        url: 'api/maintenance/domesticGarbageTrend',
        method: 'post',
        params: query
    })
}
export function getDomesticGarbageDriverTrend(query){
    return request({
        url: 'api/maintenance/domesticGarbageDriverTrend',
        method: 'post',
        params: query
    })
}
export function getWetGarbageData(query){
    return request({
        url: 'api/maintenance/wetGarbageData',
        method: 'post',
        params: query
    })
}
export function getWetGarbageDriverTrend(query){
    return request({
        url: 'api/maintenance/wetGarbageDriverTrend',
        method: 'post',
        params: query
    })
}
export function getWetGarbageTrend(query){
    return request({
        url: 'api/maintenance/wetGarbageTrend',
        method: 'post',
        params: query
    })
}
export function getBuildGarbageInfo(query){
    return request({
        url: 'api/maintenance/buildGarbageInfo',
        method: 'post',
        params: query
    })
}