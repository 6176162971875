<template>
  <vue-seamless-scroll :data="listData" class="warp" :class-option="defaultOption">
    <ul class="item">
      <li v-for="(item, index) in listData" :key="index">
        <span class="title" v-text="item.title"></span>
        <span class="date" v-text="item.date"></span>
      </li>
    </ul>
  </vue-seamless-scroll>

  <el-dialog
      title="紧急预案内容"
      v-model="dialogVisible"
      width="90%" height="40%" style="margin-bottom: 80px;overflow-y: auto;"  class="data-dialog">

    <div id="tab_2">
      <div class="tab-tit-2">
        <!--点击设置curId的值  如果curId等于0，第一个a添加cur类名，如果curId等于1，第二个a添加cur类名，以此类推。添加了cur类名，a就会改变样式 @click,:class ,v-show这三个是vue常用的指令或添加事件的方式-->
        <a href="javascript:;" @click="curId=0" :class="{'cur':curId===0}">莘庄工业区两网融合</a>
        <a href="javascript:;" @click="curId=1" :class="{'cur':curId===1}">新虹街道两网融合</a>
        <a href="javascript:;" @click="curId=2" :class="{'cur':curId===2}">莘庄镇两网融合中转站</a>
        <a href="javascript:;" @click="curId=3" :class="{'cur':curId===3}">颛桥湿垃圾处置站</a>
      </div>
      <div class="tab-con-2">
        <div v-show="curId===0">
          <div style="width: 100%;height: 500px;">
            <ul style="display: flex;width: 100%;justify-content: space-evenly;padding:0;flex-wrap: wrap;
    flex-direction: row;">
              <li >

                  <xh_monitor_1></xh_monitor_1>

                </li>
              <li>

                  <xh_monitor_2></xh_monitor_2>

              </li>
              <li>

                  <xh_monitor_3></xh_monitor_3>

              </li>
              <li>
                <xh_monitor_4></xh_monitor_4>
              </li>
              <li style="padding-top: 1%;">
                <xh_monitor_5></xh_monitor_5>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="curId===1">
          <div style="width: 100%;height: 500px;">
            <ul style="display: flex;width: 100%;justify-content: space-evenly;padding:0;flex-wrap: wrap;
    flex-direction: row;">
              <li>
                <xh_street_monitor_1></xh_street_monitor_1>
              </li>
              <li>
                <xh_street_monitor_2></xh_street_monitor_2>
              </li>
              <li>
                <xh_street_monitor_3></xh_street_monitor_3>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="curId===2">
          <div style="width: 100%;height: 500px;">
            <ul style="display: flex;width: 100%;justify-content: space-evenly;padding:0;flex-wrap: wrap;
    flex-direction: row;">
              <li>
                <xzz_monitor_1></xzz_monitor_1>
              </li>
              <li>
                <xzz_monitor_2></xzz_monitor_2>
              </li>
              <li>
                <xzz_monitor_3></xzz_monitor_3>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="curId===3">
          <div style="width: 100%;height: 500px;">
            <ul style="display: flex;width: 100%;justify-content: space-evenly;padding:0;flex-wrap: wrap;
    flex-direction: row;">
              <li>
                <zq_monitor_1></zq_monitor_1>
              </li>
              <li>
                <zq_monitor_2></zq_monitor_2>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </el-dialog>


<!--<img_carousel></img_carousel>-->
<gps_worker></gps_worker>
<!--  <map-info-panel ref="child_panel_info"></map-info-panel>-->
<!--      <map-vehicle-info-panel></map-vehicle-info-panel>-->
  <garbage-box-info-panel></garbage-box-info-panel>
<tab-card></tab-card>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll/src'
//import img_carousel from "@/components/Carousel/ImgCarousel"
import xh_monitor_1 from "@/components/Monitor/XHMonitor/xh_monitor_1";
import xh_monitor_2 from "@/components/Monitor/XHMonitor/xh_monitor_2";
import xh_monitor_3 from "@/components/Monitor/XHMonitor/xh_monitor_3";
import xh_monitor_4 from "@/components/Monitor/XHMonitor/xh_monitor_4";
import xh_monitor_5 from "@/components/Monitor/XHMonitor/xh_monitor_5";
import xh_street_monitor_1 from "@/components/Monitor/XHStreetMonitor/xh_street_monitor_1";
import xh_street_monitor_2 from "@/components/Monitor/XHStreetMonitor/xh_street_monitor_2";
import xh_street_monitor_3 from "@/components/Monitor/XHStreetMonitor/xh_street_monitor_3";
import xzz_monitor_1 from "@/components/Monitor/XZZMonitor/xzz_monitor_1";
import xzz_monitor_2 from "@/components/Monitor/XZZMonitor/xzz_monitor_2";
import xzz_monitor_3 from "@/components/Monitor/XZZMonitor/xzz_monitor_3";
import zq_monitor_1 from "@/components/Monitor/ZQMonitor/zq_monitor_1";
import zq_monitor_2 from "@/components/Monitor/ZQMonitor/zq_monitor_2";
import gps_worker from "@/components/Center1/Map/BuildGarbageBox"
//import MapVehicleInfoPanel from "@/components/Center1/Map/Panel/MapVehicleInfoPanel"
import GarbageBoxInfoPanel from "@/components/Center1/Map/Panel/GarbageBoxInfoPanel"
//import garbage_box_alarm_pie from "@/components/New/Echarts/garbage_box_alarm_pie";
import TabCard from "@/components/Tool/TabCard";
export default {
  name: 'Example01Basic',
  components: {
    //garbage_box_alarm_pie,
    //MapVehicleInfoPanel,
    TabCard,
    GarbageBoxInfoPanel,
    zq_monitor_2,
    zq_monitor_1,
    xzz_monitor_3,
    xzz_monitor_2,
    xh_monitor_2,
    vueSeamlessScroll,
    xh_monitor_1,
    xh_monitor_3,
    xh_monitor_4,
    xh_monitor_5,
    xh_street_monitor_1,
    xh_street_monitor_2,
    xh_street_monitor_3,
    xzz_monitor_1,
    gps_worker,
   // img_carousel

  },
  mounted() {
   // this.$refs.child_panel_info.openInfoPanel()
  },
  computed: {
    defaultOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 200, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data () {
    return {
      activeName2: 'first',
      isFirst: true,
      isSecond: false,
      isThird:false,
      curId: 0,
      width_data:"30",
      dialogVisible:false,
      background: 'linear-gradient(to right,#798d89, #34e8e5)',
      listData: [{
        'title': '无缝滚动第一行无缝滚动第一行',
        'date': '2017-12-16'
      }, {
        'title': '无缝滚动第二行无缝滚动第二行',
        'date': '2017-12-16'
      }],
      activeName:''
    }
  },
  methods:{
    handleClick(tab,event){
      console.log(tab, event);
    }
  }
}
</script>
<!--<style>-->
<!--.el-dialog {-->
<!--  /* background: rgba(0, 0, 0, .2) !important; */-->
<!--  background: rgba(0, 0, 0, 0.2) !important;-->
<!--  border: rgb(8, 255, 243) 1px solid;-->
<!--  backdrop-filter: blur(3px);-->
<!--}-->
<!--.el-dialog__title {-->
<!--  color: rgb(255, 255, 255);-->
<!--  font-weight: 900;-->
<!--}-->
<!--.el-dialog__header{-->
<!--  color: #fff;-->
<!--  background: rgba(0, 0, 0, 0.5);-->
<!--  text-align: center;-->
<!--  box-shadow: 0 0 1.5vw rgb(97, 255, 255) inset;-->
<!--  background: linear-gradient(#74ffef, #74ffef) left top,-->
<!--  linear-gradient(#3deeda, #3deeda) left top,-->
<!--  linear-gradient(#3deeda, #3deeda) right top,-->
<!--  linear-gradient(#3deeda, #3deeda) right top;-->
<!--  /* linear-gradient(#3deeda, #3deeda) left bottom,-->
<!--  linear-gradient(#3deeda, #3deeda) left bottom,-->
<!--  linear-gradient(#3deeda, #3deeda) right bottom,-->
<!--  linear-gradient(#3deeda, #3deeda) right bottom; */-->
<!--  background-repeat: no-repeat;-->
<!--  background-size: 2px 20px, 20px 2px;-->
<!--}-->
<!--.el-dialog__body{-->
<!--  /*padding: 20px;*/-->
<!--  color: #fff;-->
<!--  background: rgba(0, 0, 0, 0.5);-->
<!--  text-align: left;-->
<!--  box-shadow: 0 0 1.5vw rgb(57, 255, 255) inset;-->
<!--  background:-->
<!--      linear-gradient(#3deeda, #3deeda) left bottom,-->
<!--      linear-gradient(#3deeda, #3deeda) left bottom,-->
<!--      linear-gradient(#3deeda, #3deeda) right bottom,-->
<!--      linear-gradient(#3deeda, #3deeda) right bottom;-->
<!--  background-repeat: no-repeat;-->
<!--  background-size: 2px 20px, 20px 2px;-->
<!--}-->
<!--.el-form-item__content{-->
<!--  background-color: rgba(0, 0, 0, 0.1) ;-->
<!--  box-shadow: 0 0 0.5vw rgb(57, 255, 255);-->
<!--  background-repeat: no-repeat;-->
<!--  border-radius: 10px;-->
<!--}-->
<!--.planTitle {-->
<!--  padding: 0 0 0 20px;-->
<!--  color: #8ae3e9;-->
<!--  font-size: 18px;-->
<!--  font-weight: 600;-->
<!--}-->
<!--.planContent {-->
<!--  color: #e6feff;-->
<!--  font-size: 16px;-->
<!--}-->
<!--.el-button&#45;&#45;primary {-->
<!--  color: #FFF;-->
<!--  background-color: #415b5ec2;-->
<!--  border-color: #75fbe9;-->
<!--  position: absolute;-->
<!--  /*margin-left: 374px;*/-->
<!--}-->

<!--</style>-->

<style>

#tab_2{
  width: 100%;
  margin: 0 auto;
}
.tab-tit-2{
  font-size: 0;
  width: 50%;
}
.tab-tit-2 a{
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  width: 25%;
  text-align: center;
  background: #ecf5ff24;
  color: #333;
  color: wheat;
  text-decoration: none;
}
.tab-tit-2 .cur{
  background: #09f;
  color: #fff;
}
.el-link{
  color: #f9f9f9;
}
/*.el-dialog{*/
/*  margin-top: 20px !important;*/
/*  width: 90% !important;*/
/*  height: 90% !important;*/
/*  box-sizing: border-box;*/
/*  background-color: rgb(3 39 60 / 50%) !important;*/
/*  border-top: 1px solid #0e6b9f;*/
/*  border-bottom: 1px solid #0e6b9f;*/
/*  border-radius: 0 !important;*/
/*}*/

.el-dialog__title {
  font-family: Ruizi4;
  line-height: 24px;
  font-size: 18px;
  color: #fff !important;
}
</style>
<style lang="scss" scoped>
ul{
  list-style-type: none;
}
.warp {
  height: 270px;
  width: 360px;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    li,
    a {
      display: block;
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
    }
  }
}
</style>