<template>
  <div class="Left-3">
    <div class="d-flex mt-1 jc-center body-box">
      <div :id="dom_id" style="width: 100%; height: 385px"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import {
  eventsForEcharts
}from '@/api/maintenance'
export default {
name: "stacked_horizontal_bar",
  props:['parent_type'],
  mounted() {

    this.echartsData()
    // setTimeout(()=>{
    //   this.drawChart(this.dom_id)
    // },200)


  },

  data(){
    return {
      children_type:0,
      dom_id:'stacked_',
      title:[],
      handle:[],
      close_case:[]
    }
  },
  watch:{
    parent_type (newName, oldName) {
      this.children_type=newName
      this.echartsData()
      // setTimeout(()=>{
      //   this.drawChart()
      // },300)

      console.log('旧的的变法',oldName)
      console.log('新的变法',newName)
    },
    immediate: true
  },
  methods:{
    echartsData(){
      var that=this
      that.title=[];
      console.log('我的标题栏',that.children_type)
      eventsForEcharts({event_type:that.children_type}).then(response=>{
        that.title=response.data
        that.handle=response.result.handle
        that.close_case=response.result.close_case
        this.drawChart(that.dom_id)
      })
    },
    drawChart(id){
      console.log('我的dom',id)
      let that=this
      let myDom = document.getElementById(that.dom_id);
      myDom.removeAttribute('_echarts_instance_');
      const myChart = echarts.init(document.getElementById(that.dom_id));
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          textStyle:{
            fontSize: 12,//字体大小
            color: '#ffffff'//字体颜色
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {// x轴文字的配置
            show: true,
            textStyle: {
              color: '#fff'
            }
          },
        },
        yAxis: {
          type: 'category',
          data: that.title,
          axisLabel: {// x轴文字的配置
            show: true,
            textStyle: {
              color: '#fff'
            }
          },
        },
        series: [
          {
            name: '处置中总数',
            type: 'bar',
            stack: 'total',
            label: {
              show: true,


            },

            emphasis: {
              focus: 'series'
            },
            data: [66, 78, 50, 1558, 121,3544,88],
            itemStyle: {

              normal: {

                color: new echarts.graphic.LinearGradient(

                    0, 0, 0, 1,

                    [{

                      offset: 0,

                      color: '#1f67e7'

                    },

                      {

                        offset: 0.7,

                        color: '#81e6ef'

                      }

                    ]

                )

              }

            },
          },
          {
            name: '已结案总数',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {

              normal: {

                color: new echarts.graphic.LinearGradient(

                    0, 0, 0, 1,

                    [{

                      offset: 0,

                      color: '#f30621'

                    },

                      {

                        offset: 0.7,

                        color: '#d05d3a'

                      }

                    ]

                )

              }

            },

            data: [0, 0, 2, 2,0, 3, 2]
          },

        ]
      };
      option.yAxis.data=that.title
      option.series[0].data=that.handle
      option.series[1].data=that.close_case
      // if(that.children_type===1){
      //   //option.yAxis.data=['绿化枯萎','绿化断枝','未及时修剪','绿化空秃','绿化带垃圾']
      //   option.series[0].data=[32, 328, 12, 16, 2542]
      //   option.series[1].data=[0,16,0,4,0]
      // }else if(that.children_type===2){
      //  // option.yAxis.data=['路面损坏','路基损坏','路面凹陷','井盖缺失']
      //   option.series[0].data=[1, 2, 3, 8]
      //   option.series[1].data=[0,0,0,0,0]
      // }
      // else if(that.children_type===3){
      //   //option.yAxis.data=['围栏损坏','河面垃圾','水深植物','青砍绿化','绿化空秃']
      //   option.series[0].data=[12, 653, 54, 3,6]
      //   option.series[1].data=[0,135,3,0,2]
      // }
      // else if(that.children_type===4){
      //   //option.yAxis.data=['污水排放','井盖破损','管道堵塞','管道异味']
      //   option.series[0].data=[2, 13, 1, 13]
      //   option.series[1].data=[0,0,1,0]
      // }
      // else if(that.children_type===5){
      //   //option.yAxis.data=['白色垃圾','小包垃圾','不文明养宠','沟底积灰','废物箱不洁']
      //   option.series[0].data=[2568, 4023, 125, 67,562]
      //   option.series[1].data=[13,23,1,18,92]
      // }
      console.log('图表测试',option.series)
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" scoped>
.Left-3{

  .body-box {
    border-radius: 10px;
    overflow: hidden;

  }
}
#container {

  width:100%;
  height:100%;
}

</style>