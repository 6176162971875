<template>
<div id="study-three">fuckYou</div>
</template>

<script>

import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

// 引入gui.js库
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js'

export default {
name: "threeStudy",
  mounted() {
    this.create3D()

  },
  methods:{

    create3D(){
      //const ThreeBSP = require('three-js-csg')(THREE)

      const gui=new GUI()
      let THREE=this.$THREE
      const scene=new THREE.Scene()
      const geometry=new THREE.BoxGeometry(100,100,100)
      //创建一个材质对象Material
      const material = new THREE.MeshPhongMaterial({
        color: 0xffff00,//0xff0000设置材质颜色为红色
        //transparent:true,//开启透明
       // opacity:0.5,//设置透明度
       // side: THREE.DoubleSide,
        shininess:20,
        specular: 0x444444
      });
      const mesh = new THREE.Mesh(geometry, material)
      mesh.position.set(50,100,0);
      const obj={
        color:0xffff00,
        bool:false
      }
      gui.addColor(obj,'color').onChange(value=>{
          mesh.material.color.set(value)
      })
      const position=gui.addFolder('坐标位置')
      position.add(mesh.position,'x',0,200).name('材质X轴坐标')
      position.add(mesh.position,'y',0,200).name('材质Y轴坐标')
      position.add(mesh.position,'z',-100,100).name('材质z轴坐标')
      position.add(obj,'bool').name('控制旋转')
      scene.add(mesh)

      //创建一个透视相机，窗口宽度，窗口高度
      const width =800, height = 800;
      const cameraConfig = {
        fov: 30,
        viewX: 800,
        viewY: 800,
        viewZ: 800
      };
      const camera = new THREE.PerspectiveCamera(cameraConfig.fov, width/height, 1, 3000);
      camera.position.set(cameraConfig.viewX,cameraConfig.viewY,cameraConfig.viewZ);
      //设置相机方向
      camera.lookAt(100,0,0);
      const cameraFolder = gui.addFolder("相机属性设置");
      cameraFolder.add(cameraConfig, "viewX", -1000, 1000).name("修改视角-x").onChange((num) => {
        cameraConfig.viewX = num;
        camera.position.set(cameraConfig.viewX, cameraConfig.viewZ, cameraConfig.viewY);
      });
      cameraFolder.add(cameraConfig, "viewY", -1000, 1000).name("修改视角-y").onChange((num) => {
        cameraConfig.viewY = num;
        camera.position.set(cameraConfig.viewX, cameraConfig.viewZ, cameraConfig.viewY);
      });
      cameraFolder.add(cameraConfig, "viewZ", -1000, 1000).name("修改视角-z").onChange((num) => {
        cameraConfig.viewZ = num;
        camera.position.set(cameraConfig.viewX, cameraConfig.viewZ, cameraConfig.viewY);
      });
      //创建辅助坐标轴
      const axesHelper = new THREE.AxesHelper(200);//参数200标示坐标系大小，可以根据场景大小去设置
      scene.add(axesHelper);



      //点光源：两个参数分别表示光源颜色和光照强度
      // 参数1：0xffffff是纯白光,表示光源颜色
      // 参数2：1.0,表示光照强度，可以根据需要调整
      // const pointLight = new THREE.PointLight(0xffffff, 1.0);
      //
      // pointLight.position.set(300, 300, 100);
      //
      // scene.add(pointLight)
      //光源辅助
      // const sphereSize = 10;
      // const pointLightHelper = new THREE.PointLightHelper( pointLight, sphereSize );
      // scene.add( pointLightHelper );
      //环境光
      // const ambient = new THREE.AmbientLight(0xffffff, 0.4);
      //
      // scene.add(ambient);

      // 平行光
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
// 设置光源的方向：通过光源position属性和目标指向对象的position属性计算
     directionalLight.position.set(80, 100, 50);
// 方向光指向对象网格模型mesh，可以不设置，默认的位置是0,0,0
      //directionalLight.target = mesh;
      // directionalLight.position.set(100, 0, 0);
      // directionalLight.position.set(0, 100, 0);
      // directionalLight.position.set(100, 100, 100);
     // directionalLight.position.set(100, 60, 50);
      scene.add(directionalLight);
      const dirLightHelper = new THREE.DirectionalLightHelper(directionalLight, 5,0xff0000);

      scene.add(dirLightHelper);

      const lightSource=gui.addFolder('光源设置')
      lightSource.add(directionalLight, 'intensity', 0, 10.0).name('平行光强度');
      //创建一个WebGL渲染器
      const renderer = new THREE.WebGLRenderer()
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setClearColor(0x444444, 1)
      renderer.setSize(width,height)//设置渲染区尺寸
      renderer.render(scene,camera)//执行渲染操作、指定场景、相机作为参数
      const controls=new OrbitControls(camera, renderer.domElement)
      //controls.enableDamping=true
      controls.addEventListener('change',()=>{
        renderer.render(scene, camera)//监听鼠标，键盘事件
      })
      function render() {
        renderer.render(scene, camera); //执行渲染操作
        mesh.rotateY(0.01);//每次绕y轴旋转0.01弧度
        //mesh.rotateY(0.02)
        requestAnimationFrame(render);//请求再次执行渲染函数render，渲染下一帧
      }
      render()
      document.getElementById('study-three')?.appendChild(renderer.domElement)
      gui.domElement.style.position = 'absolute';
      gui.domElement.style.top = '0';
      gui.domElement.style.left = '500';


    }
  }
}
</script>

<style scoped>

</style>