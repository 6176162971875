<template>
  <el-dialog
      title="监控管理"
      v-model="dialogVisible"
      custom-class="gps-map-dialog">

    <div id="tab_2">
      <div class="tab-tit-2">
        <!--点击设置curId的值  如果curId等于0，第一个a添加cur类名，如果curId等于1，第二个a添加cur类名，以此类推。添加了cur类名，a就会改变样式 @click,:class ,v-show这三个是vue常用的指令或添加事件的方式-->
        <a href="javascript:;" @click="curId=0" :class="{'cur':curId===0}">莘庄工业区两网融合</a>
        <a href="javascript:;" @click="curId=1" :class="{'cur':curId===1}">新虹街道两网融合</a>
        <a href="javascript:;" @click="curId=2" :class="{'cur':curId===2}">莘庄镇两网融合中转站</a>
        <a href="javascript:;" @click="curId=3" :class="{'cur':curId===3}">颛桥湿垃圾处置站</a>
      </div>
      <div class="tab-con-2">
        <div v-show="curId===0">
          <div style="width: 100%;height: 500px;">
            <ul style="display: flex;width: 100%;justify-content: space-evenly;padding:0;flex-wrap: wrap;
    flex-direction: row;">
              <li >

                <xh_monitor_1></xh_monitor_1>

              </li>
              <li>

                <xh_monitor_2></xh_monitor_2>

              </li>
              <li>

                <xh_monitor_3></xh_monitor_3>

              </li>
              <li>
                <xh_monitor_4></xh_monitor_4>
              </li>
              <li style="padding-top: 1%;">
                <xh_monitor_5></xh_monitor_5>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="curId===1">
          <div style="width: 100%;height: 500px;">
            <ul style="display: flex;width: 100%;justify-content: space-evenly;padding:0;flex-wrap: wrap;
    flex-direction: row;">
              <li>
                <xh_street_monitor_1></xh_street_monitor_1>
              </li>
              <li>
                <xh_street_monitor_2></xh_street_monitor_2>
              </li>
              <li>
                <xh_street_monitor_3></xh_street_monitor_3>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="curId===2">
          <div style="width: 100%;height: 500px;">
            <ul style="display: flex;width: 100%;justify-content: space-evenly;padding:0;flex-wrap: wrap;
    flex-direction: row;">
              <li>
                <xzz_monitor_1></xzz_monitor_1>
              </li>
              <li>
                <xzz_monitor_2></xzz_monitor_2>
              </li>
              <li>
                <xzz_monitor_3></xzz_monitor_3>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="curId===3">
          <div style="width: 100%;height: 500px;">
            <ul style="display: flex;width: 100%;justify-content: space-evenly;padding:0;flex-wrap: wrap;
    flex-direction: row;">
              <li>
                <zq_monitor_1></zq_monitor_1>
              </li>
              <li>
                <zq_monitor_2></zq_monitor_2>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import zq_monitor_2 from "@/components/Monitor/ZQMonitor/zq_monitor_2";
import zq_monitor_1 from "@/components/Monitor/ZQMonitor/zq_monitor_1";
import xzz_monitor_3 from "@/components/Monitor/XZZMonitor/xzz_monitor_3";
import xzz_monitor_2 from "@/components/Monitor/XZZMonitor/xzz_monitor_2";
import xh_monitor_2 from "@/components/Monitor/XHMonitor/xh_monitor_2";
import xh_monitor_1 from "@/components/Monitor/XHMonitor/xh_monitor_1";
import xh_monitor_3 from "@/components/Monitor/XHMonitor/xh_monitor_3";
import xh_monitor_4 from "@/components/Monitor/XHMonitor/xh_monitor_4";
import xh_monitor_5 from "@/components/Monitor/XHMonitor/xh_monitor_5";
import xh_street_monitor_1 from "@/components/Monitor/XHStreetMonitor/xh_street_monitor_1";
import xh_street_monitor_2 from "@/components/Monitor/XHStreetMonitor/xh_street_monitor_2";
import xh_street_monitor_3 from "@/components/Monitor/XHStreetMonitor/xh_street_monitor_3";
import xzz_monitor_1 from "@/components/Monitor/XZZMonitor/xzz_monitor_1";

export default {
name: "MonitorPanel",
  components:{
    zq_monitor_2,
    zq_monitor_1,
    xzz_monitor_3,
    xzz_monitor_2,
    xh_monitor_2,
    xh_monitor_1,
    xh_monitor_3,
    xh_monitor_4,
    xh_monitor_5,
    xh_street_monitor_1,
    xh_street_monitor_2,
    xh_street_monitor_3,
    xzz_monitor_1
  },
  mounted() {

  },
  data(){
    return {
      dialogVisible:false,
      curId: 0,
    }
  },
  methods:{
    openMonitor(){
      this.dialogVisible=true
        console.log('打开监控面板')
    }
  }
}
</script>

<style>
#tab_2{
  width: 100%;
  margin: 0 auto;
}
.tab-tit-2{
  font-size: 0;
  width: 50%;
}
.tab-tit-2 a{
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  width: 25%;
  text-align: center;
  background: #ecf5ff24;
  color: #333;
  color: wheat;
  text-decoration: none;
}
.tab-tit-2 .cur{
  background: #09f;
  color: #fff;
}
.el-link{
  color: #f9f9f9;
}
/*.el-dialog{*/
/*  margin-top: 20px !important;*/
/*  width: 90% !important;*/
/*  height: 90% !important;*/
/*  box-sizing: border-box;*/
/*  background-color: rgb(3 39 60 / 50%) !important;*/
/*  border-top: 1px solid #0e6b9f;*/
/*  border-bottom: 1px solid #0e6b9f;*/
/*  border-radius: 0 !important;*/
/*}*/

.el-dialog__title {
  font-family: Ruizi4;
  line-height: 24px;
  font-size: 18px;
  color: #fff !important;
}

</style>
<style lang="scss" scoped>
ul{
  list-style-type: none;
}
.warp {
  height: 270px;
  width: 360px;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    li,
    a {
      display: block;
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
    }
  }
}
</style>