<template>
  <el-dialog :width="500" :title="title" v-model="dialogVisible" custom-class="dialog_width_clean_driver" :modal='modal' :before-close="outClosePanel">
  <div style="width: 100%">
  <stacked_line_clean_driver :driver_id="item"></stacked_line_clean_driver>
  </div>
  </el-dialog>
</template>

<script>
import stacked_line_clean_driver from "@/components/New/Echarts/stacked_line_clean_driver";
export default {
name: "CleanDriverTrendPanel",
  components:{
    stacked_line_clean_driver
  },

  data(){
    return {
      listing:[],
      dialogVisible:false,
      item:[],
      modal:false,
      listQuery:{},
      title:"",
      is_wet_garbage:1

    }
  },
  methods:{
    async outClosePanel(){
      this.dialogVisible=false
    },
    async openInfoPanel(item,is_wet_garbage){

      this.dialogVisible=true
      this.item=item
      this.title=item.driver
      this.is_wet_garbage=is_wet_garbage
      console.log('是否是江川',item)

    }
  }
}
</script>

<style lang="less">
.dialog_width_clean_driver{
  // margin-top: 20px !important;

  height: 30%;
  box-sizing: border-box;
  background-color: var(--el-text-color-primary) !important;
  border-top: 1px solid #0e6b9f;
  border-bottom: 1px solid #0e6b9f;
  border-radius: 0 !important;
  overflow-y: auto;
  position: absolute;
  left: 12%;
  top: 50%;


  .el-dialog__header{
    padding: unset;
    margin-right: unset;
    word-break: break-all;
    background-color: #0000ff6b;
    width: 100%;
    height: 18%;
    line-height: 42px;
    text-align: left;
  }
  .el-dialog__title{
    font-size: small;
    padding-left: 10px
  }
  .el-dialog__body{
    padding: 0;

  }
}
</style>