import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'
import { borderBox1 } from '@jiaminghi/data-view'
import VueParticles from 'vue-particles'
import echarts from 'echarts'
import VueAMap ,{initAMapApiLoader} from '@vuemap/vue-amap'
import 'animate.css/animate.min.css'
import '@vuemap/vue-amap/dist/style.css'
import ElementPlus from 'element-plus'
import Ezuikit from 'ezuikit-js'
import 'element-plus/dist/index.css'
import global from './js/global'
import * as THREE from 'three'
//import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'

import AMapLoader from '@amap/amap-jsapi-loader';
const amapPromise = AMapLoader.load({
    key: '097be0ba99ff49e85fa4e700a1010329',
    version: '2.0',
    plugins: [
        'AMap.Geolocation',
        'AMap.MarkerClusterer',
        'AMap.ToolBar',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.ControlBar',
        'AMap.moveAnimation'
    ],
    AMapUI: {
        version: '1.1',
        plugins: ['overlay/SimpleInfoWindow', 'misc/PositionPicker'],
    },
});


//import scroll from "vue3-seamless-scroll";
initAMapApiLoader({
    key: "097be0ba99ff49e85fa4e700a1010329",
    plugin: [
        // 输入提示插件
        "AMap.Autocomplete",
        "AMap.Geocoder",
        // 定位控件，用来获取和展示用户主机所在的经纬度位置
        "AMap.Geolocation",
        // 编辑 折线多，边形
        "AMap.PolyEditor",
        // 右下角缩略图插件 比例尺
        "AMap.Scale",
        // 地图鹰眼插件
        "AMap.OverView",
        // 地图工具条
        "AMap.ToolBar",
        // 圆形编辑器插件
        "AMap.CircleEditor",
        // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
        "AMap.MapType",
        'AMap.Object3DLayer',
        'AMap.Object3D'
    ],

    v: "1.4.15",
    uiVersion: "1.1",
    viewMode:"3D",//开启3D视图,默认为关闭
    terrain: true,
    buildingAnimation:true,//楼块出现是否带动画


})


const app=createApp(App)

//app.use(OrbitControls)
app.use(VueAMap)
app.use(Ezuikit)

app.use(store)
app.use(router)
app.use(dataV)
app.use(borderBox1)
app.use(VueParticles)
app.use(echarts)
app.use(ElementPlus)
//app.use(scroll);

app.config.globalProperties.$THREE=THREE
app.config.globalProperties.$global=global
app.config.globalProperties.$monitor_token='at.9njbytwh061km26637rlyfw29jt80flm-4qr225j39x-0vnh9sw-knpljwcjx'
app.config.globalProperties.$amapPromise = amapPromise;
app.mount('#app')
