<template>
  <div class="progresswrapper2">
    <div class="pro_1" v-bind:style="{'width':width_data,'background':background}">
      <div class="pro_2">

<!--          <img :src="require('/public/images/new_platform/4.png') ">-->


      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data_width','data_background'],
name: "progress_wrapper_2",
  data () {
    return {
      width_data:this.data_width,
      background:this.data_background
    }
  }

}
</script>

<style scoped lang="scss">
.progresswrapper2{
  width : 90%;
  height : 5px;
  //background-image : linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
  background-color: #2f373d;
  background-size : 40px 40px;
  position : relative;

  .pro_1{
    width : 30%;
    height : 100%;
    //background-image : linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
    //background:linear-gradient(to right,#798d89, #34e8e5);
    //background-size : 40px 40px;
    //animation : progressbar 2s linear infinite;
    text-align: right;

  }
  .pro_2{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //position: absolute;
    //left: 10%;
  }

}
@keyframes progressbar{
  0% {
    background-position : 40px 0
  }
  100% {
    background-position : 0 0
  }
}
</style>