<template>
  <div class="Left-3">

    <div class="d-flex mt-1 jc-center body-box" style="
    position: relative;
">
      <div class="tab-tit" style="text-align: left;position: absolute;
    width: 100%;">
        <a href="javascript:;" @click="curId=0" :class="{'cur':curId===0}">可回收物碳中和指数</a>
      </div>
      <div :id="dom_id" style="width: 100%; height: 200px;">

      </div>
    </div>
  </div>
</template>

<script>
//import * as echarts from "echarts"
import * as echarts from "echarts";
import {
  eventsForColumn
}from '@/api/maintenance'
export default {
name: "stacked_column_chart2",
  props:['parent_type'],
  mounted() {
    this.echartsData()
  },
  data(){
    return {
      listing: [],
      children_type:0,
      dom_id:'stacked_column_chart2',
      title:[],
    }
  },
  watch:{
    parent_type (newName, oldName) {
      this.children_type=newName
      this.echartsData()
      // setTimeout(()=>{
      //   this.drawChart()
      // },300)
      console.log('我的下标',this.children_type)
      console.log('旧的的变法',oldName)
      console.log('新的变法',newName)
    },
    immediate: true
  },
  methods: {
    echartsData(){
      var that=this
      eventsForColumn({event_type:that.children_type}).then(response=>{
        console.log('我的标题栏',response.data)
        that.listing=response.data
        // if(that.children_type == 0){
        //   that.title=response.title.year
        // }else if(that.children_type == 1){
        //   that.title=response.title.month
        // }else {
        //   that.title=response.title.week
        // }
        that.title=that.listing.year
        this.drawChart(that.dom_id)
      })

    },
    drawChart(id){
      console.log('我的dom',id)
      let that=this
      let myDom = document.getElementById(that.dom_id);
      myDom.removeAttribute('_echarts_instance_');
      let myChart = echarts.init(document.getElementById(that.dom_id));

      let option={
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: { // 悬浮框文字样式
            fontSize: 10
          }

        },
        legend: {
          padding:[0,0,0,0],
         show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,

        },
        xAxis: [
          {
            type: 'category',
            data: that.title,
            axisLabel: {// x轴文字的配置
              show: true,
              textStyle: {
                color: '#e0cf6b',
                fontSize: 9,//字体大小
              }
            },
            axisLine: {// 轴线的颜色以及宽度
              lineStyle: {
                color: '#a7920e',

              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {// 轴线的颜色以及宽度
              lineStyle: {
                color: '#a7920e',

              }
            },
            axisLabel: {// x轴文字的配置
              show: true,
              textStyle: {
                color: '#a7920e',
                fontSize: 9,//字体大小
              }
            },
            splitLine: {// 分割线配置
              lineStyle: {
                show:false,
                color: '#5c5c57',
                type: 'dashed'
              }
            }
          }
        ],

        series: [

          {
            name: '纸类回收碳中和指数',
            type: 'bar',
            barWidth: 10,
            stack: 'Search Engine',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#2378f7'},
                    {offset: 0.7, color: '#2378f7'},
                    {offset: 1, color: '#83bff6'}
                  ]
              ),


            },
            data: that.listing.info.paper
          },
          {
            name: '塑料回收碳中和指数',
            type: 'bar',
            stack: 'Search Engine',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#f7a923'},
                    {offset: 0.7, color: '#f5cf63'},
                    {offset: 1, color: '#eed0a7'}
                  ]
              )
            },
            data: that.listing.plastic
          },
          {
            name: '废旧金属回收碳中和指数',
            type: 'bar',
            stack: 'Search Engine',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#16f8aa'},
                    {offset: 0.7, color: '#72d7b0'},
                    {offset: 1, color: '#c7ecc5'}
                  ]
              )
            },
            data: that.listing.info.metal
          },
          {
            name: '木材回收碳中和指数',
            type: 'bar',
            stack: 'Search Engine',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#f60727'},
                    {offset: 0.7, color: '#ea2c5c'},
                    {offset: 1, color: '#db8282'}
                  ]
              ),

            },
            data: that.listing.info.wood
          },
          {
            name: '玻璃回收碳中和指数',
            type: 'bar',
            stack: 'Search Engine',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#2ec7d5'},
                    {offset: 0.7, color: '#7acbc3'},
                    {offset: 1, color: '#bfd0cc'}
                  ]
              ),

            },
            data: that.listing.info.glass
          }
        ]
      };
      myChart.setOption(option);
      console.log("我的echarts",that.listing)
    }
  }
}
</script>

<style lang="less" scoped>
.Left-3{

  .body-box {
    border-radius: 10px;
    overflow: hidden;

  }
  #tab{
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 2%;
  }
  .tab-tit{
    font-size: 0;
    // width: 600px;
  }
  .tab-tit a{
    width: 35%;
    display: inline-block;
    /* height: 40px; */
    line-height: 51px;
    font-size: 11px;

    text-align: center;
    //background: #ecf5ff24;
    font-weight: 500;
    color: #f8f8f8;
    text-decoration: none;
    background-image: url('../../../../public/images/new_platform/button/1.png');
    // background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;

  }
  .tab-tit .cur{
    background-image: url('../../../../public/images/new_platform/button/2.png');
    // background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    color: #d9b752;
  }
  .tab-con{
    height: 100%;
    width: 100%;

  }
  .tab-con .curID{
    //background: #d43636;
    height: 100%;
    width: 100%;
    //background: #e0de13;
  }
}
#container {

  width:100%;
  height:300px;
}
</style>