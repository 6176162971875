<template>
  <div class="block" style="width: 100%;text-align: left">
    <span class="demonstration" style="color: #00aaff">当日事件处理现场照片:</span>
    <el-carousel height="250px"  :interval="5000">
      <el-carousel-item v-for="item in photos" :key="item">
<!--        <h3 class="small">{{ item.img }}</h3>-->
        <img :src="item" alt="" width="100%" height="100%">
<!--        <el-image :src="item.img" alt="" width="100%" :preview-src-list="[item.img]"/>-->
      </el-carousel-item>
    </el-carousel>
  </div>

</template>

<script>
export default {
name: "ImgCarousel",
  props:['event_photos'],
  data(){
    return {
      items:[
        {
          img:'https://storage-api.bfriendgroup.com/uploads/images/feedback_problem/1685577675.jpg'
        },
        {
          img:'https://storage-api.bfriendgroup.com/uploads/images/feedback_problem/1685577683.jpg'
        },
        {
          img:'https://storage-api.bfriendgroup.com/uploads/images/feedback_problem/1685577726.jpg'
        },
        {
          img:'https://storage-api.bfriendgroup.com/uploads/images/feedback_problem/1685580884.jpg'
        }
      ],
      photos:[]
    }
  },
  mounted() {
   // this.photos=this.event_photos
    console.log('我的照片',this.event_photos)
  },
  watch:{
    event_photos (newName, oldName) {
      this.photos=newName

      // setTimeout(()=>{
      //   this.drawChart()
      // },300)

      console.log('旧的的变法',oldName)
      console.log('新的变法',newName)
    },
    immediate: true
  },

}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>