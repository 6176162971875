<template>
  <el-dialog  :title="item.licenseNumber"  v-model="dialogVisible"
              custom-class="dialog_car_width" :before-close="outClosePanel">
    <div class="marker_info">
      <div class="left-info" >
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 25%"><span class="info_title">车辆牌照:</span></li>
          <li><span class="info_content">{{item.licenseNumber}}</span></li>
        </ul>
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 25%"><span class="info_title">车辆类型:</span></li>
          <li><span class="info_content">{{item.vechile_type}}</span></li>
        </ul>
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 25%"><span class="info_title">今日班次:</span></li>
          <li style="align-content: flex-end;
    flex-wrap: wrap;display: flex"><span class="info_content">6:00-11:00,15:00-17:00</span></li>
        </ul>
        <ul style="width: 100%" class="panel_ul">
          <li style="width: 24%"><span class="info_title">车辆状态:</span></li>
          <li><span class="info_content">{{item.status}}</span></li>
        </ul>

        <ul style="width: 100%" class="panel_ul">
          <li style="width: 24%"><span class="info_title">当前速度:</span></li>
          <li><span class="info_content">{{item.speed}}(km/h)</span></li>
        </ul>
        <ul style="width: 100%;line-height: 28px" class="panel_ul">
          <li style="width: 24%"><span class="info_title">车辆位置:</span></li>
          <li style="width: 70%"><span class="info_content">{{item.address}}</span></li>
        </ul>
      </div>
      <div class="center-info" style="width: 35%;display: flex;
    align-content: space-around;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;">
        <img :src="src" style="width:100%">
      </div>
      <div class="right-info">
        <ul style="width: 100%;" class="panel_ul">
          <li style="width:45%"><span class="info_title">当月已完成工单数:</span></li>
          <li><span class="info_content">{{listing.current_month_order_count}}</span></li>
        </ul>
        <ul style="width: 100%;" class="panel_ul">
          <li style="width:40%"><span class="info_title">当月清运车次数:</span></li>
          <li><span class="info_content">{{listing.current_month_clear_times}}次</span></li>
        </ul>
        <ul style="width: 100%;" class="panel_ul">
          <li style="width: 30%"><span class="info_title">当天工单数:</span></li>
          <li><span class="info_content">{{listing.current_day_order_count}}</span></li>
        </ul>
        <ul style="width: 100%;" class="panel_ul">
          <li style="width: 45%"><span class="info_title">当天已完成工单数:</span></li>
          <li><span class="info_content">{{listing.current_day_finish_order_count}}</span></li>
        </ul>
        <ul style="width: 100%;" class="panel_ul">
          <li style="width: 45%"><span class="info_title">当天清运车次数:</span></li>
          <li><span class="info_content">{{listing.current_day_clear_times}}次</span></li>
        </ul>
        <ul style="width: 100%;" class="panel_ul">
          <li style="width: 45%"><span class="info_title">当月车辆驾驶员:</span></li>
          <li><span class="info_content">{{listing.drivers}}</span></li>
        </ul>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getVehicleInfo
} from '@/api/maintenance'
export default {
name: "MapVehicleInfoPanel",
  data(){
    return {
      item:[],
      listing:[],
      dialogVisible:false,
      src:require("@/assets/info_img/dry_cleaner_car.png"),
      listQuery:{
        car_number:''
      },
    }
  },
  mounted() {

  },
  methods:{
      async outClosePanel(){
        this.dialogVisible=false
      },
      async openInfoPanel(item){
        this.dialogVisible=true
        this.item=item
        this.listQuery.car_number=item.licenseNumber
        this.vehicleInfoForMan()
        console.log('我的内容项', this.listQuery)
      },
      vehicleInfoForMan(){
        let that=this

        getVehicleInfo(that.listQuery).then(response=>{
          that.listing=response.data
          console.log('我的工作情况',that.listing)
        })
      }

  }
}
</script>

<style lang="less">
.dialog_car_width{
  margin-top: 80px !important;
  width: 60% !important;
  height: 63% !important;
  box-sizing: border-box;
  background-color: rgb(3 39 60 / 50%) !important;
  border-top: 1px solid #0e6b9f;
  border-bottom: 1px solid #0e6b9f;
  border-radius: 0 !important;
  overflow-y: auto;
  .el-dialog__header{
    padding: unset;
    margin-right: unset;
    word-break: break-all;
    background-color: #0000ff6b;
    width: 100%;
    height: 10%;
    line-height: 50px;
  }
  .marker_info{
    // background-color: #d43636;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .left-info{
    width: 35%;
    line-height: 60px;
    //background-color: #00235f;
  }
  .center-info{
    //width: 30%;
    //height: 100%;
    //background-color: #e0de13;
  }
  .right-info{
    width: 35%;
    line-height: 56px;
    //background-color: #00aaff;
  }
  .panel_ul{
    list-style-type: none;
    padding: 0;
    display: flex;
    width: 100%;
    /* justify-content: space-evenly; */
    font-size: large;
    color: aliceblue;
    font-weight: 600;
    font-family: cursive;

  }
  .info_title{
    color: #00aaff;
  }
}

.info_content{

}
</style>