<template>
  <div id="container" >ddddd</div>
</template>

<script>
export default {
name: "mapView",
  mounted: async function () {
    await this.initMap()
    await this.cellHandler()
    window.onbeforeunload = function () {
      this.ws.close();
    }
  },
  unmounted(){
    this.ws.close();
    this.map?.destroy();
  },
  data(){
    return{
      markers:[121.37845811631945, 31.107472059461806],
      map:'',
      marker:''
    }
  },
  methods:{
    async cellHandler(){
      let that=this
      that.ws=new WebSocket("ws://test.bfriendgroup.com:8502");
      that.ws.onopen = function () {
        console.log('websocket连接成功map');
      };
      that.ws.onclose = function () {
        // 关闭 websocket
        console.log("连接已关闭...");
        //断线重新连接

      };
      that.marker=null;
      that.ws.onmessage=function (e){
        that.message=JSON.parse(e.data)
        if(that.message.event_report){
          that.marker = new AMap.Marker({
            position: new AMap.LngLat(that.message.event_report.longitude,that.message.event_report.latitude),
            title: "上海市"
          });
          that.marker.setMap(that.map);
          that.map.setCenter([that.message.event_report.longitude,that.message.event_report.latitude])

        }
      }

    },
    async initMap(){
      const AMap = await this.$amapPromise;
      this.map = new AMap.Map("container", {
        center: [121.3778404405382, 31.107730305989584],
        zoom: 17,
        //mapStyle: "amap://styles/grey",
        resizeEnable: true,
        rotateEnable:true,
        pitchEnable:true,
        pitch:-50,
        rotation:-15,
        viewMode:'3D',//开启3D视图,默认为关闭
        buildingAnimation:true,//楼块出现是否带动画

       expandZoomRange:true,
        showIndoorMap: false,
        zooms:[3,20],
      });

      // AMap.plugin(['AMap.HawkEye','AMap.Scale','AMap.ToolBar','AMap.ControlBar','AMap.MapType'], () => {
      //   // 将缩略图控件添加到地图实例中
      //   //map.addControl(new AMap.HawkEye())
      //   this.map.addControl(new AMap.Scale())
      //   this.map.addControl(new AMap.ToolBar())
      //   this.map.addControl(new AMap.ControlBar())
      //   this.map.addControl(new AMap.MapType())
      //
      // })

    }
  }
}
</script>

<style scoped>
#container{
  padding:0px;
  margin: 0px;
  width: 30%;
  height: 500px;
}
</style>