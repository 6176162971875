<template>
  <div class="Left-4">
    <div class="d-flex mt-1 jc-center body-box-2">
      <div id="garbage_box_alarm_reason_pie" style="width: 100%; height: 250px"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
name: "garbage_box_alarm_pie",
  mounted() {

    this.$nextTick(() => {
      this.drawChart()
    })

  },
  data(){
    return {

    }
  },
  methods:{

    drawChart(){
      let myDom = document.getElementById('garbage_box_alarm_reason_pie');
      myDom.removeAttribute('_echarts_instance_');

      let myChart = echarts.init(myDom);
      let option={
        tooltip: {
          trigger: 'item'
        },
        legend: {
          x: '70%',
          y: '28%',
          orient: 'vertical',
          textStyle:{
            fontSize:11,
            color:'white'
          }
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#ef6567', '#f9c956', '#75bedc',
          '#23d9b4'],
        series: [
          {
            name: '占比(%)',
            type: 'pie',
            radius: '65%',
            center:['40%','60%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,

            },
            label: {
              show: false,
              position: 'left'
            },

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: [
              { value: 50, name: '串口通讯失败故障' },
              { value: 30, name: '称重失效' },
              { value: 15, name: '测距失效' },
              { value: 10, name: '升降门失效' },
              { value: 5, name: '电磁门失效' },

              { value: 12, name: '计数器无法计数' },
              { value: 2, name: '传送带无法启停' }

            ]
          }
        ]
      };
      myChart.setOption(option);
    }
  }

}
</script>

<style lang="less" scoped>
.Left-3{

  .body-box {
    border-radius: 10px;
    overflow: hidden;

  }
}
#garbage_box_alarm_reason_pie {

  width:100%;
  height:200px;

}

</style>