<template>
<div id="index">
  <dv-full-screen-container class="container">
    <div class="row-container">
      <div class="threed">
        <div class="main-header">

        </div>
      </div>
      <div style="text-align: left;
    margin-top: 2%;margin-left: 10%">
        <el-button

            class="filter-item"
            type="primary"
            icon="el-icon-download"
            style="text-align: center;background:rgb(0, 35, 95);"
            @click="jump()"
        >
          返回上级
        </el-button>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="header_info">
        <dv-border-box-10 style="width: 99%;height: 45%;">
          <div style="position: absolute;width: 200px;height: 40px;font-size: smaller;font-weight: bolder;top: 0%">
            两网融合管理中心
          </div>
          <div style="position: absolute;font-size: large;font-weight: bolder;top: 40%;left: 4%;">场站运营情况</div>
          <div class="data_title">回收总量<span class="article">{{summary_data.recycling_total_weight}}</span></div>
          <div class="data_title" style="position: absolute;left: 29%">库存总量<span class="article">{{summary_data.stock_total_weight}}</span></div>
          <div class="data_title" style="position: absolute;left: 42%">出货总量<span class="article">{{summary_data.out_product_total_weight}}</span></div>
          <div style="position: absolute;font-size: large;font-weight: bolder;top: 35%;left: 60%;">设备回收数据统计</div>
          <div class="data_title" style="position: absolute;left: 70%;width: 22%;">设备投递量
            <span class="article" v-for="(item,key) in user_delivery" :key="key">
              {{item.total_number}}({{item.unit}})
            </span>

          </div>

        </dv-border-box-10>
      </div>

    </transition>
    <transition>
      <div class="body_info">
        <dv-border-box10 class="column-1">
          <div class="part">
            <div style="text-align: left;margin-left: 10%;font-weight: bold" >处置品类</div>
            <dv-border-box-12 style="height: 90%;width: 100%;margin-top: 1%;">
              <div  class='systemInfo'
                    style="height: 100%;
    width: 93%;
    overflow-y: auto;
    position: absolute;

    left: 4%;
    ">
                <table style="font-size: x-small;border-color: unset;width: 100%;line-height: 30px;" class="my_table">
                  <thead >
                  <tr>
                    <td>品类</td>
                    <td>实际重量</td>

                    <td>状态</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,key) in listing" :key="key">
                    <td>{{item.category_id}}</td>
                    <td>{{item.weight}}</td>

                    <td v-if="item.status==1" style="color: #e0de13">已申请</td>
                    <td v-else-if="item.status==2" style="color: #32e013">审核通过</td>
                    <td v-else-if="item.status==3" style="color: #e03c13">退回申请</td>
                    <td v-else-if="item.status==4" style="color: #13c8e0">工单完成</td>
                    <td v-else-if="item.status==5" style="color: #08ea81">确认完成</td>
                  </tr>

                  </tbody>
                </table>
              </div>



            </dv-border-box-12>
          </div>
          <div class="part">
            <div style="text-align: left;margin-left: 10%;font-weight: bold" >回收明细</div>
            <dv-border-box-12 style="height: 90%;width: 100%;margin-top: 1%;">
              <recycling_pie :category_data_info="category_pie" v-if="is_init==true"></recycling_pie>
            </dv-border-box-12>
          </div>
        </dv-border-box10>
        <div class="column-2" v-if="disabled===true">
          <dv-border-box-11 title="设备分布图" style="height: 100%">
            <garbage-box :getSummaryData="getSummaryData" :get-user-delivery="getUserDelivery"></garbage-box>
          </dv-border-box-11>
<!--          <dv-border-box-12 style="height: 24%;width: 100%;margin-top: 1%;">-->
<!--            <div style="position: absolute;width: 200px;height: 40px;font-size: smaller;font-weight: bolder;top: 0%">-->
<!--              设备回收数据统计-->
<!--            </div>-->
<!--            <div class="classic">-->
<!--              <ul >-->
<!--                <li>智能回收箱</li>-->
<!--                <li>投递量</li>-->
<!--                <li style="font-size: small;-->
<!--    color: aqua;">7752.5公斤</li>-->
<!--              </ul>-->
<!--              <ul >-->
<!--                <li>智能回收箱</li>-->
<!--                <li>清运量</li>-->
<!--                <li style="font-size: small;-->
<!--    color: aqua;">7642.5公斤</li>-->
<!--              </ul>-->
<!--              <ul >-->
<!--                <li>预约回收</li>-->
<!--                <li>回收量</li>-->
<!--                <li style="font-size: small;-->
<!--    color: aqua;">11423公斤</li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </dv-border-box-12>-->
        </div>
        <div class="column-3">
          <div class="part">
            <div style="text-align: left;margin-left: 10%;font-weight: bold" >供货商活跃度</div>
            <dv-border-box-12 style="height: 90%;width: 100%;margin-top: 1%;">
              <div  class='systemInfo'
                    style="height: 100%;
    width: 93%;
    overflow-y: auto;
    position: absolute;

    left: 4%;
    ">
                <table style="font-size: x-small;border-color: unset;width: 100%;line-height: 30px;" class="my_table">
                  <thead >
                  <tr>
                    <td>客户名称</td>
                    <td>客户工单数</td>
                    <td>活跃度排名</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,key) in ranking" :key="key">
                    <td>{{item.real_name}}</td>
                    <td>{{item.number}}</td>

                    <td v-if="key==0" style="color: deepskyblue;font-size:xx-large">{{key+1}}</td>
                    <td v-else-if="key==1" style="color: yellow;font-size:x-large">{{key+1}}</td>
                    <td v-else-if="key==2" style="color: orangered;font-size:large">{{key+1}}</td>
                    <td v-else>{{key+1}}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </dv-border-box-12>
          </div>

          <div class="part">
            <div style="text-align: left;margin-left: 10%;font-weight: bold" >库存明细</div>
            <dv-border-box-12 style="height: 90%;width: 100%;margin-top: 1%;">
              <div  class='systemInfo'
                    style="height: 100%;
    width: 93%;
    overflow-y: auto;
    position: absolute;

    left: 4%;
    ">
                <table style="font-size: x-small;border-color: unset;width: 100%;line-height: 30px;" class="my_table">
                  <thead >
                  <tr>
                    <td>品类名称</td>
                    <td>总重量</td>

                    <td>入库时间</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,key) in stock" :key="key">
                    <td>{{item.category_type}}</td>
                    <td>{{item.total_weight}}</td>

                    <td style="color: yellow">{{item.create_time}}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </transition>
  </dv-full-screen-container>
</div>
</template>

<script>
import recycling_pie from "@/components/New/Echarts/recycling_pie";
import GarbageBox from "@/components/Center1/Map/GarbageBox";
import {
  summaryOutCategory,
  getClientsRanking
}from '@/api/maintenance'
export default {
  name: "TransferStationView",
  components:{
    recycling_pie,
    GarbageBox,

  },
  data(){
    return {
      disabled:true,
      summary_data:[],
      listing:[],
      ranking:[],
      stock:[],
      category_pie:[],
      user_delivery:[],
      is_init:false
    }
  },
  mounted() {
    this.getOutCategorySummary()
    this.clientsRanking()
  },
  methods:{
    getOutCategorySummary(){
      let that=this
      summaryOutCategory().then(response=>{
        that.listing=response.data
        console.log('我的类型汇总',that.listing)
      })
    },
    clientsRanking(){
      let that=this
      getClientsRanking().then(response=>{
          that.ranking=response.data.ranking
          that.stock=response.data.stock_detail
          that.category_pie=response.data.category_pie
          that.is_init=true
        console.log('饼图类型',that.category_pie)
      })
    },
    getSummaryData(item){
      let that=this
      that.summary_data=item
      console.log('统计数据',that.summary_data)
    },
    getUserDelivery(item){
      let that=this
      that.user_delivery=item
      console.log('投递量统计',that.user_delivery)

    },
    jump(){
      this.disabled=false
      this.$router.back();
    },
  }
}
</script>

<style lang="less">
#index{
  color: #f9f9f9;
  background-color: #000000;
  width: 100%;
  height: 100%;
  .container {
    padding: 0.4rem 0.4rem 0.3rem 0.7rem;

  }
  .classic ul{
    font-size: 13px;
    float: left;
    list-style:none;
    width: 23%;
    line-height: 230%;
    font-weight: bold;
    border-right: 2px dashed var(--el-color-white);
    text-align: center;
  }
  .classic{

    width: 95%;
    position: absolute;
    top: 18%;
    height: 78%;
    left: 2%;
  }
  /*滚动条样式*/
  .systemInfo::-webkit-scrollbar {/*滚动条整体样式*/
    width: 8px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
  }
  .systemInfo::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: white;
  }
  .systemInfo::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background:#00235f;
  }
  .part{
    width: 100%;
    height:50%;
    box-shadow:#79bbff 0px 0px 10px
  }
  .article{
    background: #eda50f linear-gradient(135deg, transparent 25%, transparent 40%, var(--el-color-warning) 40%, #e0de13 60%, transparent 60%, transparent) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: scratchy 1.5s linear infinite;
    animation: scratchy 1.5s linear infinite;
    margin-left: 4%;
    font-size: large;
    font-weight: bold;
  }
  .data_title{
    position: absolute;
    font-size: small;
    top: 40%;
    left: 15%;
    width: 200px;
  }
  .column-3{
    width: 25%;
    height: 100%;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 75%;
    //background: #42b983;
  }
  .column-2{
    width: 48%;
    height: 100%;

    position: absolute;
    left: 26%;
   // background: #d43636;

    //left: 31%;
  }
  .column-1{
    width: 25%;
    height: 100%;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    float: left;
  }
  .body_info{
    position: absolute;
    top:18%;
    height: 80%;
    width: 100%;
   // background: blueviolet;

  }
  .header_info{

    // background: blueviolet;
    /* top: 29%; */
    position: absolute;
    width: 100%;
    top: 8%;
    height: 20%;


  }
  .row-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:  url('../../../public/img/new_title_2.jpg');
    //background-image:  url('../../../public/img/new_title.png');
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    z-index: -100;

  }
}
</style>