<template>
  <div id="xzz_monitor_3"></div>
</template>

<script>
import EZUIKit from "ezuikit-js";
export default {
name: "xzz_monitor_3",
  data() {
    return {
      player:''
    }
  },
  mounted(){
    this.player = new EZUIKit.EZUIKitPlayer({
      id: 'xzz_monitor_3', // 视频容器ID
      accessToken: this.$monitor_token,
      url: 'ezopen://open.ys7.com/G37143112/1.hd.live',
      autoplay: false,
      template: "standard",
      width:380,
      height:270
    });
  }
}
</script>

<style scoped>

</style>