<template>
<div id="buffer_geomerty">ccccdddd</div>
</template>

<script>
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
export default {
name: "bufferGeometryView",
  mounted() {
   // this.createModel()
   this.createBufferModel()
   // this.createVector3()
  },
  methods:{
    createVector3(){
      let THREE=this.$THREE
      const scene=new THREE.Scene()
      //新版不再使用
      const geometry = new THREE.Geometry(); //声明一个几何体对象Geometry
      const p1 = new THREE.Vector3(50, 0, 0); //顶点1坐标
      const p2 = new THREE.Vector3(0, 70, 0); //顶点2坐标
      const p3 = new THREE.Vector3(80, 70, 0); //顶点3坐标
//顶点坐标添加到geometry对象
      geometry.vertices.push(p1, p2, p3);

      // Color对象表示顶点颜色数据
      const color1 = new THREE.Color(0x00ff00); //顶点1颜色——绿色
      const color2 = new THREE.Color(0xff0000); //顶点2颜色——红色
      const color3 = new THREE.Color(0x0000ff); //顶点3颜色——蓝色
//顶点颜色数据添加到geometry对象
      geometry.colors.push(color1, color2, color3);
      const material=new THREE.MeshLambertMaterial({
        vertexColors:true,
        side:THREE.DoubleSide
      });
      const mesh=new THREE.Mesh(geometry,material)
      scene.add(mesh)
      //注意使用顶点颜色数据定义模型颜色的时候，
      // 要把材质的属性vertexColors设置为THREE.VertexColors,这样顶点的颜色数据才能取代材质颜色属性.color起作用。
      /**
       * 光源设置
       */
      //点光源
      const point = new THREE.PointLight(0x444444);
      point.position.set(280, 45, 15); //点光源位置
      scene.add(point); //点光源添加到场景中
      //环境光    环境光颜色与网格模型的颜色进行RGB进行乘法运算
      const ambient = new THREE.AmbientLight(0x444444);
      scene.add(ambient);
      /**
       * 相机设置
       */
      const width = window.innerWidth; //窗口宽度
      const height = window.innerHeight; //窗口高度
      const k = width / height; //窗口宽高比
      const s = 300; //三维场景显示范围控制系数，系数越大，显示的范围越大
      const camera = new THREE.OrthographicCamera(-s * k, s * k, s, -s, 1, 1000);
      camera.position.set(350, 300, 200); //设置相机位置
      camera.lookAt(scene.position); //设置相机方向(指向的场景对象)
      //创建辅助坐标轴
      const axesHelper = new THREE.AxesHelper(1200);//参数200标示坐标系大小，可以根据场景大小去设置
      scene.add(axesHelper);

      /**
       * 创建渲染器对象
       */
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);//设置渲染区域尺寸
      renderer.setClearColor(0x444444, 1); //设置背景颜色
      document.body.appendChild(renderer.domElement); //body元素中插入canvas对象
      function render(){
        renderer.render(scene,camera)
        //mesh.rotateY(0.01)
        requestAnimationFrame(render)
      }
      render()
      new OrbitControls(camera,renderer.domElement)
    },
    createBufferModel(){
      let THREE=this.$THREE
      const scene=new THREE.Scene()
      // //自定义几何体
      // const geometry=new THREE.BufferGeometry()////创建一个Buffer类型几何体对象,缓冲类型几何体
      // //类型数组创建顶点数据
      // const vertices = new Float32Array([
      //   0, 0, 0, //顶点1坐标
      //   50, 0, 0, //顶点2坐标
      //   0, 100, 0, //顶点3坐标
      //
      //   0, 0, 10, //顶点4坐标
      //   0, 0, 100, //顶点5坐标
      //   50, 0, 10, //顶点6坐标
      // ]);
      // // 创建属性缓冲区对象
      // const attribue = new THREE.BufferAttribute(vertices, 3); //3个为一组，表示一个顶点的xyz坐标
      // // 设置几何体attributes属性的位置属性
      // geometry.attributes.position = attribue;
      // //顶点的法向量数据
      // const normals = new Float32Array([
      //   0, 0, 1, //顶点1法向量
      //   0, 0, 1, //顶点2法向量
      //   0, 0, 1, //顶点3法向量
      //
      //   0, 1, 0, //顶点4法向量
      //   0, 1, 0, //顶点5法向量
      //   0, 1, 0, //顶点6法向量
      // ]);
      // 设置几何体attributes属性的位置normal属性
     // geometry.attributes.normal = new THREE.BufferAttribute(normals, 3); //3个为一组,表示一个顶点的法向量数据
      // 三角面(网格)渲染模式
      //类型数组创建顶点颜色color数据
      // const colors = new Float32Array([
      //   1, 0, 0, //顶点1颜色
      //   0, 1, 0, //顶点2颜色
      //   0, 0, 1, //顶点3颜色
      //
      //   1, 1, 0, //顶点4颜色
      //   0, 1, 1, //顶点5颜色
      //   1, 0, 1, //顶点6颜色
      // ]);
      // geometry.attributes.color = new THREE.BufferAttribute(colors, 3)//3个为一组,表示一个顶点的颜色数据RGB
      // const material = new THREE.MeshBasicMaterial({
      //   color: 0x0000ff, //三角面颜色
      //  // vertexColors:  true, //以顶点颜色为准
      //   side: THREE.DoubleSide //两面可见
      // }); //材质对象
      // const mesh = new THREE.Mesh(geometry, material); //网格模型对象Mesh
      // 点渲染模式

      // const material = new THREE.PointsMaterial({
      //  // color: 0xff0000,
      //   vertexColors:  true, //以顶点颜色为准
      //   size: 10.0 //点对象像素尺寸
      // }); //材质对象
      // const points = new THREE.Points(geometry, material); //点模型对象
      // 线条渲染模式
      // const material=new THREE.LineBasicMaterial({
      //   color:0xff0000 //线条颜色
      // });//材质对象
      // const line=new THREE.Line(geometry,material);//线条模型对象
     // scene.add(mesh)
      /**
       * 顶点索引复用顶点数据
       * */
      //未使用顶点复用
      //const geometry=new THREE.BufferGeometry()
      //类型数组创建顶点位置position数据
      // const vertices = new Float32Array([
      //   0, 0, 0, //顶点1坐标
      //   80, 0, 0, //顶点2坐标
      //   80, 80, 0, //顶点3坐标
      //
      //   0, 0, 0, //顶点4坐标   和顶点1位置相同
      //   80, 80, 0, //顶点5坐标  和顶点3位置相同
      //   0, 80, 0, //顶点6坐标
      // ]);
      // 创建属性缓冲区对象
      // const attribue = new THREE.BufferAttribute(vertices, 3); //3个为一组
      // // 设置几何体attributes属性的位置position属性
      // geometry.attributes.position = attribue
      // const normals = new Float32Array([
      //   0, 0, 1, //顶点1法向量
      //   0, 0, 1, //顶点2法向量
      //   0, 0, 1, //顶点3法向量
      //
      //   0, 0, 1, //顶点4法向量
      //   0, 0, 1, //顶点5法向量
      //   0, 0, 1, //顶点6法向量
      // ]);
      // // 设置几何体attributes属性的位置normal属性
      //geometry.attributes.normal = new THREE.BufferAttribute(normals, 3); //3个为一组,表示一个顶点的xyz坐标
      //顶点索引.index
      const geometry = new THREE.BufferGeometry(); //声明一个空几何体对象
      //类型数组创建顶点位置position数据
      const vertices = new Float32Array([
        0, 0, 0, //顶点1坐标
        80, 0, 0, //顶点2坐标
        80, 80, 0, //顶点3坐标
        0, 80, 0, //顶点4坐标
      ]);
      // 创建属性缓冲区对象
      const attribue = new THREE.BufferAttribute(vertices, 3); //3个为一组
      // 设置几何体attributes属性的位置position属性
      geometry.attributes.position = attribue
      const normals = new Float32Array([
        0, 0, 1, //顶点1法向量
        0, 0, 1, //顶点2法向量
        0, 0, 1, //顶点3法向量
        0, 0, 1, //顶点4法向量
      ]);
      // 设置几何体attributes属性的位置normal属性
      geometry.attributes.normal = new THREE.BufferAttribute(normals, 3); //3个为一组,表示一个顶点的xyz坐标
      // Uint16Array类型数组创建顶点索引数据
      const indexes = new Uint16Array([
        // 0对应第1个顶点位置数据、第1个顶点法向量数据
        // 1对应第2个顶点位置数据、第2个顶点法向量数据
        // 索引值3个为一组，表示一个三角形的3个顶点
        0, 1, 2,
        0, 2, 3,
      ])
// 索引数据赋值给几何体的index属性
      geometry.index = new THREE.BufferAttribute(indexes, 1); //1个为一组
      const material = new THREE.MeshBasicMaterial({
        color: 0x0000ff, //三角面颜色
        // vertexColors:  true, //以顶点颜色为准
        side: THREE.DoubleSide //两面可见
      }); //材质对象
      const mesh = new THREE.Mesh(geometry, material); //网格模型对象Mesh
      scene.add(mesh)
      /**
       * 光源设置
       */
          //点光源
      // const point = new THREE.PointLight(0x444444);
      // point.position.set(280, 45, 15); //点光源位置
      // scene.add(point); //点光源添加到场景中
      // //环境光    环境光颜色与网格模型的颜色进行RGB进行乘法运算
      // const ambient = new THREE.AmbientLight(0x444444);
      // scene.add(ambient);
      /**
       * 相机设置
       */
      const width = window.innerWidth; //窗口宽度
      const height = window.innerHeight; //窗口高度
      const k = width / height; //窗口宽高比
      const s = 300; //三维场景显示范围控制系数，系数越大，显示的范围越大
      const camera = new THREE.OrthographicCamera(-s * k, s * k, s, -s, 1, 1000);
      camera.position.set(350, 300, 200); //设置相机位置
      camera.lookAt(scene.position); //设置相机方向(指向的场景对象)
      //创建辅助坐标轴
      const axesHelper = new THREE.AxesHelper(1200);//参数200标示坐标系大小，可以根据场景大小去设置
      scene.add(axesHelper);

      /**
       * 创建渲染器对象
       */
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);//设置渲染区域尺寸
      renderer.setClearColor(0x444444, 1); //设置背景颜色
      document.body.appendChild(renderer.domElement); //body元素中插入canvas对象
      function render(){
        renderer.render(scene,camera)
        //mesh.rotateY(0.01)
        requestAnimationFrame(render)
      }
      render()
      new OrbitControls(camera,renderer.domElement)
    },
    createModel(){
      let THREE=this.$THREE
      const scene=new THREE.Scene()
      //正方体
      const geometry = new THREE.BoxGeometry(50, 100, 200); //创建一个立方体几何对象Geometry
      // const material = new THREE.MeshLambertMaterial({
      //   color: 0x00ff00,
      //   opacity:0.7,//opacity的值是0~1之间
      //   transparent:true,
      //  // wireframe:true	//将几何图形渲染为线框。 默认值为false
      // }); //材质对象Material
      //MeshPhongMaterial镜面反色
      const sphereMaterial=new THREE.MeshPhongMaterial({
        color:0x00ff00,
        specular:0x4488ee,//specular表示球体网格模型的高光颜色
        shininess:120//shininess属性可以理解为光照强度的系数
      });//材质对象
      const mesh = new THREE.Mesh(geometry, sphereMaterial); //网格模型对象Mesh
      mesh.position.set(200,100,0);
      scene.add(mesh); //网格模型添加到场景中

      //球体
      // const geometry2 = new THREE.SphereGeometry(60, 40, 40);
      // const material2 = new THREE.MeshLambertMaterial({
      //   color: 0xff00ff
      // });
      // const mesh2 = new THREE.Mesh(geometry2, material2); //网格模型对象Mesh
      // mesh2.translateY(150); //球体网格模型沿Y轴正方向平移120
      // scene.add(mesh2);
      // 圆柱网格模型
      // const geometry3 = new THREE.CylinderGeometry(0, 50, 100, 50);
      // const material3 = new THREE.MeshLambertMaterial({
      //   color: 0xffff00
      // });
      // const mesh3 = new THREE.Mesh(geometry3, material3); //网格模型对象Mesh
      // mesh3.translateX(120); //球体网格模型沿Y轴正方向平移120
      // mesh3.position.set(120,0,0);//设置mesh3模型对象的xyz坐标为120,0,0
      // scene.add(mesh3); //
      /**
       * 光源设置
       */
          //点光源
      const point = new THREE.PointLight(0x444444);
      point.position.set(280, 45, 15); //点光源位置
      scene.add(point); //点光源添加到场景中
          //环境光    环境光颜色与网格模型的颜色进行RGB进行乘法运算
      const ambient = new THREE.AmbientLight(0x444444);
      scene.add(ambient);

      /**
       * 相机设置
       */
      const width = window.innerWidth; //窗口宽度
      const height = window.innerHeight; //窗口高度
      const k = width / height; //窗口宽高比
      const s = 300; //三维场景显示范围控制系数，系数越大，显示的范围越大
      const camera = new THREE.OrthographicCamera(-s * k, s * k, s, -s, 1, 1000);
      camera.position.set(350, 300, 200); //设置相机位置
      camera.lookAt(scene.position); //设置相机方向(指向的场景对象)
      //创建辅助坐标轴
      const axesHelper = new THREE.AxesHelper(1200);//参数200标示坐标系大小，可以根据场景大小去设置
      scene.add(axesHelper);

      /**
       * 创建渲染器对象
       */
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);//设置渲染区域尺寸
      renderer.setClearColor(0x444444, 1); //设置背景颜色
      document.body.appendChild(renderer.domElement); //body元素中插入canvas对象
      //执行渲染操作   指定场景、相机作为参数
     // renderer.render(scene, camera);
     //  let T0 = new Date();
     //  function render() {
     //    let T1 = new Date();//本次时间
     //    let t = T1-T0;//时间差
     //    T0 = T1;//把本次时间赋值给上次时间
     //    // renderer.render(scene,camera);//执行渲染操作
     //    // mesh.rotateY(0.01);//每次绕y轴旋转0.01弧度
     //    // requestAnimationFrame(render)
     //    requestAnimationFrame(render);
     //    renderer.render(scene,camera);//执行渲染操作
     //    mesh.rotateY(0.001*t);//旋转角速度0.001弧度每毫秒
     //  }
     //  render()

      //鼠标操作控件
      // function render(){
      //   renderer.render(scene,camera)
      //
      // }
      // render()
      // const controls = new OrbitControls(camera,renderer.domElement)
      // controls.addEventListener('change',render)
      //当通过OrbitControls操作改变相机状态的时候，没必要在通过
      // controls.addEventListener('change', render)监听鼠标事件调用渲染函数，
      // 因为requestAnimationFrame()就会不停的调用渲染函数。
      //注意开发中不要同时使用requestAnimationFrame()或controls.addEventListener('change', render)调用同一个函数，这样会冲突。
      function render(){
        renderer.render(scene,camera)
        //mesh.rotateY(0.01)
        requestAnimationFrame(render)
      }
      render()
      new OrbitControls(camera,renderer.domElement)

    },

  }
}
</script>

<style scoped>

</style>