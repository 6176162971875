<template>
  <el-dialog :title="title" v-model="dialogVisible" custom-class="dialog_width_box" :modal='modal' :before-close="outClosePanel">
    <div class="garbage_box_marker_info">
      <div class="real_time_overview">
        <div class="real_time_info_title">实时情况</div>
        <div class="real_time_content">
          <ul class="info_ul">
            <li>满仓状态</li>

            <li style="font-weight: 800;color: #f1cb0a" v-if="item.sta=='满仓'">满仓</li>
            <li style="font-weight: 800;color: #32e013" v-else>正常</li>
          </ul>
          <ul class="info_ul">
            <li>故障状态</li>

            <li style="font-weight: 800;color: #f12d0a" v-if="item.sta=='故障'">故障</li>
            <li style="font-weight: 800;color: #32e013" v-else>正常</li>
          </ul>
          <ul class="info_ul">
            <li>在线状态</li>

            <li style="font-weight: 800;color: #f12d0a" v-if="item.sta=='离线'">离线</li>
            <li style="font-weight: 800;color: #32e013" v-else>在线</li>
          </ul>
        </div>
      </div>
      <div class="deliver_overview">
        <div class="real_time_info_title">仓位概况</div>
        <div class="deliver_content">
          <div  class='systemInfo' style="height: 100%;width: 100%;overflow-y: auto;">
            <table style="font-size: x-small;border-color: unset;width: 100%;line-height: 29px;color: white;border-bottom: solid;" class="my_table_1" >
              <thead >
                <tr >

                  <td>仓位</td>
                  <td>当前量</td>
                  <td>满仓量</td>
                  <td>满仓状态</td>
                  <td>故障状态</td>
                  <td>故障时间</td>
                  <td>故障事件</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>饮料瓶</td>
                  <td>21</td>
                  <td>180</td>
                  <td>未满</td>
                  <td>正常</td>
                  <td>未异常</td>
                  <td>未异常</td>
                </tr>
                <tr>
                  <td>玻璃</td>
                  <td>4.72</td>
                  <td>60</td>
                  <td>未满</td>
                  <td>正常</td>
                  <td>未异常</td>
                  <td>未异常</td>
                </tr>
                <tr>
                  <td>废旧金属/木材</td>
                  <td>0</td>
                  <td>60</td>
                  <td>未满</td>
                  <td>正常</td>
                  <td>未异常</td>
                  <td>未异常</td>
                </tr>
                <tr>
                  <td>织物/复合材料</td>
                  <td>16.36</td>
                  <td>60</td>
                  <td>满仓</td>
                  <td>故障</td>
                  <td>2023-12-21 14:39:03</td>
                  <td>测距失效</td>
                </tr>
                <tr>
                  <td>纸类</td>
                  <td>0</td>
                  <td>60</td>
                  <td>未满</td>
                  <td>正常</td>
                  <td>未异常</td>
                  <td>未异常</td>
                </tr>
                <tr>
                  <td>塑料</td>
                  <td>0</td>
                  <td>60</td>
                  <td>未满</td>
                  <td>正常</td>
                  <td>未异常</td>
                  <td>未异常</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="alarm_statistics">
        <div class="real_time_info_title">当月报警统计</div>
        <div class="alarm_statistics_content">
          <span style="text-align: left;font-size: 16px;
  padding-left: 13px;
  font-weight: 600;color: white">报警数量:</span><span style="margin-left: 5px;font-size: 15px;color: white">33</span>
        </div>
      </div>
      <div class="alarm_reason">
        <div class="real_time_info_title">报警原因</div>
        <div class="alarm_reason_content">
          <garbage_box_alarm_pie></garbage_box_alarm_pie>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import garbage_box_alarm_pie from "@/components/New/Echarts/garbage_box_alarm_pie";
export default {
name: "GarbageBoxInfoPanel",
  components:{
    garbage_box_alarm_pie
  },
  data(){
    return {
      listing:[],
      dialogVisible:false,
      item:[],
      modal:false,
      listQuery:{},
      title:"智能回收箱概况"

    }
  },
  methods:{
    async outClosePanel(){
      this.dialogVisible=false
    },
    async openInfoPanel(item){
      this.title='智能回收箱概况'
      this.dialogVisible=true
      this.item=item
      this.title=this.title+'('+item.device_name+')'
      console.log('我的信息',this.title)
    }
  }
}
</script>

<style lang="less">
.dialog_width_box{
 // margin-top: 20px !important;
  width: 25% !important;
  height: 74% !important;
  box-sizing: border-box;
  background-color: rgb(4 116 231 / 47%) !important;
  border-top: 1px solid #0e6b9f;
  border-bottom: 1px solid #0e6b9f;
  border-radius: 0 !important;
  overflow-y: auto;
  position: absolute;
  left: 30%;
  top: -5%;


  .el-dialog__header{
    padding: unset;
    margin-right: unset;
    word-break: break-all;
    background-color: #0000ff6b;
    width: 100%;
    height: 8%;
    line-height: 66px;
    text-align: left;
  }
  .el-dialog__title{
    font-size: x-large;
    padding-left: 10px
  }
  .el-dialog__body{
    padding: 0;

  }
}
.garbage_box_marker_info{
  // background-color: #d43636;
  width: 100%;
  height: 100%;
  padding: 0;
  //display: flex;
}
.real_time_overview{
  width: 100%;
  //height: 120px;
  //background-color: #d43636;
  margin-top: 2%;
  .real_time_content{
    width: 100%;
    height: 100%;
   // background-color: #00aaff;
    display: flex;
    .info_ul{
      list-style-type: none;
      padding: 0;

      width: 33%;
      line-height: 30px;
      /* justify-content: space-evenly; */

      color: aliceblue;
      // font-weight: 600;
      // font-family: cursive;

    }
  }

}
.deliver_overview{
  width: 100%;
  height: 240px;
  //margin-top: 6%;
  .deliver_content{
    width: 100%;
    height: 100%;
   // background-color: #ff0077;
  }
  ul{
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
}
.alarm_statistics{
  width: 100%;
  height: 30px;
  margin-top: 7%;
  .alarm_statistics_content{
    width: 100%;
    height: 100%;
   // background-color: #00ffb7;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.alarm_reason{
  width: 100%;
 // height: 300px;
  margin-top: 6%;
  .alarm_reason_content{
    width: 100%;
   // height: 100%;
    //background-color: #b49127;
  }
}
.real_time_info_title{
  text-align: left;
  color: white;
  font-size: 18px;
  padding-left: 13px;
  font-weight: bolder;
}
</style>